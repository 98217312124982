import styled from 'styled-components';

export const Container = styled.footer`
  background-color: var(--gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  gap: 20px;
  margin-top: 1%;
  nav {
    width: var(--width-m);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      h4 {
        color: var(--white);
        margin-bottom: 15px;
        text-transform: uppercase;
        font-size: ${(props) => props.theme.fontSize + 14}px;
        cursor: pointer;
      }
      a {
        color: var(--white);
        font-weight: 400;
        font-size: ${(props) => props.theme.fontSize + 14}px;
      }
    }
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    img {
      width: 150px;
    }
    p {
      color: var(--white);
      text-align: center;
      font-size: ${(props) => props.theme.fontSize + 12}px;
    }
  }

  @media screen and (max-width: 720px) {
    nav {
      max-width: 80% !important;
      flex-direction: column;
      margin-left: 32px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    nav {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-width: 80% !important;
      margin-left: 32px;
    }
  }
`;
