/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from "react";
import { Autocomplete } from "../../../components/Autocomplete";
import { Input } from "../../../components/Input";
import { ModalDefault } from "../../../components/ModalDefault";
import { UploadInput } from "../../../components/UploadInput";
import { Wizard } from "../../../components/Wizard";
import { maskCepInput, maskPhoneInput } from "../../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../../data/regex";
import { Container, Controllers, Empty, Line } from "./styles";
import InputMask from 'react-input-mask';
import axios from "axios";
import countriesMask from "../../../utils/countriesPhoneMask";

export function Address(props) {
    const {
        cep,
        address,
        number,
        district,
        city,
        state,
        primaryPhone,
        secondaryPhone,
        foreigner
    } = props

    const remainingFields = [
        {
            value: cep,
            label: 'cep',
            message: 'O campo CEP é obrigatório'
        },
        {
            value: address,
            label: 'address',
            message: 'O campo endereço é obrigatório'
        },
        {
            value: number,
            label: 'number',
            message: 'O campo número é obrigatório'
        },
        {
            value: state,
            label: 'state',
            message: 'O campo estado é obrigatório'
        },
        {
            value: city,
            label: 'city',
            message: 'O campo cidade é obrigatório'
        },
        {
            value: district,
            label: 'district',
            message: 'O campo bairro é obrigatório'
        },
        {
            value: primaryPhone,
            label: 'primaryPhone',
            message: 'O campo telefone é obrigatório'
        },
    ].filter(({ value }) => `${value}`.length === 0)

    const imageOrPDFRegex = new RegExp(/jpg|jpeg|png/i)

    const isFileValid = imageOrPDFRegex.test(props.foreignerProofOfddress?.name ?? '')

    const remainingFieldsForeigner = [
        {
            value: cep,
            label: 'cep',
            message: 'O campo código postal é obrigatório'
        },
        {
            value: address,
            label: 'address',
            message: 'O campo endereço é obrigatório'
        },
        {
            value: number,
            label: 'number',
            message: 'O campo número é obrigatório'
        },
        {
            value: props.country,
            label: 'country',
            message: 'O campo país é obrigatório'
        },
        {
            value: state,
            label: 'state',
            message: 'O campo estado é obrigatório'
        },
        {
            value: city,
            label: 'city',
            message: 'O campo cidade é obrigatório'
        },
        {
            value: props.foreignerProofOfddress,
            label: 'foreignerProofOfddress',
            message: 'O campo de upload de comprovante de residência para estrangeiros é obrigatório'
        },
        {
            value: primaryPhone,
            label: 'primaryPhone',
            message: 'O campo telefone é obrigatório'
        },
    ].filter(({ value }) => `${value}`.length === 0)

    const [targetField, setTargetField] = useState('cep')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')    

    const isFormValid = foreigner ? remainingFieldsForeigner.length === 0 : remainingFields.length === 0

    const isZipcodeValid = foreigner ? cep !== "" : cep.replace(NUMBER_ONLY, "").length === 8

    const isPhoneValid = primaryPhone.replace(NUMBER_ONLY, "").length > 9

    const isAltPhoneValid = secondaryPhone.replace(NUMBER_ONLY, "").length === 0 ||
        secondaryPhone.replace(NUMBER_ONLY, "").length > 9

    const disableButton = foreigner 
                            ? !(isZipcodeValid && isPhoneValid && isFormValid && isAltPhoneValid && isFileValid) 
                            : !(isZipcodeValid && isPhoneValid && isFormValid && isAltPhoneValid)

    const [cities, setCities] = useState([])

    const fetchForSelectedStateCities = async () => {
        try {
            //here we retrieve the list of cities from IBGE API
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
            if (response.status === 200) {
                const cities = Array.from(response.data || []).map(({ nome }) => nome)
                setCities(cities)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }


    useEffect(() => {
        if(!foreigner) {
            setDropdownCities([])
            if (state.length > 0) fetchForSelectedStateCities()
        }
    }, [state])

    const validationStatuses = {
        cep: {
            label: 'cep',
            message: 'CEP inválido'
        },
        phone: {
            label: 'primaryPhone',
            message: 'Telefone principal inválido'
        },
        altPhone: {
            label: 'secondaryPhone',
            message: 'Telefone secundário inválido'
        }
    }

    const focusOnLastRequiredField = () => {
        const getFirstInvalidField = remainingFields[0]
        const getFirstInvalidFieldForeigner = remainingFieldsForeigner[0] 

        setModalError(true)

        setModalErrorMessage(foreigner ? props.foreignerProofOfddress !== '' && !isFileValid 
                ?  'Arquivo de comprovante de residência para estrangeiros em formato inválido. Por favor use os formatos: JPEG ou PNG.': getFirstInvalidFieldForeigner.message 
                : getFirstInvalidField.message)
        setTargetField(foreigner ? props.foreignerProofOfddress !== '' && !isFileValid 
                ? 'foreignerProofOfAddress' : getFirstInvalidFieldForeigner.label
                : getFirstInvalidField.label)
    }

    const closeModalShowLastRequiredField = () => {
        if (remainingFields.length > 0 || remainingFieldsForeigner.length > 0) {
            const getFirstInvalidField = foreigner ? remainingFieldsForeigner[0] : remainingFields[0]
            if (getFirstInvalidField?.label === targetField) {
                setTargetField(' ')
                setModalError(false)
                const timedOutSetField = setTimeout(() =>
                    setTargetField(getFirstInvalidField?.label), 256)
                return () => clearTimeout(timedOutSetField)
            }
            setTargetField(getFirstInvalidField?.label)
            return
        }
        const selectedField = !isZipcodeValid ? 'cep' : !isPhoneValid ? 'phone'
            : !isAltPhoneValid ? 'altPhone' : !isFileValid ? 'foreignerProofOfddressFile' : ''
        const getInvalidField = validationStatuses[selectedField]

        setModalError(false)
        if(getInvalidField.label === targetField) {
            setTargetField(' ')
            const timedOutSetField = setTimeout(() => 
            setTargetField(getInvalidField.label), 256)
            return () => clearTimeout(timedOutSetField)
        }
        setTargetField(getInvalidField.label);
    }

    const [drowdownCities, setDropdownCities] = useState([]);

    function handleCityInput (input) {
        props.setCity(input)
        if (cities.length > 0) {
            const variableToSearch = input.toLocaleLowerCase();

            const citiesFiltered = cities.filter(element => {
                return element
                    .toLocaleLowerCase()
                    .includes(variableToSearch)
            });

            setDropdownCities(citiesFiltered)
        }
    }

    const [dropdownCountries, setDropdownCountries] = useState([]);

    function handleCountryInput (input) {
        if (countries.length > 0) {
            const variableToSearch = input.toLocaleLowerCase();
            
            const countriesFiltered = dropdownCountries.filter(element => {
                return element
                .toLocaleLowerCase()
                .includes(variableToSearch)
            }); 

            setDropdownCountries(countriesFiltered)
            props.setCountry(input)
        }
        if(input === ""){
            const countriesToDrpdwn = Array.from(countries).map(({ nome }) => nome.abreviado)
            setDropdownCountries(countriesToDrpdwn)
        }
    }

    function handleCountries (selected) {
        setDropdownCountries([]);
        const countriesFiltered = countries.filter(element => element.nome.abreviado === selected);
        setCountryToMask(countriesFiltered[0].id['ISO-3166-1-ALPHA-2'])
        props.setCountry(countriesFiltered[0].nome.abreviado);
    }

    function handleCities (selected) {
        if (cities.length > 0) {
            setDropdownCities([]);

            const citiesFiltered = cities.filter(element => element === selected);
            props.setCity(citiesFiltered);

        }
    }

    function onBlurCep (e) { 
        if(!foreigner) {
            let cep = e.target.value;

            if (!cep) {
                e.target.classList.add('error');
            } else {
                e.target.classList.remove('error');

                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(response => {
                        response.json()
                        .then(res => {
                            if (res.erro) {
                                setModalError(true);
                                setModalErrorMessage('CEP invalido!');

                                props.setCep('');
                                props.setAddress('');
                                props.setDistrict('');
                                props.setState('');
                                props.setCity('');

                                return null;
                            }

                            props.setAddress(res.logradouro);
                            props.setDistrict(res.bairro);
                            props.setState(res.uf);
                            props.setCity(res.localidade);
                        });
                    })
                    .catch(() => {
                        setModalError(true);
                        setModalErrorMessage('CEP invalido!');

                        props.setCep('');
                        props.setAddress('');
                        props.setDistrict('');
                        props.setState('');
                        props.setCity('');

                        return null;
                    });
            };
        } else {
           (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); 
        }
    }
    const [countries, setCountries] = useState([{}])
    const [countryToMask, setCountryToMask] = useState([{}])
    const [loading, setLoading] = useState(true)

    useEffect(() =>{
        async function getCountries(){
            await axios.get('https://servicodados.ibge.gov.br/api/v1/paises/{paises}')
            .then(res => {
                let filtered = res.data.filter((v,i,a)=>a.findIndex(v2=>(v2.id.M49===v.id.M49))===i)
                let sorted= filtered.sort(function(a, b) {
                    return a.nome.abreviado.localeCompare(b.nome.abreviado);
                 });
                setCountries(sorted)
                setLoading(false)
            })
        }
        getCountries()
    }, [])

    function handleLoadCountries(){
        if(props.country !== ""){
            props.setCountry("")
        }
        const countriesToDrpdwn = Array.from(countries).map(({ nome }) => nome.abreviado)
        setDropdownCountries(countriesToDrpdwn)
    }

    return (
        <>
            <Wizard step={3} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container>
                <Line>
                    <Input autofocus={targetField === 'cep'}
                        targetName={'cep'}
                        value={props.cep} 
                        onChange={(v) => foreigner ? props.setCep(v) : props.setCep(maskCepInput(v.replace(NUMBER_ONLY, "")))}
                        onBlur={(e) => onBlurCep(e)}
                        type="text" label={foreigner ? "Código postal" : "CEP"} placeholder="" required></Input>
                    <Empty />
                    <Empty />
                    <Empty />
                </Line>
                <Line>
                    <Input
                        autofocus={targetField === 'address'}
                        targetName={'address'}
                        value={props.address} onChange={(v) => props.setAddress(v)} fl={2} label="Endereço"
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        type="text" placeholder="Rua leopoldina" required />
                    <Input
                        autofocus={targetField === 'number'}
                        targetName={'number'}
                        value={props.number} onChange={(v) => props.setNumber(v)} fl={1} label="Número"
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        type="number" placeholder="0000" required />
                    <Input
                        autofocus={targetField === 'complement'}
                        targetName={'complement'}
                        value={props.complement} onChange={(v) => props.setComplement(v)} fl={1} label="Complemento"
                        type="text" placeholder="" />
                </Line>
                {!foreigner ? 
                    <Line>
                        <Input autofocus={targetField === 'district'}
                            targetName={'district'}
                            value={props.district} onChange={(v) => props.setDistrict(v)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" label="Bairro" placeholder=" " required />
                        <Empty />
                    </Line>
                 : null}
                <Line>
                    {foreigner ? (
                        <>
                            {loading ? null : (
                                <Autocomplete
                                    style={{
                                        opacity: !loading ? 1 : .5
                                    }}
                                    value={props.country}
                                    label={'País'}
                                    targetName={'country'}
                                    required
                                    onChange={(v) => handleCountryInput(v)}
                                    onSelect={e => handleCountries(e)}
                                    onSelectChange={(e) => props.setCountry(e)}
                                    onFocus={() => handleLoadCountries()}
                                    // onBlur={(e) => {
                                    //     console.log('oi', e)
                                    //     setDropdownCountries([])
                                    // }}
                                    data={dropdownCountries}
                                />
                            )}
                            <Input autofocus={targetField === 'state'}
                                targetName={'state'}
                                value={props.state} 
                                onChange={(v) => props.setState(v)}
                                onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                                type="text" label="Estado" placeholder="" required
                            />
                            <Input autofocus={targetField === 'city'}
                                value={props.city}
                                label={'Cidade'}
                                targetName={'city'}
                                onChange={(v) => props.setCity(v)}
                                onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                                type="text" placeholder="" required
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                autofocus={targetField === 'state'}
                                targetName={'state'}
                                value={props.state} onChange={(v) => {
                                    props.setState(v);
                                    props.setCity('');
                                }} 
                                fl={1} label="Estado"
                                onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                                type="select"
                                required>
                                <option value="RO">RO</option>
                                <option value="AC">AC</option>
                                <option value="AM">AM</option>
                                <option value="RR">RR</option>
                                <option value="PA">PA</option>
                                <option value="AP">AP</option>
                                <option value="TO">TO</option>
                                <option value="MA">MA</option>
                                <option value="PI">PI</option>
                                <option value="CE">CE</option>
                                <option value="RN">RN</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="AL">AL</option>
                                <option value="SE">SE</option>
                                <option value="BA">BA</option>
                                <option value="MG">MG</option>
                                <option value="ES">ES</option>
                                <option value="RJ">RJ</option>
                                <option value="SP">SP</option>
                                <option value="PR">PR</option>
                                <option value="SC">SC</option>
                                <option value="RS">RS</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="GO">GO</option>
                                <option value="DF">DF</option>
                            </Input>
                            
                            <Autocomplete
                                style={{
                                    opacity: cities.length > 0 ? 1 : .5
                                }}
                                value={props.city}
                                label={'Cidade'}
                                targetName={'city'}
                                required
                                onChange={(v) => handleCityInput(v)}
                                onSelect={e => handleCities(e)}
                                onSelectChange={(e) => props.setCity(e)}
                                onBlur={() => setDropdownCities([])}
                                data={props.city && drowdownCities}
                            />
                        </>
                        )
                    }
                </Line>
                {foreigner ? (
                    <Line>
                        <UploadInput
                            required
                            tabIndex={0}
                            value={props.foreignerProofOfddress}
                            autofocus={targetField === 'foreignerProofOfddress'}
                            onChange={props.setForeignerProofOfddress}
                            aria-label="Upload do comprovante de endereço para estrangeiros"
                            onBlur={(e) => (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error')}
                            label="Upload do comprovante de endereço para estrangeiros" />
                        <Empty></Empty>
                    </Line>
                ) : null}
                <Line>
                    {foreigner ? (
                        <Input
                        autofocus={targetField === 'primaryPhone'}
                        targetName={'primaryPhone'}
                        value={props.primaryPhone} 
                        onChange={(v) => props.setPrimaryPhone(v)}
                        fl={1} label="Telefone principal"
                        selectedCountry={countryToMask}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        type="telephone" placeholder="Informe o número com código de área" required />
                    ) : (
                        <Input
                        autofocus={targetField === 'primaryPhone'}
                        targetName={'primaryPhone'}
                        value={props.primaryPhone} 
                        onChange={(v) => props.setPrimaryPhone(maskPhoneInput(v.replace(NUMBER_ONLY, "")))}
                        fl={1} label="Telefone principal"
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        type="text" placeholder="Informe o número com código de área" required />
                    )}
                    {foreigner ? (
                        <Input
                        autofocus={targetField === 'secondaryPhone'}
                        targetName={'secondaryPhone'}
                        value={props.secondaryPhone} 
                        onChange={(v) => props.setSecondaryPhone(maskPhoneInput(v.replace(NUMBER_ONLY, "")))} 
                        fl={1} 
                        label="Telefone alternativo" 
                        placeholder="Informe o número com código de área"
                        selectedCountry={countryToMask}
                        type="telephone"/>
                    ) : (
                    <Input
                        autofocus={targetField === 'secondaryPhone'}
                        targetName={'secondaryPhone'}
                        value={props.secondaryPhone} 
                        onChange={(v) => props.setSecondaryPhone(maskPhoneInput(v.replace(NUMBER_ONLY, "")))} 
                        fl={1} 
                        label="Telefone alternativo" 
                        type="text" 
                        placeholder="Informe o número com código de área" />
                    )}
                    
                </Line>
            </Container>
            <Controllers>
                <button tabIndex={1} onClick={() => { props.changeStep(2) }}>Anterior</button>
                <button
                    style={{
                        opacity: disableButton ? .5 : 1
                    }}
                    tabIndex={1}
                    onClick={() => disableButton ? focusOnLastRequiredField() : props.changeStep(4)}>Avançar</button>
            </Controllers>
        </>
    )
}