import {
  ErrorWrapper
} from './styles'

export const NoMatchError = ({
  message = 'Nenhum resultado encontrado'
}) => <ErrorWrapper>
    <svg aria-hidden={true} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="red">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /></svg>
    <p tabIndex={0}>
      {message}
    </p>
  </ErrorWrapper>