import { useEffect, useRef } from "react"
import { Input } from "../../../Input"
import { UploadInput } from "../../../UploadInput"
import {
  Genero
} from './styles'
import { Autocomplete } from "../../../Autocomplete"

const StateOptionsObject = () => <>
  <option value="RO">RO</option>
  <option value="AC">AC</option>
  <option value="AM">AM</option>
  <option value="RR">RR</option>
  <option value="PA">PA</option>
  <option value="AP">AP</option>
  <option value="TO">TO</option>
  <option value="MA">MA</option>
  <option value="PI">PI</option>
  <option value="CE">CE</option>
  <option value="RN">RN</option>
  <option value="PB">PB</option>
  <option value="PE">PE</option>
  <option value="AL">AL</option>
  <option value="SE">SE</option>
  <option value="BA">BA</option>
  <option value="MG">MG</option>
  <option value="ES">ES</option>
  <option value="RJ">RJ</option>
  <option value="SP">SP</option>
  <option value="PR">PR</option>
  <option value="SC">SC</option>
  <option value="RS">RS</option>
  <option value="MS">MS</option>
  <option value="MT">MT</option>
  <option value="GO">GO</option>
  <option value="DF">DF</option>
</>

export const RenderInput = (props) => {
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef) {
      if (inputRef.current) {
        inputRef.current?.focus()
      }
    }
  }, [props.autofocus])

  if (!props) return null

  if (props.type === 'upload') {
    return <UploadInput
      value={props.value}
      onChange={props.onChange}
      label={props.label}
      autofocus={props.autofocus}
    />
  }

  if (props.type === 'radio') {
    return <Genero>
      <div>
        <label ref={inputRef} tabIndex={0} htmlFor="">{props.label}</label>
      </div>
      <div style={{
        marginTop: 8
      }}>
        {
          props.options ?
            null :
            <>
              <div>
                <input tabIndex={0} aria-label="sim"
                  type="radio" name="radyes" id="yes" value={true}
                  checked={props.value} onChange={e => props.onChange(true)} />
                <label htmlFor="yes">Sim</label>
              </div> <div>
                <input
                  tabIndex={0} aria-label="não"
                  type="radio" name="radno" id="no" value={true} checked={!props.value} onChange={e => props.onChange(false)} />
                <label htmlFor="no">Não</label>
              </div>
            </>
        }

      </div>
    </Genero>
  }

  if (!props.type || props.type === 'none') {
    return null
  }

  if (props.type === 'list-checkbox') {
    return <div className="list-checkbox">
      <div>
        <label ref={inputRef} tabIndex={0} htmlFor="">{props.label}</label>
      </div>
      <div>
        {
          props.items.map((item, key) => <div
            key={key + 'checkbox' + item.label}
            style={{
              marginTop: 8,
              marginBottom: 8,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <input style={{
              marginRight: 12
            }} type="checkbox"
              tabIndex={0} aria-label={item.label}
              onChange={() => item.onChange(!item.value)} checked={item.value} value={true} />
            {item.label !== undefined ? (
              <label>{item.label}</label>
            ) : (item.labelElement)}
          </div>)
        }
      </div>
    </div>
  }

  if (props.type === 'multiple-checkbox') {
    return <Genero style={{
      marginRight: 22
    }}>
      <div>
        <label ref={inputRef} tabIndex={0} htmlFor="">{props.label}</label>
      </div>
      <div style={{
        marginTop: 8
      }}>
        {
          props.items ?
            <>
              {
                props.items.map((item, key) => <div key={key + 'checkbox'}>
                  <input
                    tabIndex={0} aria-label={item.label}
                    type="checkbox" onChange={() => item.onChange(!item.value)} checked={item.value} />
                  <label>{item.label}</label>
                </div>)
              }
            </>
            :
            null
        }
      </div>
    </Genero>
  }

  if (props.type === 'autocomplete') {

    return  <Autocomplete
      style={{
        opacity: props.options.length > 0 ? 1 : .5
      }}
      value={props.value}
      onChange={(e) => props.onChange(e)}
      onSelectChange={(e) => props.onSelectChange(e)}
      onSelect={(e) => props.onSelect(e) }
      onBlur={() => props.onBlur()}
      label="Cidade"
      required
      data={props.drowpdownValues}
    />
}
  if (props.type === 'text-list') {
    const handleFilter = (value) => props.filter(value)

    return <div className="text-list">
      <div>
        <label tabIndex={0} ref={inputRef} htmlFor="">{props.label}</label>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
        {
          props.items.map((item, key) => <div 
          key={key + 'items-key'}
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: 12,
            alignItems: 'center',
            marginBottom: 18
          }}>
            <label tabIndex={0}>
              {item.label}
            </label>
            <input
              tabIndex={0}
              style={{
                marginRight: 16,
                borderRadius: 4,
                paddingBottom: 6,
                paddingTop: 4,
                textAlign: 'center',
                border: '1px solid #00000055',
                width: 78
              }} id={`item${key}`} type="text" value={item.value} onChange={(e) => item.onChange(handleFilter(e.target.value))} />

          </div>)
        }
      </div>
    </div>
  }

  const handleOnchange = (value) => {
    let parsedValue = value
    if (props.filter) {
      parsedValue = props.filter(value)
    }

    if (props.mask) {
      parsedValue = props.mask(parsedValue)
    }

    props.onChange(parsedValue)
  }

  return <Input
    targetName={(typeof props.formLabel === 'object') ? props.formLabel.name : props.formLabel}
    type={props.type}
    value={props.value}
    label={props.label} fl={props.fl}
    onChange={handleOnchange}
    onBlur={props.onBlur}
    drowpdownValues={props.drowpdownValues}
    errorMessage={props.errorMessage}
    autofocus={props.autofocus}
    required={props.required}
  >
    {
      props.type === 'select' ?
        props.options ?
          props.options.map((item, key) => {
            const { value, label } = item
            const parsedLabel = label ?? item
            const parsedValue = value ?? item
            return <option key={key + 'opt' + parsedValue} value={parsedValue} >
              {parsedLabel}
            </option>
          })
          :
          StateOptionsObject()
        : null
    }
  </Input>
}
