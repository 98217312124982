import styled from 'styled-components';

export const Container = styled.section`
  span.red{
      color: #F00;
  }

  @media screen and (max-width: 720px) {
    margin: 0 32px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    padding: 0 32px;
  }
`;
