import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { Container, Line, Empty, Genero, Controllers, Radius } from "./styles";
import { ModalDefault } from "../../../components/ModalDefault";
import { useState } from "react";
export function MoreDatas(props) {
    const {
        schooling,
        profession,
        occupation,
        fluencyInBraille,
        screenReader,
        screenMagnifier,
        brailleLine,
        eletronicGlass,
        readingFluency,
        searchingFor,
        goToLibraries,
        which,
        literaryEvents,
        equipament,
        fdnc,
        save,
        changeStep,
        meet
    } = props
    const howAboutDorinateca = [
        'Amigos',
        'Através de estudantes',
        'Através de um professor',
        'Através de uma biblioteca',
        'Através de uma instituição',
        'Por whatsapp',
        'Notícias',
        'Redes sociais',
        'Site de busca'
    ]

    const howAboutDorinatecaBackend = [
        'friends',
        'students',
        'teachers',
        'libraries',
        'institutions_serving_people_disabilities',
        'whatsapp',
        'media_newspaper_or_tv_or_articles',
        'social_networks',
        'search_site'
    ]


    const [targetField, setTargetField] = useState('schooling')
    
    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const filterNonEmptyFields = [
        {
            value: schooling,
            label: 'schooling',
            message: 'O campo escolaridade é obrigatório'
        },
        {
            value: fluencyInBraille,
            label: 'fluencyInBraille',
            message: 'O campo fluência em braille é obrigatório'
        },
        {
            value: meet,
            label: 'meet',
            message: 'O campo como conheceu a Dorinateca é obrigatório'
        },
        {
            value: profession,
            label: 'profession',
            message: 'O campo profissão é obrigatório'
        },
        {
            value: occupation,
            label: 'occupation',
            message: 'O campo ocupação é obrigatório'
        }
    ].filter(({ value }) => !value)


    const isFormValid = filterNonEmptyFields.length === 0
    const focusOnLastRequiredField = () => {
        const getFirstInvalidField = filterNonEmptyFields[0]

        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.label)

    }
    const closeModalShowLastRequiredField = () => {
        const getFirstInvalidField = filterNonEmptyFields[0]
        if (filterNonEmptyFields.length > 0) {
            setModalError(false)
            if (getFirstInvalidField.label === targetField) {
                setTargetField(' ')
                const timedOutSetField = setTimeout(() =>
                    setTargetField(getFirstInvalidField.label), 256)
                return () => clearTimeout(timedOutSetField)
            }
            setTargetField(getFirstInvalidField.label)
            return
        }

        setModalError(false)
    }

    return (
        <>
            <Wizard step={4} navigate={true} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container>
                <Line>
                    <Input 
                        autofocus={targetField === 'schooling'} 
                        value={props.schooling} 
                        onChange={(v) => props.setSchooling(v)} 
                        label="Escolaridade" 
                        type="select" 
                        required
                    >
                        <option value="fundamental">Ensino Fundamental</option>
                        <option value="fundamentalIncomplete">Ensino Fundamental Incompleto</option>
                        <option value="highSchool">Ensino Médio</option>
                        <option value="highSchoolIncomplete">Ensino Médio incompleto</option>
                        <option value="Graduated">Ensino Superior</option>
                        <option value="SuperiorIncomplete">Ensino Superior Incompleto</option>
                        <option value="postgraduate">Pós Graduado</option>
                    </Input>
                    <Empty></Empty>
                </Line>
                <Line>
                    <Input autofocus={targetField === 'profession'} value={props.profession} onChange={(v) => props.setProfession(v)} label="Profissão" type="text" required={true} />
                    <Input autofocus={targetField === 'occupation'} value={props.occupation} onChange={(v) => props.setOccupation(v)} label="Ocupação" type="text" required={true}/>
                </Line>
                <Line>
                    <Input required={true} autofocus={targetField === 'fluencyInBraille'} value={props.fluencyInBraille} onChange={(v) => props.setFluencyInBraille(v)} fl={1} label="Fluencia em leitura em Braille" type="select" placeholder="Senha de acesso">
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                    </Input>
                    <Radius>
                        <label tabIndex={0}
                            aria-label="Recursos de acessibilidade que utiliza, esse campo é de preenchimento obrigatório"
                            htmlFor="">Recursos de acessibilidade que utiliza</label>
                        <div>
                            <div className="div1">
                                <input type="checkbox" aria-label={"Leitor de tela"} checked={props.screenReader} onClick={(e) => props.setScreenReader(e.currentTarget.checked)} />
                                <label htmlFor="">Leitor de tela</label>
                            </div>
                            <div className="div2">
                                <input type="checkbox" aria-label={"Ampliador de tela"} name="" id="" checked={props.screenMagnifier} onClick={(e) => props.setScreenMagnifier(e.currentTarget.checked)} />
                                <label htmlFor="">Ampliador de tela</label>
                            </div>
                            <div className="div3">
                                <input type="checkbox" aria-label={"Linha Braille"} name="" id="" checked={props.brailleLine} onClick={(e) => props.setBrailleLine(e.currentTarget.checked)} />
                                <label htmlFor="">Linha Braille</label>
                            </div>
                            <div className="div4">
                                <input type="checkbox" aria-label={"Lupa eletrônica"} name="" id="" checked={props.eletronicGlass} onClick={(e) => props.setEletronicGlass(e.currentTarget.checked)} />
                                <label htmlFor="">Lupa eletrônica</label>
                            </div>
                        </div>
                    </Radius>
                </Line>
                <Line>
                    {/* <Input value={props.readingFluency} onChange={(v) => props.setReadingFluency(v)} type="select" label="Frequencia de leitura">
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                    </Input> */}
                    {/* <Input value={props.searchingFor} onChange={(v) => props.setSearchingFor(v)} type="select" label="O que busca na Dorinateca">

                    </Input> */}
                </Line>
                <Line>
                    <div className="check2">
                        <input type="checkbox" aria-label="Frequenta outras bibliotecas" checked={props.goToLibraries} onClick={(e) => props.setGoToLibraries(e.currentTarget.checked)} />
                        <label htmlFor="">Frequenta outras bibliotecas?</label>
                    </div>
                    {
                        props.goToLibraries ?
                            <Input value={props.which}
                                onChange={(v) => props.setWhich(v)} ariaLabel="Quais bibliotecas utiliza?" label="Quais?"></Input>
                            : <Empty />
                    }
                </Line>
                <Line>
                    <div className="check3">
                        <input type="checkbox" aria-label="Gostaria de participar de eventos literários?" checked={props.literaryEvents} onClick={(e) => props.setLiteraryEvents(e.currentTarget.checked)} />
                        <label htmlFor="">Gostaria de participar de eventos literários?</label>
                    </div>
                </Line>
                <Line>
                    {/* <Input value={props.equipament} onChange={(v) => props.setEquipament(v)} type="select" label="Que tipo de equipamento utiliza">
                    </Input>
                    <Input value={props.fdnc} onChange={(v) => props.setFdnc(v)} type="select" label="Por quais serviços da FDNC se interessa?">
                    </Input> */}
                </Line>
                <Line>
                    <Input value={props.meet} required={true} onChange={(v) => props.setMeet(v)} type="select" label="Como conheceu a Dorinateca">
                        {
                            howAboutDorinateca.map((opt, key) => <option
                                value={howAboutDorinatecaBackend[key]}
                                key={key + 'opt-about'}>
                                {opt}
                            </option>)
                        }
                    </Input>
                </Line>
            </Container>
            <Controllers>
                <button onClick={() => { props.goBack() }}>Cancelar</button>
                <button
                    style={{
                        opacity: isFormValid ? 1 : .5
                    }}
                    onClick={() => isFormValid ? save() : focusOnLastRequiredField()}>Salvar</button>
            </Controllers>
        </>
    )
}