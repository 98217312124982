export default class EmailValidator {
    constructor() {
        this.regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    validate(string) {
        const errorMessage = "E-mail inválido";
        if(!this.regex.test(string)) {
            return errorMessage;
        }
        return "";
    }
}