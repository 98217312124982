import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  @media screen and (max-width: 720px) {
    position: static;
    width: 100vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    position: static;
    width: 100vw;
  }
`;

export const Top = styled.div`
  width: 100%;
  background-color: var(--yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  > div {
    width: var(--width-m);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    button,
    a {
      background-color: transparent;
      border: none;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
    }

    button:hover,
    a:hover {
      background-color: var(--pink);
    }
    button.notification {
      position: relative;
      span {
        position: absolute;
        bottom: -1px;
        right: -7px;
        background-color: var(--pink);
        border: 2px solid var(--white);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: 600;
        border-radius: 50%;
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    padding: 0 8px;
  }
  @media screen and (max-width: 1300px) {
    width: 100vw;
    padding: 0 26px;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.bodyBackground};
  box-shadow: -2px 2px 5px 0px ${(props) => props.theme.textColor}A6;
  -webkit-box-shadow: -2px 2px 5px 0px ${(props) => props.theme.textColor}A6;
  -moz-box-shadow: -2px 2px 5px 0px ${(props) => props.theme.textColor}A6;
  height: 100px;
  display: flex;
  align-items: center;
  > div {
    margin: 0 auto;
    width: var(--width-m);
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo {
    width: 90px;
    img {
      width: 100%;
    }
  }
  nav {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a:hover,
    button:hover {
      background-color: var(--pink);
      color: var(--white);
    }
    button,
    a,
    > div {
      font-weight: 700;
      text-transform: uppercase;
      font-size: ${(props) => props.theme.fontSize + 12}px;
      padding: 7px 10px;
      background-color: none;
      border: none;
      background: none;
      &.active {
        background-color: var(--pink);
        color: var(--white);
      }

      &:nth-child(6) {
        position: relative;
      }
      div.collapse-not-logged,
      div.collapse {
        position: absolute;
        top: 100%;
        width: 250px;
        display: flex;
        flex-direction: column;
        background-color: var(--pink);
        button {
          color: var(--white);
          padding: 10px 10px;
          align-self: flex-start;
        }
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 0 32px;
    nav {
      flex: none;
      flex-direction: column;
      margin: 0 52px;
      width: 50vw;
      word-break:keep-all;
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 15px;
        top: 165px !important;
        position: absolute !important;
      }
      a {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 1201px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 245px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 1101px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 196px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
  @media screen and (max-width: 1100px) and (min-width: 1001px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 153px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 901px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 110px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
  @media screen and (max-width: 900px) and (min-width: 801px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 68px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
  @media screen and (max-width: 800px) and (min-width: 721px) {
    padding: 16px;
    nav {
      div.collapse {
        right: -125px;
      }
      div.collapse-not-logged {
        right: 27px;
        top: 114px !important;
        position: absolute !important;
      }
    }
  }
`;

export const Space = styled.div`
  display: block;
  height: 150px;
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

export const ContrastButton = styled.button`
  height: 48px;
  width: 48px;
  font-weight: 700;
  font-size: 30px;
  border-radius: 7px;
  border: none;
  position: relative;

  &:nth-child(1) {
    color: #000;
  }

  &:nth-child(2) {
    color: #000;
    border: 1px solid #000 !important;
  }

  &:nth-child(3) {
    color: #fff;
    border: 1px solid #fff !important;
  }

  &:nth-child(4) {
    color: #e9b00d;
    border: 1px solid #e9b00d !important;
  }
`;

export const ActiveSpan = styled.span`
  background-color: ${(props) => props.theme.highlight} !important;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 256ms;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.textColor} !important;
`;
