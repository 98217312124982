import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: var(--width-m);
  margin: 30px auto;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: transparent;
    border: none;
    h2 {
      font-size: ${(props) => props.theme.fontSize + 32}px;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 720px) {
    width: fit-content;
    margin: 30px 16px;
    word-break: keep-all;
    h2 {
      text-align: left;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: fit-content;
    margin: 30px 16px;
    word-break: keep-all;
    h2 {
      text-align: left;
    }
  }
`;
