import styled from 'styled-components';

export const Wrapper = styled.div`
  > div.cards {
    width: var(--width-m);
    margin: 0 auto;
    display: grid;
    grid-gap: 50px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 50px;
  }
`;

export const Container = styled.main`
  padding: 50px 0px;

  width: var(--width-m);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
  }
`;

export const Content = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize + 32}px;
  }
  > div {
    display: grid;
    grid-gap: 20px;
    align-items: start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 720px) {
    > div {
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    > div {
      margin: 0 32px;
      grid-template-columns: repeat(3, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    > div {
      margin: 0 32px;
    }
  }
`;
