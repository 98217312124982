import { BASE_URL } from '../../services/api'
import {
    Card2,
    ImagePlaceholder,
    InvisibleLabel
} from './styles'

export const SmallBookCard = ({ book, action, showDownloads }) => {
    let getFirstAvailableImage = book.images[0]

    let url = getFirstAvailableImage ? getFirstAvailableImage.file?.url : false
    // let fallbackImageDescription = getFirstAvailableImage.label ?? "Imagem da obra"
    let ariaLabel = `Imagem do livro ${book.bookTitle ?? "livro sem nome cadastrado"}}`

    if (url) {
        ariaLabel = getFirstAvailableImage.label
    }

    return <Card2 onClick={action}>
        {/* <img src={book.images[0]?.url ?? undefined} alt="" />
         */}

        <ImagePlaceholder
            bgImage={url ? url : undefined}
            tabIndex={1}
            aria-label={ariaLabel}
        />
        {
            showDownloads ?
                <p className="position">{book.downloads ?? 0}</p>
                : null
        }
        <div>
            <h3 tabIndex={0} aria-label={
                `Ir para livro: ${book.bookTitle ?? "livro sem nome cadastrado"}} do autor: ${book.author ?? "livro sem autor cadastrado"}`} 
                onKeyDown={({keyCode}) => keyCode === 13 ? action() : null}>
                    <InvisibleLabel>Livro: </InvisibleLabel>
                    {book.bookTitle ?? "livro sem nome cadastrado"}
                    </h3>
            <p><InvisibleLabel>Autor: </InvisibleLabel>{book.author ?? "livro sem autor cadastrado"}</p>
        </div>

    </Card2>
}