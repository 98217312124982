import styled from 'styled-components';

export const Container = styled.section`
  width: var(--width-pp);
  margin: 0 auto;
  padding-bottom: 50px;
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin: 0 32px;
  }
`;

export const Form = styled.div`
  width: 100%;
  > div {
    padding-bottom: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    input,
    select {
      height: 50px;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.textColor}26;
      font-size: ${(props) => props.theme.fontSize + 16}px;
      padding: 0px 10px;
      color: ${(props) => props.theme.textColor};
      &::placeholder {
        color: ${(props) => props.theme.textColor}88;
      }
    }
    textarea {
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.textColor}26;
      font-size: ${(props) => props.theme.fontSize + 16}px;
      padding: 10px;
      color: ${(props) => props.theme.textColor};
      background-color: ${(props) => props.theme.backgroundColor};
      height: 200px;
      resize: vertical;
      &::placeholder {
        color: ${(props) => props.theme.textColor}88;
      }
    }
    label {
      font-size: ${(props) => props.theme.fontSize + 14}px;
      font-weight: 400;
    }
    select,
    option,
    input {
      color: ${(props) => props.theme.textColor};
      background-color: ${(props) => props.theme.backgroundColor};
    }
  }
  > button {
    width: 178px;
    height: 50px;
    background-color: var(--pink);
    color: var(--white);
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize + 20}px;
    text-transform: uppercase;
    border: none;
    border-radius: 7px;
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    > div {
      select {
        width: 75%;
      }
      > div {
        width: 75% !important;
      }
    }
  }
`;
