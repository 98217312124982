import { Container, Content } from "./styles";
import { Pagination } from "../Pagination";
import api from "../../services/api";
import { useState, useEffect } from "react";
import { NewsCard } from "../NewsCard";


export function MainNews() {

    const [news, setNews] = useState([])

    const [pagination, setPagination] = useState(0)

    const PAGINATION_STEP_SIZE = 8

    const fetchForNewsAndSponsoredProjects = async () => {
        try {
            const response = await api.get(`/articles?_limit=-1&sponsor=false`)

            if (response) {
                if (response.status === 200) {
                    setNews(response.data.filter(({inactive}) => !inactive))
                    return
                }
                throw new Error('Unexpected response')
            }
            throw new Error('Network error')
        } catch (error) {
            console.log(error, 'error while fetching for news and sponsored news')
        }
    }

    useEffect(() => {
        fetchForNewsAndSponsoredProjects()
    }, [])

    return (
        <Container>
            <Content>
                <div>
                    {
                        news.slice(PAGINATION_STEP_SIZE * pagination, Math.min(news.length, PAGINATION_STEP_SIZE * (pagination + 1))).map((item, key) => <NewsCard
                            news={item}
                            key={key + 'news'} />)
                    }
                </div>
            </Content>
            <Pagination
                value={pagination}
                onChange={setPagination}
                paginationSize={PAGINATION_STEP_SIZE}
                itemQuantity={news.length}
            />
        </Container>
    )
}