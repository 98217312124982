import { useState } from "react";
import { Container, Line, Empty, Controllers } from "./styles";
import { Wizard } from "../../../components/Wizard";
import { Input } from "../../../components/Input";

import ValidationBuilder from '../../../data/validation/builder'
import { maskDocumentInput } from "../../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../../data/regex";
import { ModalDefault } from "../../../components/ModalDefault";
import { UploadInput } from "../../../components/UploadInput";

const cpfValidator = ValidationBuilder("cpf")

const emailValidator = ValidationBuilder("email")

export function Password(props) {

    const {
        cpf, 
        email, 
        email2, 
        password,
        password2, 
        foreigner, 
        setForeigner, 
        foreignerDocument, 
        setForeignerDocument, 
        foreignerDocumentFile, 
        setForeignerDocumentFile,
        changeStep
    } = props

    const [targetField, setTargetField] = useState('cpf')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    function forNext(){
        if(password === password2){
            props.changeStep(3)
        } else {
            setModalError(true)
            setModalErrorMessage("As senhas precisam ser iguais")
        }
    }

    const hasEmail = email !== '' || email2 !== '';

    const isCPFValid = !cpfValidator.validateString(cpf.replace(NUMBER_ONLY, "")).error

    const isEmailValid = email !== '' || foreigner ? !emailValidator.validateString(email).error : true;

    const isEmailMatching = hasEmail || foreigner ? email === email2 : true

    const isPasswordValid = password.length > 7
    const isPasswordMatching = password === password2

    const validateForeignerDocument = foreignerDocument !== '';
    
    const imageOrPDFRegex = new RegExp(/jpg|jpeg|png/i)
    
    const isFileValid = imageOrPDFRegex.test(foreignerDocumentFile?.name ?? '')
    const validateForeignerDocumentFile = foreignerDocumentFile !== '';

    const disableButton = foreigner ? !(validateForeignerDocument && validateForeignerDocumentFile&& isFileValid && isEmailValid && isEmailMatching && isPasswordValid && isPasswordMatching) 
                : !(isCPFValid && (hasEmail ? isEmailValid && isEmailMatching : true) && isPasswordValid && isPasswordMatching)

    const validationObject = {
        cpf: {
            field: 'cpf',
            message: 'O campo de CPF está inválido'
        },
        email: {
            field: 'email',
            message: 'O campo e-mail está inválido'
        },
        emailMismatch: {
            field: 'email2',
            message: 'Os campos de e-mail não coincidem',
        },
        password: {
            field: 'password',
            message: 'O campo de senha precisa de 8 caracteres ou mais'
        },
        passwordMismatch: {
            field: 'password2',
            message: 'Os campos de senha não coincidem'
        },
        foreignerDocumentIsEmpty: {
            field: 'foreignerDocument',
            message: 'O campo de documento do estrangeiro não pode estar vazio'
        },
        foreignerDocumentFileIsEmpty: {
            field: 'foreignerDocumentFile',
            message: 'O upload documento do estrangeiro não pode estar vazio'
        },
        foreignerDocumentFileFormat: {
            field: 'foreignerDocumentFileFormat',
            message: 'Arquivo de documento para estrangeiros em formato inválido. Por favor use os formatos: JPEG ou PNG.'
        }
    };

    const focusOnLastRequiredField = () => {
        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' : !isPasswordValid ? 'password' : !isPasswordMatching ? 'passwordMismatch' : '';

        const getErrorEntryForeigner = !validateForeignerDocument ? 'foreignerDocumentIsEmpty' : !validateForeignerDocumentFile 
        ? 'foreignerDocumentFileIsEmpty' : !isFileValid ? 'foreignerDocumentFileFormat' : !isEmailValid ? 'email' : !isEmailMatching 
        ? 'emailMismatch' : !isPasswordValid ? 'password' : !isPasswordMatching ? 'passwordMismatch' : ''

        const getFirstInvalidField = foreigner ? validationObject[getErrorEntryForeigner] :validationObject[getErrorEntry]
        
        setTargetField(' ')
        
        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.field)
    }

    const closeModalShowLastRequiredField = () => {

        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' : !isPasswordValid ? 'password' : !isPasswordMatching ? 'passwordMismatch' : ''
        const getFirstInvalidField = validationObject[getErrorEntry]

        setModalError(false)
        setTargetField(' ')

        if(getFirstInvalidField.field === targetField) {
            setTargetField(' ')
            const timedOutSetField = setTimeout(() => 
            setTargetField(getFirstInvalidField.field), 256)
            return () => clearTimeout(timedOutSetField)
        }
    }

    return (
        <>
            <Wizard step={2} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container tabIndex={1}>
                <Line>
                    {foreigner ? (
                        <Input 
                            autofocus={targetField === 'cpf' || targetField.length === 0} 
                            value={foreignerDocument} 
                            onChange={(v) => setForeignerDocument(v)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" 
                            label="Documento de identificação para estrangeiro" 
                            placeholder="Documento de identificação para estrangeiro"
                            required
                        />
                    ) : (
                        <Input 
                            autofocus={targetField === 'cpf' || targetField.length === 0} 
                            value={cpf} 
                            errorMessage={cpf.length > 0 && !isCPFValid ? 'CPF inválido' : ''}
                            onChange={(v) => props.setCpf(maskDocumentInput(v.replace(NUMBER_ONLY, "").slice(), "cpf"))}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            type="text" 
                            label="CPF" 
                            placeholder="CPF para login"
                            required
                            disabled={foreigner}
                        />
                    )}
                    <div className="check">
                        <input type="checkbox"
                            id="foreigner"
                            tabIndex={1}
                            aria-label="Sou estrangeiro" 
                            onChange={(e) => setForeigner(!foreigner)}
                            checked={foreigner}></input>
                        <label htmlFor="foreigner">Sou estrangeiro</label>
                    </div>
                </Line>
                {foreigner ? (
                    <Line>
                        <UploadInput
                            required
                            tabIndex={0}
                            value={foreignerDocumentFile}
                            autofocus={targetField === 'foreignerDocumentFile'}
                            onChange={setForeignerDocumentFile}
                            aria-label="Upload do documento de identificação de estrangeiros"
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                            label="Upload do documento de identificação de estrangeiros" />
                        <Empty></Empty>
                    </Line>
                ) : null}
                <Line>
                    <Input required={foreigner} autofocus={targetField === 'email'} value={email} onChange={(v) => props.setEmail(v)} onBlur={(e) => { (!e.target.value) && (hasEmail || foreigner) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}  label="Email" type="text" placeholder="Email para login" />
                    <Input required={foreigner} autofocus={targetField === 'email2'} value={email2} onChange={(v) => props.setEmail2(v)} onBlur={(e) => { (!e.target.value) && (hasEmail || foreigner) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}  label="Confirmar Email" type="text" placeholder="Confirmar email para login" />
                </Line>
                <Line>
                    <Input autofocus={targetField === 'password'} value={password} onChange={(v) => props.setPassword(v)} onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }} label="Senha" type="password" placeholder="Senha de acesso" required />
                    <Input autofocus={targetField === 'password2'} value={password2} onChange={(v) => props.setPassword2(v)} onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }} label="Repetir senha" type="password" placeholder="Repetir senha de acesso" required />
                </Line>
            </Container>
            <Controllers>
                <button tabIndex={1} onClick={() => {changeStep(1)}}>Anterior</button>
                <button 
                style={{
                    opacity: disableButton ? .5 : 1
                }}
                tabIndex={1}
                onClick={() => disableButton ? focusOnLastRequiredField() : forNext()}>Avançar</button>
            </Controllers>
        </>
    )
}