import {
    Container,
    Body,
    HeaderSummary,
    HeaderRow,
    HeaderNewsType,
    HeaderShareLink,
    Title,
    DateText,
    MainArticle,
    ImageArticle,
    ImageArticleImage,
    TextArticle,
    ImageCaption,
    InivisibleLabel
} from "./styles";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { GoBack } from '../../components/GoBack';
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import api, { BASE_URL } from "../../services/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import 'moment/locale/pt-br'

import { ArticleSection } from "../../components/ArticleSection";

export function InternalNews(props) {
    const [news, setNews] = useState({})
    // console.log("🐌🧲 ~ file: index.jsx ~ line 29 ~ InternalNews ~ news", news)


    const { match } = props
    const extractedID = match.params.id ?? -1

    const id = Number.isNaN(parseInt(extractedID)) ? -1 : extractedID
    const fetchForCurrentNews = async () => {
        try {
            const response = await api.get(`/articles/${id}`)

            if (response) {
                if (response.status === 200) {
                    setNews(response.data)
                }
            }
        } catch (error) {
            console.log(error, 'error')
            history.push('/')
        }
    }
    const history = useHistory()
    useEffect(() => {
        if (id !== -1) {
            fetchForCurrentNews()
        } else {
            history.push('/')
        }
    }, [])

    const focus = useRef()

    useLayoutEffect(() => {
        focus.current?.focus();
    }, [news])

    let targetFileCover = {}
    if (news.id) {
        if (news.cover) {
            targetFileCover = news.cover
        } else {
            const getElegibleImagesFromArticles = news.sections.filter((item) => item.images.length > 0)
            if (getElegibleImagesFromArticles.length > 0) {
                const firstImage = getElegibleImagesFromArticles[0].images[0]
                targetFileCover = firstImage

            }
        }
    }
    return (
        <>
            <Body >
                <Header menu={news.sponsor === undefined ? 0 : news.sponsor ? 4 : 2} />
                {
                    news.id !== undefined ?
                        <>
                            <GoBack title={news.sponsor ? 'Projetos' : 'Notícias'} />
                            <Container>
                                <HeaderSummary tabIndex={1}>
                                    <HeaderRow>
                                        <HeaderNewsType tabIndex={1}>
                                            <InivisibleLabel>
                                                Tipo de notícia:
                                            </InivisibleLabel>  {news.sponsor ? 'Projetos' : 'Novidades'}
                                        </HeaderNewsType>
                                        <HeaderShareLink>
                                        </HeaderShareLink>
                                    </HeaderRow>
                                    <Title ref={focus} tabIndex={1}>
                                        <InivisibleLabel>
                                            Página da notícia, Título:
                                        </InivisibleLabel>
                                        {news.title}
                                    </Title>
                                    <DateText tabIndex={1}>
                                        <InivisibleLabel>
                                            Data:
                                        </InivisibleLabel> {moment(news.created_at).locale("pt-br").format('LL')}
                                    </DateText>
                                </HeaderSummary>
                                <MainArticle tabIndex={0}>
                                    <ImageArticle >
                                        <ImageArticleImage>
                                            <img
                                                tabIndex={1}
                                                src={news.banner ? news.banner.url : undefined}
                                                alt={news.label ?? 'Imagem de capa do artigo'} />
                                        </ImageArticleImage>
                                        <ImageCaption
                                            tabIndex={1}
                                        >
                                            {news.caption ?? ''}
                                        </ImageCaption>
                                    </ImageArticle>
                                    {/* <TextArticle tabIndex={1}> */}
                                    {/* {news.introduction} */}
                                    {/* </TextArticle> */}
                                </MainArticle>
                                {
                                    news.sections.map(
                                        (sect, key) => <ArticleSection key={key + 'section'}
                                            {...sect}
                                        />)
                                }
                            </Container>
                        </>
                        : null
                }
                <Footer />
            </Body>
        </>
    )
}