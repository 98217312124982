import styled from 'styled-components';

export const Container = styled.section`
span.red{
    color: #F00;
}
  @media screen and (max-width: 720px) {
    width: 90vw;
    padding: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90vw;
    padding: 0 16px;
  }
`;
