import styled from 'styled-components';

export const Container = styled.section`
  position: relative;

  span.red {
    color: #f00;
  }

  input.error {
    border-color: #f00;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    padding: 0 32px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    padding: 0 32px;
  }
`;
