import { Banner } from "../../components/Banner";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { MainHome } from "../../components/MainHome";

export function Home() {
    //atualizar 0 a cada push na master
    console.log("V1.0.10")
    return (
        <>
            <Header />
            <Banner />
            <MainHome />
            <Footer />
        </>
    )
}