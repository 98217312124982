import { useEffect } from "react";
import { AuthProvider } from "./contexts/auth"
import Router from "./router/router"
import 'moment/dist/locale/pt-br'
function App() {
  useEffect(() => {
    let prod = process.env.REACT_APP_PRODUCTION_ENVIRONMENT === "true"
    if(prod){
      const script = document.createElement('script');
      script.src = "https://plugin.handtalk.me/web/latest/handtalk.min.js";
      
      const script2 = document.createElement('script');

      const token = "a4ec4c6c4c568947ed4826e1ed63af3d";
      script.onload = () => {
        script2.innerHTML = ` 
          var ht = new HT({
            token: "${token}"
          });
        ` 
      }

      document.body.appendChild(script);
      document.body.appendChild(script2);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(script2);
      }
    }
  }, []);
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  )
}


export default App;
