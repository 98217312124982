import { Container } from "./styles";

import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import { useEffect } from "react";

export function Pagination({
    value,
    onChange,
    paginationSize,
    itemQuantity
}) {

    const paginationQuantity = Math.floor(itemQuantity / paginationSize) - 1 + (itemQuantity % paginationSize > 0 ? 1:0)
    
    useEffect(() => {
        if(value > paginationQuantity) {
            onChange(0)
        }
    }, [value])

    const workedValue = value + 1

    if(paginationQuantity < 1 || itemQuantity === 0) return null
    return (
        <Container>
            <button
                style={{
                    opacity: value - 1 >= 0 ? 1 : .5
                }}
                onClick={() => value - 1 >= 0 ? onChange(value - 1) : null}
                className="prev"> <BsChevronDoubleLeft /> Anterior</button>

            {
                value - 2 >= 0 ?
                    <button onClick={() => onChange(value - 2)}
                        className="item">{workedValue - 2}</button>
                    :
                    null
            }

            {
                value - 1 >= 0 ?
                    <button
                        onClick={() => onChange(value - 1)}
                        className="item">
                        {workedValue - 1}</button>
                    :
                    null
            }
            <button className="item active">{workedValue}</button>

            {
                value + 1 <= paginationQuantity ?
                    <button onClick={() => onChange(value + 1)} className="item">{workedValue + 1}</button>
                    :
                    null
            }

            {
                value + 2 <= paginationQuantity ?
                    <button onClick={() => onChange(value + 2)} className="item">{workedValue + 2}</button>
                    :
                    null
            }
            <button
                style={{
                    opacity: value + 1 <= paginationQuantity ? 1 : .5
                }}
                onClick={() => value + 1 <= paginationQuantity ? onChange(value + 1) : null}
                className="next">Próximo <BsChevronDoubleRight /></button>
        </Container>
    )
}