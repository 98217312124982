import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Container, Search, Collapses } from "./styles";
import { GoBack } from '../../components/GoBack';
import { FiSearch } from "react-icons/fi";
import { Hide } from "../../components/Hide";
import { useState, useEffect } from "react";
import api from '../../services/api'
import { replaceAllAcemptuation } from "../../data/formatting/latin-replace-acemptuation/latin-replace-acemptuation";
import { NoMatchError } from "../../components/NoMatchError";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

export function Answers() {
    const [faq, setFaq] = useState([])
    // console.log("🐌🧲 ~ file: index.jsx ~ line 11 ~ HelpLibraries ~ libraries", libraries)
    const [search, setSearch] = useState('')

    const fetchForFAQ = async () => {
        try {
            const response = await api.get(`/common-questions`)

            if (response) {
                if (response.status === 200) {
                    setFaq(response.data.filter(({inactive}) => !inactive))
                    return
                }
            }
        } catch (error) {
            console.log(error, 'error while fetching for resources')
        }
    }

    useEffect(() => {
        fetchForFAQ()
    }, [])

    let filterByString = faq

    if (search.length > 0) {
        const workedSearchString = replaceAllAcemptuation(search)
        filterByString = faq.filter(({ title, }) => replaceAllAcemptuation(title).search(workedSearchString) > -1)
    }
    return (
        <>
            <Header menu={5} />
            <GoBack title="Perguntas e Respostas" />
            <Container>
                <Search>
                    <div>
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Buscar perguntas" />
                        <button tabIndex={-1} aria-hidden={true}> <FiSearch /> </button>
                    </div>
                </Search>
                <Collapses>
                    {
                        filterByString.map(({ title, description, url }, key) => <Hide
                            title={title}
                            key={key + 'resources'}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
                            <a href={url} target="_blank" aria-label={`Link para a ferramenta ${title}`} rel="noreferrer">
                                {url}
                            </a>
                        </Hide>)
                    }
                    {
                        filterByString.length === 0 ?
                            <NoMatchError />
                            : null
                    }
                </Collapses>
            </Container>
            <Footer />
        </>
    )
}