import styled from 'styled-components';

import logoImg from '../../assets/logo-banner.svg';

export const Container = styled.section`
  background-color: var(--purple);
  background-image: url(${logoImg});
  background-position: center;
  background-position-x: -1011px;
  background-size: 212%;
  height: 480px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: var(--width-m);
    gap: 15px;

    > span {
      color: var(--white);
      font-weight: 700;
      font-size: ${(props) => props.theme.fontSize + 18}px;
    }
    > h1 {
      color: var(--white);
      font-weight: 700;
      font-size: ${(props) => props.theme.fontSize + 32}px;
    }
    > p {
      color: var(--yellow);
      font-weight: 600;
      margin-bottom: 50px;
      font-size: ${(props) => props.theme.fontSize + 16}px;
    }
    > div.search {
      height: 50px;
      width: 568px;
      position: relative;
      display: flex;
      input {
        background-color: ${(props) => props.theme.backgroundColor};
        width: 100%;
        margin-top: 0;
        border: none;
        border-radius: 10px;
        height: 50px;
        font-weight: 700;
        padding: 0px 135px 0px 20px;
        color: ${(props) => props.theme.textColor};
        ::placeholder {
          color: ${(props) => props.theme.textColor};
        }
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        height: 50px;
        width: 115px;
        border-radius: 0px 10px 10px 0px;
        border: none;
        background-color: var(--yellow);
        color: var(--white);
        font-size: ${(props) => props.theme.fontSize / 4 + 14}px;
        font-weight: 700;
      }
    }
    > a {
      background: transparent;
      border: none;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      img {
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    padding: 32px;
    height: fit-content;
    .search {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    padding: 32px;
    height: fit-content;
    .search {
      width: 100% !important;
    }
  }
`;
