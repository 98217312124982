import moment from 'moment'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import {
    Card2,
    ImageDivWithFallback
} from './styles'

export const NewsCard = ({ news }) => {

    const filteredIntroduction = (news.introduction ?? "")?.length > 150 ?
        news.introduction.slice(0, 140) + '...' : news.introduction

    // console.log(url ? BASE_URL + url : undefined)
    const history = useHistory()

    const handleRedirectToNews = () => {
        // setSelectedNews(news)
        history.push(`/noticias/ler/${news.id}`)
    }
    return <Card2
        onClick={handleRedirectToNews}>
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column-reverse'
        }}>

            <div className="foot">
                {/* <span>Tag 1</span>
                <span>Tag 2</span> */}
            </div>
            <div className="body">
                <h3 tabIndex={1} onClick={handleRedirectToNews} 
                onKeyDown={({ keyCode }) => keyCode === 13 ? handleRedirectToNews() : null}>{news.title}</h3>
                <p tabIndex={1}>{filteredIntroduction}</p>
            </div>
            <div className="head">
                <ImageDivWithFallback
                    bgImage={news.banner ? news.banner.url : false}
                    tabIndex={2}
                    aria-label={news.label ?? `Imagem de capa do artigo ${news.title}`}
                />
                <div className="categorie">
                    <p tabIndex={1}>Novidades</p>
                    <span tabIndex={1} aria-label={moment(news.created_at).format("LLL")}>{moment(news.created_at).format("DD/MM/yyyy")}</span>
                </div>
            </div>
        </div>
    </Card2>
}