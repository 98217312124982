export const ReactiveLogo = ({ color }) => {
    return <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="118.022" height="103" viewBox="0 0 118.022 103">
        <g id="Logo" transform="translate(-153.529 -197.55)">
            <g id="Dorinateca" transform="translate(153.529 282.473)">
                <g id="Grupo_7" data-name="Grupo 7" transform="translate(0)">
                    <path id="Caminho_8" data-name="Caminho 8" d="M162.985,634.237v14.14c0,1.379.05,2.833.1,3.66H159.7l-.175-1.805h-.05a4.28,4.28,0,0,1-3.836,2.081c-2.908,0-5.24-2.482-5.24-6.293-.025-4.137,2.557-6.519,5.491-6.519a3.735,3.735,0,0,1,3.234,1.379h.05v-6.644Zm-3.811,10.731a6.148,6.148,0,0,0-.05-.677,2.289,2.289,0,0,0-2.231-1.88c-1.73,0-2.633,1.554-2.633,3.485,0,2.081,1.028,3.385,2.607,3.385a2.231,2.231,0,0,0,2.206-1.83,3.242,3.242,0,0,0,.1-.877Z" transform="translate(-150.399 -634.237)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_9" data-name="Caminho 9" d="M241.98,667.579a6.151,6.151,0,0,1-6.469,6.544,6.005,6.005,0,0,1-6.343-6.318c0-3.961,2.607-6.493,6.544-6.493A5.961,5.961,0,0,1,241.98,667.579Zm-8.875.125c0,2.106.877,3.685,2.507,3.685,1.479,0,2.432-1.479,2.432-3.685,0-1.83-.7-3.686-2.432-3.686C233.781,664.019,233.1,665.9,233.1,667.7Z" transform="translate(-213.85 -656.046)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_10" data-name="Caminho 10" d="M308.681,665.623c0-1.805-.05-2.983-.1-4.036h3.284l.125,2.256h.1a3.6,3.6,0,0,1,3.31-2.532,3.934,3.934,0,0,1,.8.05v3.585a5.458,5.458,0,0,0-1.028-.1,2.45,2.45,0,0,0-2.607,1.931,4.487,4.487,0,0,0-.075.852v6.218h-3.811Z" transform="translate(-277.819 -656.046)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_11" data-name="Caminho 11" d="M363.536,637.2a1.913,1.913,0,0,1-2.056,1.905,1.919,1.919,0,1,1,.025-3.836A1.911,1.911,0,0,1,363.536,637.2Zm-3.936,15.67v-12.26h3.811v12.26Z" transform="translate(-318.835 -635.068)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_12" data-name="Caminho 12" d="M396.857,665.5c0-1.529-.05-2.833-.1-3.911h3.31l.175,1.68h.075a4.488,4.488,0,0,1,3.786-1.955c2.507,0,4.387,1.655,4.387,5.265v7.271H404.68v-6.794c0-1.58-.552-2.658-1.931-2.658a2.034,2.034,0,0,0-1.93,1.429,2.617,2.617,0,0,0-.151.953v7.07h-3.811Z" transform="translate(-348.847 -656.046)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_13" data-name="Caminho 13" d="M478.4,673.847l-.226-1.228H478.1a4.417,4.417,0,0,1-3.51,1.5,3.758,3.758,0,0,1-3.961-3.761c0-3.184,2.858-4.714,7.2-4.688V665.5c0-.652-.351-1.58-2.232-1.58a6.9,6.9,0,0,0-3.385.927l-.7-2.457a10.121,10.121,0,0,1,4.764-1.078c4.087,0,5.39,2.407,5.39,5.29v4.262a18.985,18.985,0,0,0,.175,2.983Zm-.451-5.792c-2.006-.025-3.56.451-3.56,1.931a1.369,1.369,0,0,0,1.5,1.454,2.082,2.082,0,0,0,1.981-1.4,2.732,2.732,0,0,0,.075-.652Z" transform="translate(-408.352 -656.046)"
                        fill={color || "#1d1d1b"}
                    />
                </g>
                <g id="Grupo_6" data-name="Grupo 6" transform="translate(74.85 2.181)">
                    <path id="Caminho_14" data-name="Caminho 14" d="M543.247,645.453v3.485h3.159v1.68h-3.159v6.544c0,1.5.426,2.357,1.655,2.357a4.906,4.906,0,0,0,1.279-.15l.1,1.655a5.4,5.4,0,0,1-1.956.3,3.057,3.057,0,0,1-2.382-.928,4.5,4.5,0,0,1-.853-3.159v-6.619h-1.88v-1.68h1.88V646.03Z" transform="translate(-539.21 -645.453)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_15" data-name="Caminho 15" d="M587.727,668.7a3.888,3.888,0,0,0,4.162,4.212,7.977,7.977,0,0,0,3.359-.627l.376,1.58a9.832,9.832,0,0,1-4.037.752c-3.736,0-5.967-2.457-5.967-6.117s2.156-6.544,5.691-6.544c3.961,0,5.014,3.485,5.014,5.716a8.309,8.309,0,0,1-.075,1.028Zm6.469-1.579c.025-1.4-.577-3.585-3.059-3.585-2.231,0-3.209,2.056-3.385,3.585Z" transform="translate(-576.595 -658.746)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_16" data-name="Caminho 16" d="M661.668,674.018a8.128,8.128,0,0,1-3.485.7c-3.66,0-6.042-2.482-6.042-6.193a6.166,6.166,0,0,1,6.518-6.443,7.372,7.372,0,0,1,3.059.627l-.5,1.7a5.117,5.117,0,0,0-2.557-.577c-2.783,0-4.287,2.056-4.287,4.588,0,2.808,1.805,4.538,4.212,4.538a6.268,6.268,0,0,0,2.708-.6Z" transform="translate(-630.179 -658.85)"
                        fill={color || "#1d1d1b"}
                    />
                    <path id="Caminho_17" data-name="Caminho 17" d="M719.114,674.365l-.176-1.529h-.075a4.519,4.519,0,0,1-3.711,1.805,3.457,3.457,0,0,1-3.711-3.485c0-2.933,2.607-4.538,7.3-4.513v-.251a2.5,2.5,0,0,0-2.758-2.808,6.048,6.048,0,0,0-3.159.9l-.5-1.454a7.532,7.532,0,0,1,3.987-1.078c3.711,0,4.613,2.532,4.613,4.964v4.538a17.082,17.082,0,0,0,.2,2.908Zm-.326-6.193c-2.407-.05-5.14.376-5.14,2.733a1.953,1.953,0,0,0,2.081,2.106,3.019,3.019,0,0,0,2.933-2.031,2.3,2.3,0,0,0,.125-.7Z" transform="translate(-677.948 -658.746)"
                        fill={color || "#1d1d1b"}
                    />
                </g>
            </g>
            <g id="Grupo_8" data-name="Grupo 8" transform="translate(165.886 197.55)">
                <path id="Caminho_1" data-name="Caminho 1" d="M362.585,278.037c1.686-.011,3.376-.142,5.058-.079a21.832,21.832,0,0,1,3.785.522,4.612,4.612,0,0,1,1.571.883.748.748,0,1,0,1.2,0,4.5,4.5,0,0,1,1.543-.883,21.062,21.062,0,0,1,3.716-.522c1.651-.064,3.31.068,4.965.079,2.359.015,4.72.052,7.078.021a19.5,19.5,0,1,0-37.259-.021C357.019,278.1,359.8,278.054,362.585,278.037Z" transform="translate(-328.372 -242.058)" fill="#ffdc00" fillRule="evenodd" />
                <path id="Caminho_2" data-name="Caminho 2" d="M329.6,404.818q0,9.355-.005,18.71a1.452,1.452,0,0,1-.135.71.509.509,0,0,1-.908-.148c-.253-1.289-1.3-1.517-2.28-1.642a31.47,31.47,0,0,0-4.047-.178c-3.43.009-6.859.115-10.288.086a23.636,23.636,0,0,1-7.675-1.044,8.285,8.285,0,0,1-3.15-1.8,1.4,1.4,0,0,1-.458-1.076q.013-18.574.02-37.148a1.076,1.076,0,0,1,.313-.794c.329-.221.609.08.856.333a7.578,7.578,0,0,0,4.146,2,29.938,29.938,0,0,0,4.685.559c2.819.086,5.642.032,8.463.013,1.6-.011,3.206-.142,4.8-.079a19.75,19.75,0,0,1,3.594.522,4.324,4.324,0,0,1,1.492.883,1.614,1.614,0,0,1,.58,1.381Q329.586,395.463,329.6,404.818Z" transform="translate(-285.9 -344.857)" fill="#fbda12" />
                <path id="Caminho_3" data-name="Caminho 3" d="M462.613,404.818q0,9.355.005,18.71a1.45,1.45,0,0,0,.135.71.509.509,0,0,0,.908-.148c.253-1.289,1.3-1.517,2.28-1.642a31.47,31.47,0,0,1,4.047-.178c3.429.009,6.859.115,10.288.086a23.633,23.633,0,0,0,7.675-1.044,8.284,8.284,0,0,0,3.15-1.8,1.4,1.4,0,0,0,.458-1.076q-.013-18.574-.02-37.148a1.075,1.075,0,0,0-.313-.794c-.329-.221-.608.08-.856.333a7.579,7.579,0,0,1-4.146,2,29.944,29.944,0,0,1-4.685.559c-2.819.086-5.642.032-8.463.013-1.6-.011-3.205-.142-4.8-.079a19.749,19.749,0,0,0-3.594.522,4.323,4.323,0,0,0-1.492.883,1.616,1.616,0,0,0-.58,1.381Q462.622,395.463,462.613,404.818Z" transform="translate(-416.36 -344.857)" fill="#e9af0c" />
                <g id="Grupo_1" data-name="Grupo 1" transform="translate(7.977)">
                    <path id="Caminho_4" data-name="Caminho 4" d="M269.929,238.692l-4.1-.06c.2-13.9,4.21-24.572,11.909-31.73,10.819-10.059,24.873-9.365,25.464-9.33l-.235,4.1c-.128-.007-12.963-.6-22.471,8.272C273.67,216.308,270.116,225.981,269.929,238.692Z" transform="translate(-265.825 -197.55)" fill="#df4163" />
                </g>
                <g id="Grupo_2" data-name="Grupo 2" transform="translate(44.56 0)">
                    <path id="Caminho_5" data-name="Caminho 5" d="M487.209,238.692c-.186-12.711-3.74-22.384-10.563-28.75-9.508-8.872-22.343-8.279-22.471-8.272l-.235-4.1c.592-.034,14.645-.729,25.465,9.33,7.7,7.158,11.7,17.833,11.908,31.73Z" transform="translate(-453.94 -197.55)" fill="#df4163" />
                </g>
                <path id="Caminho_6" data-name="Caminho 6" d="M246.659,407.238c0-3.724,1.569-6.765,3.553-7.014V400.2h4.4v14.076h-4.4v-.024C248.228,414,246.659,410.962,246.659,407.238Z" transform="translate(-242.409 -360.79)" fill="#8463a6" />
                <rect id="Retângulo_1" data-name="Retângulo 1" width="2.817" height="6.728" rx="1.302" transform="translate(0 43.457)" fill="#8463a6" />
                <rect id="Retângulo_2" data-name="Retângulo 2" width="2.817" height="6.728" rx="1.302" transform="translate(86.877 43.457)" fill="#8463a6" />
                <path id="Caminho_7" data-name="Caminho 7" d="M631.064,407.238c0-3.724-1.569-6.765-3.553-7.014V400.2h-4.4v14.076h4.4v-.024C629.5,414,631.064,410.962,631.064,407.238Z" transform="translate(-545.649 -360.79)" fill="#8463a6" />
                <g id="Grupo_4" data-name="Grupo 4" transform="translate(18.942 46.623)">
                    <path id="Caminho_18" data-name="Caminho 18" d="M322.9,438.673a.707.707,0,1,1,.483-.207A.661.661,0,0,1,322.9,438.673Zm2.116,0a.707.707,0,1,1,.483-.207A.663.663,0,0,1,325.013,438.673Zm0,2.116a.707.707,0,1,1,.483-.207A.664.664,0,0,1,325.013,440.788Z" transform="translate(-322.207 -437.293)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_19" data-name="Caminho 19" d="M352.066,438.673a.708.708,0,1,1,.483-.207A.661.661,0,0,1,352.066,438.673Zm0,4.231a.709.709,0,1,1,.483-.207A.659.659,0,0,1,352.066,442.9Zm2.116-2.115a.707.707,0,1,1,.483-.207A.664.664,0,0,1,354.182,440.788Z" transform="translate(-345.703 -437.293)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_20" data-name="Caminho 20" d="M381.232,438.673a.707.707,0,1,1,.483-.207A.661.661,0,0,1,381.232,438.673Zm0,2.116a.708.708,0,1,1,.483-.207A.662.662,0,0,1,381.232,440.788Zm0,2.115a.708.708,0,1,1,.483-.207A.659.659,0,0,1,381.232,442.9Zm2.116-2.115a.707.707,0,1,1,.483-.207A.665.665,0,0,1,383.348,440.788Z" transform="translate(-369.197 -437.293)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_21" data-name="Caminho 21" d="M410.4,440.788a.708.708,0,1,1,.483-.207A.663.663,0,0,1,410.4,440.788Zm2.116-2.116a.707.707,0,1,1,.483-.207A.663.663,0,0,1,412.517,438.673Z" transform="translate(-392.694 -437.293)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_22" data-name="Caminho 22" d="M322.9,487.107a.707.707,0,1,1,.483-.207A.662.662,0,0,1,322.9,487.107Zm0,4.231a.708.708,0,1,1,.483-.207A.66.66,0,0,1,322.9,491.338Zm2.116-4.231a.707.707,0,1,1,.483-.207A.664.664,0,0,1,325.013,487.107Zm0,2.116a.708.708,0,1,1,.483-.207A.664.664,0,0,1,325.013,489.222Z" transform="translate(-322.207 -476.308)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_23" data-name="Caminho 23" d="M352.066,487.107a.708.708,0,1,1,.483-.207A.662.662,0,0,1,352.066,487.107Z" transform="translate(-345.703 -476.308)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_24" data-name="Caminho 24" d="M322.9,537.656a.708.708,0,1,1,.483-.207A.662.662,0,0,1,322.9,537.656Zm0,2.115a.708.708,0,1,1,.483-.207A.66.66,0,0,1,322.9,539.771Zm2.116-4.231a.707.707,0,1,1,.483-.207A.663.663,0,0,1,325.013,535.54Zm0,2.116a.707.707,0,1,1,.483-.207A.664.664,0,0,1,325.013,537.656Z" transform="translate(-322.207 -515.322)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_25" data-name="Caminho 25" d="M352.066,535.54a.708.708,0,1,1,.483-.207A.661.661,0,0,1,352.066,535.54Zm2.116,2.116a.707.707,0,1,1,.483-.207A.664.664,0,0,1,354.182,537.656Z" transform="translate(-345.703 -515.322)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_26" data-name="Caminho 26" d="M381.232,535.54a.707.707,0,1,1,.483-.207A.661.661,0,0,1,381.232,535.54Zm2.116,0a.707.707,0,1,1,.483-.207A.664.664,0,0,1,383.348,535.54Z" transform="translate(-369.197 -515.322)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                    <path id="Caminho_27" data-name="Caminho 27" d="M410.4,535.54a.707.707,0,1,1,.483-.207A.662.662,0,0,1,410.4,535.54Z" transform="translate(-392.694 -515.322)" fill="#d77610" stroke="#d67710" strokeMiterlimit="10" strokeWidth="1.469" />
                </g>
                <path id="Caminho_28" data-name="Caminho 28" d="M380.812,293.313a6.462,6.462,0,0,1,6.315,5.1l4.034-2.579h.01c.717,0,1.3,1.164,1.3,2.6a3.919,3.919,0,0,1-.508,2.061,19.3,19.3,0,0,0-.792-2.573v.057l-3.94,2.519a6.459,6.459,0,0,1-12.866-.345,6.554,6.554,0,0,0-2.781,0,6.459,6.459,0,0,1-12.872.3l-3.91-2.5,0-.1a19.392,19.392,0,0,0-.812,2.614,3.96,3.96,0,0,1-.49-2.033c0-1.436.582-2.6,1.3-2.6l4.025,2.553a6.46,6.46,0,0,1,12.663.218,6.556,6.556,0,0,1,2.97,0A6.462,6.462,0,0,1,380.812,293.313Z" transform="translate(-328.474 -274.69)" fill="#1d1d1b" fillRule="evenodd" />
            </g>
        </g>
    </svg>
}