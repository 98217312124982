import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Address } from "./Address";
import { MoreDatas } from "./MoreDatas";
import { Password } from "./Password";
import { PersonalData } from "./PersonalData";
import { Container } from "./styles";
import { Visual } from "./Visual";
import { useState, useContext, useEffect } from 'react';
import api from "../../services/api";
import moment from "moment";
import AuthContext from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { NUMBER_ONLY } from "../../data/regex";
import { ModalDefault } from "../../components/ModalDefault";

export function Register() {
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1);

    //step 1
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [socialName, setSocialName] = useState('');
    const [gender, setGender] = useState('');
    const [birth, setBirth] = useState('');
    const [minor, setMinor] = useState(false);
    const [marital, setMarital] = useState('');
    const [nationality, setNationality] = useState('');

    //step 2
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [foreigner, setForeigner] = useState(false);
    const [foreignerDocument, setForeignerDocument] = useState('');
    const [foreignerDocumentFile, setForeignerDocumentFile] = useState('');

    //step 3
    const [cep, setCep] = useState('');
    const [district, setDistrict] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [country, setCountry] = useState('');
    const [foreignerProofOfddress, setForeignerProofOfddress] = useState('');

    //step 4
    const [schooling, setSchooling] = useState('');
    const [profession, setProfession] = useState('');
    const [occupation, setOccupation] = useState('');
    const [fluencyInBraille, setFluencyInBraille] = useState('');
    const [screenReader, setScreenReader] = useState(false);
    const [screenMagnifier, setScreenMagnifier] = useState(false);
    const [brailleLine, setBrailleLine] = useState(false);
    const [eletronicGlass, setEletronicGlass] = useState(false);
    const [readingFluency, setReadingFluency] = useState('');
    const [searchingFor, setSearchingFor] = useState('');
    const [goToLibraries, setGoToLibraries] = useState(false);
    const [which, setWhich] = useState('');
    const [literaryEvents, setLiteraryEvents] = useState(false);
    const [equipament, setEquipament] = useState('');
    const [fdnc, setFdnc] = useState('');
    const [meet, setMeet] = useState('');

    //step 5
    const [visualCondition, setVisualCondition] = useState('');
    const [cidCode, setCidCode] = useState('');
    const [report, setReport] = useState('');
    const [privacy, setPrivacy] = useState(false);
    const [terms, setTerms] = useState(false);

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const { registerNewUser, Logout } = useContext(AuthContext)
    const history = useHistory()

    async function postPersonalDatas() {
        setLoading(true)
        const finalEmail = email === "" ? "email"+Math.random().toFixed(10)+"@dorinateca.com" : email

        const userAccess = {
            username: foreigner ? finalEmail : cpf.replace(NUMBER_ONLY, ""),
            email: finalEmail,
            password: password
        };

        const userForm = {
            name,
            lastName: surname,
            cpf,
            socialName,
            birthDate: moment(birth).format(),
            minor: minor,
            genre: gender,
            maritalStatus: marital,
            schooling: schooling,
            nationality,
            address: {
                address: address,
                number,
                city,
                uf: state,
                complement,
                district,
                zipcode: cep
            },
            mainPhone: primaryPhone.replace(NUMBER_ONLY, ""),
            phone: secondaryPhone.replace(NUMBER_ONLY, ""),
            profession: profession,
            occupation: occupation,
            fluencyBraille: fluencyInBraille,
            brailleOptionsScreenReader: screenReader,
            brailleOptionsLineBraille: brailleLine,
            brailleOptionsScreenMagnifier: screenMagnifier,
            brailleOptionsElectronicMagnifyingGlass: eletronicGlass,
            howAboutDorinateca: meet,
            altLibraries: which,
            literaryEvents: literaryEvents ? 'sim' : 'não',
            additionlInformation: {
                visualCondition: visualCondition,
                visualConditionCode: cidCode
            },
            blocked: true,
            confirmed: false,
            foreigner: foreigner,
            foreignerDocument: foreignerDocument,
            country: country
        };

        const handleSuccess = () => {
            setModalError(true)
            setModalErrorMessage("Pedido de cadastro criado! Aguarde o nosso contato por e-mail ou telefone informando da aprovação do cadastro")
        };

        await registerNewUser(userAccess, userForm, report, foreignerDocumentFile, foreignerProofOfddress, handleSuccess)
        setLoading(false)
    }

    const closeModalShowLastRequiredField = () => {
        setModalError(false)
        Logout()
        history.push("/")
    }
    const [modalUseTermsMessage, setModalUseTermsMessage] = useState('')
    const [modalPrivacyPoliceMessage, setModalPrivacyPoliceMessage] = useState('') 
    useEffect(()=>{
        api.get(`/privacy-policy`).then(res => {
            setModalPrivacyPoliceMessage(res.data.text)
        })
    }, [])

    useEffect(()=>{
        api.get(`/terms-use`).then(res => {
            setModalUseTermsMessage(res.data.text)
        })
    }, [])

    return (
        <>
            <Header />
            <GoBack title="Cadastro pessoa física" aria-label="Os campos obrigatórios estão marcados com um *" />
            <ModalDefault type="error" showCloseButton title="Sucesso" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p aria-label={modalErrorMessage}>{modalErrorMessage}</p>
            </ModalDefault>
            <Container>
                {step === 1 &&
                    <PersonalData step={step} changeStep={(v) => setStep(v)}
                        name={name}
                        setName={(v) => setName(v)}
                        surname={surname}
                        setSurname={(v) => setSurname(v)}
                        socialName={socialName}
                        setSocialName={(v) => setSocialName(v)}
                        gender={gender}
                        setGender={(v) => setGender(v)}
                        birth={birth}
                        setBirth={setBirth}
                        minor={minor}
                        setMinor={(v) => setMinor(v)}
                        marital={marital}
                        setMarital={(v) => setMarital(v)}
                        nationality={nationality}
                        setNationality={(v) => setNationality(v)}
                    />
                }
                {step === 2 &&
                    <Password step={step} changeStep={(v) => setStep(v)}
                        cpf={cpf}
                        setCpf={setCpf}
                        email={email}
                        setEmail={(v) => setEmail(v)}
                        email2={email2}
                        setEmail2={(v) => setEmail2(v)}
                        password={password}
                        setPassword={(v) => setPassword(v)}
                        password2={password2}
                        setPassword2={(v) => setPassword2(v)}
                        foreigner={foreigner}
                        setForeigner={setForeigner}
                        foreignerDocument={foreignerDocument}
                        setForeignerDocument={setForeignerDocument}
                        foreignerDocumentFile={foreignerDocumentFile}
                        setForeignerDocumentFile={v => setForeignerDocumentFile(v)}
                    />
                }
                {step === 3 &&
                    <Address step={step} changeStep={(v) => setStep(v)}
                        cep={cep}
                        setCep={(v) => setCep(v)}
                        address={address}
                        setAddress={(v) => setAddress(v)}
                        number={number}
                        setNumber={(v) => setNumber(v)}
                        complement={complement}
                        setComplement={(v) => setComplement(v)}
                        city={city}
                        setCity={(v) => setCity(v)}
                        state={state}
                        setState={(v) => setState(v)}
                        primaryPhone={primaryPhone}
                        setPrimaryPhone={(v) => setPrimaryPhone(v)}
                        secondaryPhone={secondaryPhone}
                        setSecondaryPhone={(v) => setSecondaryPhone(v)}
                        district={district}
                        setDistrict={setDistrict}
                        foreigner={foreigner}
                        country={country}
                        setCountry={setCountry}
                        foreignerProofOfddress={foreignerProofOfddress}
                        setForeignerProofOfddress={setForeignerProofOfddress}
                    />
                }
                {step === 4 &&
                    <MoreDatas step={step} changeStep={(v) => setStep(v)}
                        schooling={schooling}
                        setSchooling={(v) => setSchooling(v)}
                        profession={profession}
                        setProfession={(v) => setProfession(v)}
                        occupation={occupation}
                        setOccupation={(v) => setOccupation(v)}
                        fluencyInBraille={fluencyInBraille}
                        setFluencyInBraille={(v) => setFluencyInBraille(v)}
                        screenReader={screenReader}
                        setScreenReader={(v) => setScreenReader(v)}
                        screenMagnifier={screenMagnifier}
                        setScreenMagnifier={(v) => setScreenMagnifier(v)}
                        brailleLine={brailleLine}
                        setBrailleLine={(v) => setBrailleLine(v)}
                        eletronicGlass={eletronicGlass}
                        setEletronicGlass={(v) => setEletronicGlass(v)}
                        readingFluency={readingFluency}
                        setReadingFluency={(v) => setReadingFluency(v)}
                        searchingFor={searchingFor}
                        setSearchingFor={(v) => setSearchingFor(v)}
                        goToLibraries={goToLibraries}
                        setGoToLibraries={(v) => setGoToLibraries(v)}
                        which={which}
                        setWhich={(v) => setWhich(v)}
                        literaryEvents={literaryEvents}
                        setLiteraryEvents={(v) => setLiteraryEvents(v)}
                        equipament={equipament}
                        setEquipament={(v) => setEquipament(v)}
                        fdnc={fdnc}
                        setFdnc={(v) => setFdnc(v)}
                        meet={meet}
                        setMeet={(v) => setMeet(v)}
                    />
                }
                {step === 5 &&
                    <Visual
                        step={step}
                        changeStep={(v) => setStep(v)}
                        submit={postPersonalDatas}
                        visualCondition={visualCondition}
                        setVisualCondition={setVisualCondition}
                        cidCode={cidCode}
                        setCidCode={setCidCode}
                        report={report}
                        setReport={setReport}
                        privacy={privacy}
                        setPrivacy={setPrivacy}
                        terms={terms}
                        setTerms={setTerms}
                        modalUseTermsMessage={modalUseTermsMessage}
                        modalPrivacyPoliceMessage={modalPrivacyPoliceMessage}
                        loading={loading}
                        foreigner={foreigner}
                    />
                }
            </Container>
            <Footer />
        </>
    )
}