import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { Container, Controllers, Empty, Genero, Line } from "./styles";
import { ModalDefault } from "../../../components/ModalDefault";

import moment from "moment";
import { useRef, useState } from "react";
export function PersonalData(props) {
    const { name, surname, changeStep, gender, birth, nationality, marital, save } = props

    const isFormValid = [name, surname, gender, birth, nationality, marital].filter((item) => !item).length === 0

    const [targetField, setTargetField] = useState('')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const [modalInvalidfield, setModalInvalidfield] = useState(false);
    const [modalInvalidfieldMessage, setModalInvalidfieldMessage] = useState('')

    const nameRef = useRef()
    const surnameRef = useRef()
    const genderRef = useRef()
    const birthRef = useRef()
    const maritalRef = useRef()
    const nationalityRef = useRef()

    function validateBirth (e) {
        let dateNowTS = new Date().getTime();
        let dateToValidateTS = new Date(e.target.value).getTime()

        if(isNaN(dateToValidateTS) && e.target.value !== ''){
            props.setBirth(null)
            setModalInvalidfield(true)
            setModalInvalidfieldMessage('Data inserida é inválida')
        }
        if(dateToValidateTS > dateNowTS && e.target.value !== ''){
            props.setBirth(null)
            setModalInvalidfield(true)
            setModalInvalidfieldMessage('Data inserida não pode ser maior que a data de hoje')
        }
        if (!e.target.value) { e.target.classList.add('error'); } else { e.target.classList.remove('error');}
    }

    const remainingFields = [
        {
            value: name,
            label: 'name',
            message: 'O campo nome é obrigatório'
        },
        {
            value: surname,
            label: 'surname',
            message: 'O campo sobrenome é obrigatório'
        },
        {
            value: gender,
            label: 'gender',
            message: 'O campo gênero é obrigatório'
        },
        {
            value: birth,
            label: 'birth',
            message: 'O campo data de nascimento é obrigatório'
        },
        {
            value: marital,
            label: 'marital',
            message: 'O campo status civil é obrigatório'
        },
        {
            value: nationality,
            label: 'nationality',
            message: 'O campo nacionalidade é obrigatório'
        }
    ].filter(({ value }) => !value)
    const focusOnLastRequiredField = () => {
        const getFirstInvalidField = remainingFields[0]
        setTargetField(' ')
        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
    }

    const closeModalShowLastRequiredField = () => {
        const getFirstInvalidField = remainingFields[0]

        setModalError(false)
        setTargetField(' ')
        switch(remainingFields[0].label) {
            case 'name':
                nameRef.current?.focus();
                break;

            case 'surname':
                surnameRef.current?.focus();
                break;

            case 'gender':
                genderRef.current?.focus();
                break;

            case 'birth':
                birthRef.current?.focus();
                break;

            case 'marital':
                maritalRef.current?.focus();
                break;

            case 'nationality':
                nationalityRef.current?.focus();
                break;
        }

        setTargetField(remainingFields[0].label)
        if (getFirstInvalidField.label === 'gender') {
            const timedOutSetField = setTimeout(() =>
                genderRef.current?.focus()
            , 148)
            return () => clearTimeout(timedOutSetField)
        }

        const timedOutSetField = setTimeout(() =>
            setTargetField(getFirstInvalidField.label)
            , 148)
        return () => clearTimeout(timedOutSetField)
    }

    return (
        <>
            <Wizard step={1} navigate={true} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Preencha o campo" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalInvalidfield} onRequestClose={() => {
                    setModalInvalidfield(false)
                    //setado o foco direto para o campo de nascimento pois é o único que possui validação de formato
                    birthRef.current?.focus()
                }}
            >
                    <p>{modalInvalidfieldMessage}</p>
            </ModalDefault>
            <Container>
                <Line>
                    <Input autofocus={targetField === 'name'} value={props.name} onChange={(v) => props.setName(v)} label="Nome" required={true} type="text" placeholder="Insira seu nome" />
                    <Input autofocus={targetField === 'surname'} value={props.surname} onChange={(v) => props.setSurname(v)} required={true} label="Sobrenome" type="text" placeholder="Insira seu sobrenome" />
                </Line>
                <Line className="only">
                    <Input value={props.socialName} onChange={(v) => props.setSocialName(v)} label="Nome Social" tyle="text" placeholder="Insira o nome social" />
                    <Empty></Empty>
                </Line>
                <Line>
                    <Genero>
                        <div>
                            <label htmlFor={'gender'} _ref={genderRef} tabIndex={1} aria-label="Gênero">Gênero <span className="red">*</span></label>
                        </div>
                        <div>
                            <div>
                                <input tabIndex={0}
                                    aria-label="homem"
                                    checked={props.gender === 'male'}
                                    type="radio" name="genmale" id="man" value="male" onChange={e => props.setGender(e.target.value)} />
                                <label htmlFor="man">Homem</label>
                            </div>
                            <div>
                                <input tabIndex={0}
                                    checked={props.gender === 'female'}
                                    aria-label="mulher" type="radio" name="genfemale" id="mu" value="female" onChange={e => props.setGender(e.target.value)} />
                                <label htmlFor="mu">Mulher</label>
                            </div>
                            <div>
                                <input tabIndex={0}
                                    aria-label="outro" type="radio" name="genother"
                                    checked={props.gender === 'other'}
                                    id="none" value="other"
                                    onChange={e => props.setGender(e.target.value)} />
                                <label htmlFor="none">Prefiro não indicar</label>
                            </div>
                        </div>
                    </Genero>
                </Line>
                <Line>
                    <Input 
                        autofocus={targetField === 'birth'} 
                        value={props.birth} 
                        onChange={(v) => props.setBirth(v)} 
                        required={true} 
                        type="date"
                        onBlur={(e)=> validateBirth(e)}
                        label="Nascimento">
                    </Input>
                    <div className="check">
                        <input type="checkbox"
                            disabled={!(Math.abs(moment(props.birth).diff(moment(), 'years')) < 18)}
                            
                            tabIndex={0}
                            aria-label="Sou menor de idade"
                            checked={props.minor} onChange={(e) => props.setMinor(e.currentTarget.checked)}></input>
                        <label htmlFor="">Sou menor de idade</label>
                    </div>
                    <Empty></Empty>
                    <Empty></Empty>
                </Line>
                <Line>
                    <Input label="Estado Civíl" autofocus={targetField === 'marital'} type="select" required={true} value={props.marital} onChange={(v) => props.setMarital(v)}>
                        <option name="solteiro" value="single">Solteiro</option>
                        <option name="Casado" value="married">Casado</option>
                        <option name="Divorciado" value="divorced">Divorciado</option>
                        <option name="Viuvo" value="widower">Viúvo</option>
                    </Input>
                    <Input value={props.nationaliry}
                        autofocus={targetField === 'nationaliry'}
                        onChange={(v) => props.setNationality(v)}
                        label="Nacionalidade" type="text"
                        placeholder="Informe sua nacionalidade"
                        required={true}></Input>
                </Line>
            </Container>
            <Controllers>
                <button onClick={props.goBack}>Cancelar</button>
                <button style={{
                    opacity: isFormValid ? 1 : .5
                }} onClick={() => isFormValid ? save() : focusOnLastRequiredField()}>Salvar</button>
            </Controllers>
        </>
    )
}