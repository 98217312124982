import styled from 'styled-components';

export const Container = styled.section`
  width: var(--width-m);
  margin: 50px auto 100px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 720px) {
    flex-direction: column-reverse;
    width: auto;
    margin: 50px 0;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    flex-direction: column-reverse;
    width: auto;
    margin: 50px 0;
  }
`;

export const ColorPreviewBox = styled.span`
  width: 20px;
  height: 20px;
  padding-right: 4px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.bgColor};
  margin-right: 8px;
`;

export const InvisibleLabel = styled.span`
  position: absolute;
  opacity: 0;
`;

export const Main = styled.main`
  flex: 7;
  min-height: 50vh;
  > div.tabs {
    width: 100%;
    border-bottom: 1px solid transparent;
    button {
      font-size: ${(props) => props.theme.fontSize + 14}px;
      font-weight: 400;
      border: none;
      background-color: transparent;
      border-bottom: 3px solid ${(props) => props.theme.bodyBackground};
      padding: 10px 25px;

      &.active {
        font-weight: 700;
        border-bottom-color: ${(props) => props.theme.textColor};
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    &.active {
      display: block;
    }

    thead {
      width: 100%;
      > tr.colors {
        > td {
          border-bottom-color: ${(props) => props.theme.textColor}26;
        }
      }
      td {
        font-size: ${(props) => props.theme.fontSize + 14}px;
        font-weight: 600;
        padding: 15px 50px 15px 0px;
        border-bottom: 1px solid ${(props) => props.theme.textColor}66;
        button {
          background-color: transparent;
          border: none;
          margin-left: 12px;
        }
      }
    }
    tbody {
      width: 100%;
      > tr {
        &.red {
          > td:first-child {
            border-left: 5px solid var(--tred);
            border-radius: 3px 0px 0px 3px;
          }
        }
        &.yellow {
          > td:first-child {
            border-left: 5px solid var(--tyellow);
            border-radius: 3px 0px 0px 3px;
          }
        }
        &.green {
          > td:first-child {
            border-left: 5px solid var(--tgreen);
            border-radius: 3px 0px 0px 3px;
          }
        }

        &.blue {
          > td:first-child {
            border-left: 5px solid #00aaff;
            border-radius: 3px 0px 0px 3px;
          }
        }

        > td {
          padding: 20px 50px 20px 0px;
          border-bottom: 1px solid ${(props) => props.theme.textColor}26;

          &:nth-child(2) {
            font-weight: 700;
          }
        }

        &:nth-child(2n + 0) {
          background-color: ${(props) => props.theme.textColor}11;
        }
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: fit-content;
    table {
      overflow-x: scroll;
      width: 100vw;
      margin: 0 8px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    overflow-x: scroll;
    width: fit-content;
    table {
      width: 100vw;
      margin: 0 8px;
    }
  }
`;

export const AvatarImage = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 70px;
  background-color: ${(props) => props.theme.textColor}26;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

export const Aside = styled.aside`
  position: relative;
  min-width: 300px;
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    padding: 12px;
    box-shadow: var(--shadow-light);
    border-radius: 16px;
    background-color: ${(props) => props.theme.backgroundColor};
    position: absolute;
    gap: 15px;
    > div {
      position: relative;
      width: 140px;
      > div {
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 50%;
        img {
          height: 100%;
        }
      }
      > button {
        position: absolute;
        background-color: ${(props) => props.theme.bodyBackground};
        border: none;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        color: ${(props) => props.theme.textColor};
        box-shadow: 1px 1px 4px ${(props) => props.theme.textColor}26;
        bottom: 5px;
        right: 5px;
      }
    }
    p {
      font-weight: 700;
      color: ${(props) => props.theme.textColor}aa;
      font-size: ${(props) => props.theme.fontSize + 22}px;
    }
    span {
      font-weight: 400;
      font-size: ${(props) => props.theme.fontSize + 16}px;
    }
    > button {
      color: var(--white);
      font-size: ${(props) => props.theme.fontSize + 12}px;
      font-weight: 400;

      background-color: var(--pink);
      padding: 7px;
      border: none;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  @media screen and (max-width: 720px) {
    margin: 0 auto;
    section {
      position: unset;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin: 0 auto;
    section {
      position: unset;
    }
  }
`;
