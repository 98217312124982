/* eslint-disable default-case */
import { useRef, useState } from "react";
import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { Container, Line, Empty, Genero, Controllers } from "./styles";
import { ModalDefault } from "../../../components/ModalDefault";
import moment from "moment";

export function PersonalData(props) {
    const { name, surname, gender, birth, nationality, marital } = props

    const [targetField, setTargetField] = useState('')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const [modalInvalidfield, setModalInvalidfield] = useState(false);
    const [modalInvalidfieldMessage, setModalInvalidfieldMessage] = useState('')
    
    const nameRef = useRef()
    const surnameRef = useRef()
    const genderRef = useRef()
    const birthRef = useRef()
    const maritalRef = useRef()
    const nationalityRef = useRef()

    const remainingFields = [
        {
            value: name,
            label: 'name',
            message: 'O campo nome é obrigatório'
        },
        {
            value: surname,
            label: 'surname',
            message: 'O campo sobrenome é obrigatório'
        },
        {
            value: gender,
            label: 'gender',
            message: 'O campo gênero é obrigatório'
        },
        {
            value: birth,
            label: 'birth',
            message: 'O campo data de nascimento é obrigatório'
        },
        {
            value: marital,
            label: 'marital',
            message: 'O campo status civil é obrigatório'
        },
        {
            value: nationality,
            label: 'nationality',
            message: 'O campo nacionalidade é obrigatório'
        }
    ].filter(({ value }) => !value)

    const isFormValid = remainingFields.length === 0;

    const focusOnLastRequiredField = () => {
        const getFirstInvalidField = remainingFields[0]
        setTargetField(' ')
        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
    }

    const closeModalShowLastRequiredField = () => {
        const getFirstInvalidField = remainingFields[0]

        setModalError(false)
        setTargetField(' ')
        switch(remainingFields[0].label) {
            case 'name':
                nameRef.current?.focus();
                break;

            case 'surname':
                surnameRef.current?.focus();
                break;

            case 'gender':
                genderRef.current?.focus();
                break;

            case 'birth':
                birthRef.current?.focus();
                break;

            case 'marital':
                maritalRef.current?.focus();
                break;

            case 'nationality':
                nationalityRef.current?.focus();
                break;
        }

        setTargetField(remainingFields[0].label)
        if (getFirstInvalidField.label === 'gender') {
            const timedOutSetField = setTimeout(() =>
                genderRef.current?.focus()
            , 148)
            return () => clearTimeout(timedOutSetField)
        }

        const timedOutSetField = setTimeout(() =>
            setTargetField(getFirstInvalidField.label)
            , 148)
        return () => clearTimeout(timedOutSetField)
    }

    function validateBirth (e) {
        let dateNowTS = new Date().getTime();
        let dateToValidateTS = new Date(e.target.value).getTime()

        if(isNaN(dateToValidateTS) && e.target.value !== ''){
            props.setBirth(null)
            setModalInvalidfield(true)
            setModalInvalidfieldMessage('Data inserida é inválida')
        }
        if(dateToValidateTS > dateNowTS && e.target.value !== ''){
            props.setBirth(null)
            setModalInvalidfield(true)
            setModalInvalidfieldMessage('Data inserida não pode ser maior que a data de hoje')
        }
        if (!e.target.value) { e.target.classList.add('error'); } else { e.target.classList.remove('error');}
    }

    return (
        <>
            <Wizard step={1} />
            <ModalDefault type="error" showCloseButton title="Preencha o campo" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalInvalidfield} onRequestClose={() => {
                    setModalInvalidfield(false)
                    //setado o foco direto para o campo de nascimento pois é o único que possui validação de formato
                    birthRef.current?.focus()
                }}
            >
                    <p>{modalInvalidfieldMessage}</p>
            </ModalDefault>
            <Container tabIndex={1}>
                <Line>
                    <Input
                        autoFocus={targetField === 'name'}
                        targetName={'name'}
                        value={props.name}
                        onChange={(v) => props.setName(v)}
                        onBlur={(e) => { if (!e.target.value) { e.target.classList.add('error'); } else { e.target.classList.remove('error'); }}}
                        label="Nome"
                        _ref={nameRef}
                        type="text"
                        placeholder="Insira seu nome"
                        required />
                    <Input 
                        autoFocus={targetField === 'surname'}
                        targetName={'surname'}
                        value={props.surname}
                        onChange={(v) => props.setSurname(v)}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        label="Sobrenome"
                        _ref={surnameRef}
                        type="text"
                        placeholder="Insira seu sobrenome"
                        required />
                </Line>
                <Line className="only">
                    <Input
                        targetName={'socialName'}
                        value={props.socialName}
                        onChange={(v) => props.setSocialName(v)}
                        label="Nome Social"
                        type="text"
                        placeholder="Insira o nome social"
                        />
                    <Empty></Empty>
                </Line>
                <Line>
                    <Genero>
                        <div>
                            <label htmlFor={'gender'} _ref={genderRef} tabIndex={1} aria-label="Gênero">Gênero <span className="red">*</span></label>
                        </div>
                        <div id="gender">
                            <div>
                                <input tabIndex={1}
                                    aria-label="homem"
                                    checked={props.gender === 'male'}
                                    type="radio" name="genmale" id="man" value="male" onChange={e => props.setGender(e.target.value)} />
                                <label className="error" htmlFor="man">Homem</label>
                            </div>
                            <div>
                                <input tabIndex={1}
                                    checked={props.gender === 'female'}
                                    aria-label="mulher" type="radio" name="genfemale" id="woman" value="female" onChange={e => props.setGender(e.target.value)} />
                                <label htmlFor="woman">Mulher</label>
                            </div>
                            <div>
                                <input tabIndex={1}
                                    aria-label="Prefiro não indicar" type="radio" name="genother"
                                    checked={props.gender === 'other'}
                                    id="none" value="other"
                                    onChange={e => props.setGender(e.target.value)} />
                                <label className="error" htmlFor="none">Prefiro não indicar</label>
                            </div>
                        </div>
                    </Genero>
                </Line>
                <Line>
                    <Input
                        autoFocus={targetField === 'birth'}
                        targetName={'birth'}
                        value={props.birth}
                        onChange={(v) => props.setBirth(v)}
                        onBlur={(e)=> validateBirth(e)}
                        type="date"
                        label="Nascimento"
                        _ref={birthRef}
                        required></Input>
                    <div className="check">
                        <input type="checkbox"
                            disabled={!(Math.abs(moment(birth).diff(moment(), 'years')) < 18)}
                            id="minority"
                            tabIndex={1}
                            aria-label="Sou menor de idade"
                            style={{opacity: 0.5}}
                            checked={(Math.abs(moment(birth).diff(moment(), 'years')) < 18)} onChange={(e) => props.setMinor((Math.abs(moment(birth).diff(moment(), 'years')) < 18))}></input>
                        <label htmlFor="minority">Sou menor de idade</label>
                    </div>
                    <Empty></Empty>
                    <Empty></Empty>
                </Line>
                <Line>
                    <Input
                        targetName={'marital'}
                        label="Estado Civíl"
                        autoFocus={targetField === 'marital'}
                        type="select"
                        value={props.marital}
                        _ref={maritalRef}
                        onChange={(v) => props.setMarital(v)}
                        required>
                        <option name="solteiro" value="single">Solteiro</option>
                        <option name="Casado" value="married">Casado</option>
                        <option name="Divorciado" value="divorced">Divorciado</option>
                        <option name="Viuvo" value="widower">Viúvo</option>
                    </Input>
                    <Input 
                        autoFocus={targetField === 'nationality'}
                        targetName={'nationality'}
                        onChange={(v) => props.setNationality(v)}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        label="Nacionalidade" type="text"
                        value={props.nationality}
                        _ref={nationalityRef}
                        placeholder="Informe sua nacionalidade" required></Input>
                </Line>
            </Container>
            <Controllers>
                <button tabIndex={1}>Cancelar</button>
                <button style={{
                    opacity: isFormValid ? 1 : .5
                }} tabIndex={1} onClick={() => isFormValid ? props.changeStep(2) : focusOnLastRequiredField()}>Avançar</button>
            </Controllers>
        </>
    )
}