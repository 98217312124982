import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Address } from "./Address";
import { MoreDatas } from "./MoreDatas";
import { Password } from "./Password";
import { PersonalData } from "./PersonalData";
import { Container } from "./styles";
import { useState, useContext } from 'react';
import moment from "moment";
import AuthContext from "../../contexts/auth";
import { useHistory } from "react-router-dom";
import { NUMBER_ONLY } from "../../data/regex";
import { ModalDefault } from "../../components/ModalDefault";


export function EditUser() {
    const { user } = useContext(AuthContext)

    //step 1
    const [step, setStep] = useState(1);
    const birthDateformated = moment(user.birthDate).format().split("T")[0]
    const [name, setName] = useState(user.name ?? "");
    const [surname, setSurname] = useState(user.lastName ?? "");
    const [socialName, setSocialName] = useState(user.socialName ?? "");
    const [gender, setGender] = useState(user.genre ?? "");
    const [birth, setBirth] = useState(user.birthDate ? birthDateformated : "");
    const [minor, setMinor] = useState(user.minor ?? false);
    const [marital, setMarital] = useState(user.maritalStatus ?? "");
    const [nationality, setNationality] = useState(user.nationality ?? "");

    //step 2
    const [cpf, setCpf] = useState(user.cpf ?? "");
    const [email, setEmail] = useState(user.email ?? "");
    const [email2, setEmail2] = useState(user.email ?? "");
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [foreigner, setForeigner] = useState(user?.foreigner ?? false);
    const [foreignerDocument, setForeignerDocument] = useState(user?.foreignerDocument ?? "");

    //step 3
    const [cep, setCep] = useState(user.address?.zipcode ?? "");
    const [address, setAddress] = useState(user.address?.zipcode ?? "");
    const [number, setNumber] = useState(user.address?.number ?? "");
    const [complement, setComplement] = useState(user.address?.complement ?? "");
    const [city, setCity] = useState(user.address?.city ?? "");
    const [state, setState] = useState(user.address?.uf ?? "");
    const [primaryPhone, setPrimaryPhone] = useState(user.mainPhone ?? "");
    const [secondaryPhone, setSecondaryPhone] = useState(user.phone ?? "");
    const [country, setCountry] = useState(user.country ?? "");

    //step 4
    const [schooling, setSchooling] = useState(user.schooling ?? "");
    const [profession, setProfession] = useState(user.profession ?? "");
    const [occupation, setOccupation] = useState(user.occupation ?? "");
    const [fluencyInBraille, setFluencyInBraille] = useState(user.fluencyBraille ?? "");
    const [screenReader, setScreenReader] = useState(user.brailleOptionsScreenReader ?? false);

    const [screenMagnifier, setScreenMagnifier] = useState(user.brailleOptionsScreenMagnifier ?? false);
    const [brailleLine, setBrailleLine] = useState(user.brailleOptionsLineBraille ?? false);
    const [eletronicGlass, setEletronicGlass] = useState(user.brailleOptionsElectronicMagnifyingGlass ?? false);

    const [readingFluency, setReadingFluency] = useState(user.fluencyBraille ?? "")

    const [searchingFor, setSearchingFor] = useState('');
    const [goToLibraries, setGoToLibraries] = useState(false);
    const [which, setWhich] = useState(user.altLibraries) ?? "";
    const [literaryEvents, setLiteraryEvents] = useState(user.literaryEvents === 'sim' ?? "");
    const [equipament, setEquipament] = useState('');
    const [fdnc, setFdnc] = useState('');
    const [meet, setMeet] = useState(user.howAboutDorinateca ?? "");

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const { handleUserUpdate } = useContext(AuthContext)
    const history = useHistory()

    function postPersonalDatas() {
        const userForm = {
            name,
            "lastName": surname,
            cpf,
            socialName,
            birthDate: moment(birth).format(),
            minor: Math.abs(moment(birth).diff(moment(), 'years')) < 18,
            genre: gender,
            maritalStatus: marital,
            schooling: schooling,
            nationality,
            address: {
                address: address,
                number,
                city,
                uf: state,
                complement,
                zipcode: cep
            },
            "mainPhone": primaryPhone.replace(NUMBER_ONLY, ""),
            "phone": secondaryPhone.replace(NUMBER_ONLY, ""),
            "profession": profession,
            "occupation": occupation,
            "fluencyBraille": fluencyInBraille,
            "brailleOptionsScreenReader": screenReader,
            "brailleOptionsLineBraille": brailleLine,
            "brailleOptionsScreenMagnifier": screenMagnifier,
            "brailleOptionsElectronicMagnifyingGlass": eletronicGlass,
            "howAboutDorinateca": meet,
            altLibraries: which,
            literaryEvents: literaryEvents ? 'sim' : 'não',
            foreigner: foreigner,
            foreignerDocument: foreigner ? foreignerDocument : "",
            country: country
        }

        if (cpf) {
            userForm['username'] = cpf.replace(NUMBER_ONLY, "");
        }
        const handleSuccess = () => {
            setModalError(true)
            setModalErrorMessage("Informações atualizadas com sucesso!")
        }
        handleUserUpdate(userForm, handleSuccess)
    }
    const closeModalShowLastRequiredField = () => {
        history.push("/")
    }

    return (
        <>
            <ModalDefault type="error" showCloseButton title="Sucesso" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Header />
            <GoBack title="Editar cadastro" />
            <Container>
                {step === 1 &&
                    <PersonalData
                        step={step}
                        changeStep={(v) => setStep(v)}
                        save={postPersonalDatas}
                        name={name}
                        setName={(v) => setName(v)}
                        surname={surname}
                        setSurname={(v) => setSurname(v)}
                        socialName={socialName}
                        setSocialName={(v) => setSocialName(v)}
                        gender={gender}
                        setGender={(v) => setGender(v)}
                        birth={birth}
                        setBirth={(v) => setBirth(v)}
                        minor={minor}
                        setMinor={(v) => setMinor(v)}
                        marital={marital}
                        setMarital={(v) => setMarital(v)}
                        nationality={nationality}
                        setNationality={(v) => setNationality(v)}
                        goBack={() => history.push('/minha-dorinateca')}
                    />
                }
                {step === 2 &&
                    <Password step={step} changeStep={(v) => setStep(v)}
                        save={postPersonalDatas}
                        cpf={cpf}
                        setCpf={(v) => setCpf(v)}
                        email={email}
                        setEmail={(v) => setEmail(v)}
                        email2={email2}
                        setEmail2={(v) => setEmail2(v)}
                        password={password}
                        setPassword={(v) => setPassword(v)}
                        password2={password2}
                        setPassword2={(v) => setPassword2(v)}
                        foreigner={foreigner}
                        setForeigner={setForeigner}
                        foreignerDocument={foreignerDocument}
                        setForeignerDocument={setForeignerDocument}
                        goBack={() => history.push('/minha-dorinateca')}
                    />
                }
                {step === 3 &&
                    <Address step={step} changeStep={(v) => setStep(v)}
                        save={postPersonalDatas}
                        cep={cep}
                        setCep={(v) => setCep(v)}
                        address={address}
                        setAddress={(v) => setAddress(v)}
                        number={number}
                        setNumber={(v) => setNumber(v)}
                        complement={complement}
                        setComplement={(v) => setComplement(v)}
                        city={city}
                        setCity={(v) => setCity(v)}
                        state={state}
                        setState={(v) => setState(v)}
                        primaryPhone={primaryPhone}
                        setPrimaryPhone={(v) => setPrimaryPhone(v)}
                        secondaryPhone={secondaryPhone}
                        setSecondaryPhone={(v) => setSecondaryPhone(v)}
                        country={country}
                        foreigner={foreigner}
                        setCountry={(v) => setCountry(v)}                       
                        goBack={() => history.push('/minha-dorinateca')}
                    />
                }
                {step === 4 &&
                    <MoreDatas step={step} changeStep={(v) => setStep(v)}
                        save={postPersonalDatas}
                        schooling={schooling}
                        setSchooling={(v) => setSchooling(v)}
                        profession={profession}
                        setProfession={(v) => setProfession(v)}
                        occupation={occupation}
                        setOccupation={(v) => setOccupation(v)}
                        fluencyInBraille={fluencyInBraille}
                        setFluencyInBraille={(v) => setFluencyInBraille(v)}
                        screenReader={screenReader}
                        setScreenReader={(v) => setScreenReader(v)}
                        screenMagnifier={screenMagnifier}
                        setScreenMagnifier={(v) => setScreenMagnifier(v)}
                        brailleLine={brailleLine}
                        setBrailleLine={(v) => setBrailleLine(v)}
                        eletronicGlass={eletronicGlass}
                        setEletronicGlass={(v) => setEletronicGlass(v)}
                        readingFluency={readingFluency}
                        setReadingFluency={(v) => setReadingFluency(v)}
                        searchingFor={searchingFor}
                        setSearchingFor={(v) => setSearchingFor(v)}
                        goToLibraries={goToLibraries}
                        setGoToLibraries={(v) => setGoToLibraries(v)}
                        which={which}
                        setWhich={(v) => setWhich(v)}
                        literaryEvents={literaryEvents}
                        setLiteraryEvents={(v) => setLiteraryEvents(v)}
                        equipament={equipament}
                        setEquipament={(v) => setEquipament(v)}
                        fdnc={fdnc}
                        setFdnc={(v) => setFdnc(v)}
                        meet={meet}
                        setMeet={(v) => setMeet(v)}                        
                        goBack={() => history.push('/minha-dorinateca')}
                    />
                }
                {/* {step === 5 &&
                    <Visual
                        step={step}
                        changeStep={(v) => setStep(v)}
                        save={postPersonalDatas}
                        submit={postPersonalDatas}
                        visualCondition={visualCondition}
                        setVisualCondition={setVisualCondition}
                        cidCode={cidCode}
                        setCidCode={setCidCode}
                        report={report}
                        setReport={setReport}
                        privacy={privacy}
                        setPrivacy={setPrivacy}
                        terms={terms}
                        setTerms={setTerms}
                    />
                } */}
            </Container>
            <Footer />
        </>
    )
}