import { useContext, useState } from 'react';
import { Footer } from '../../components/Footer';
import { GoBack } from '../../components/GoBack';
import { Header } from '../../components/Header';
import { SearchContainer } from '../../components/SearchContainer';
import AuthContext from '../../contexts/auth';
import { SearchBar } from './styles';
import useDebounce from '../../hooks/useDebounce';

export function Search() {
    const {injectSearch} = useContext(AuthContext)
    const [filterText, setFilterText] = useState(injectSearch)
    const [handleButton, setHandleButton] = useState(false);

    const handleClick = () => {
        setHandleButton(true);
    }
    const debouncedSearchTerm = useDebounce(filterText, 2000)

    return (
        <>
            <Header />
            <GoBack ariaLabel={"Voltar para página anterior"} title="Pesquisa" />
            <SearchBar>
                <input
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    type="text"
                    placeholder='Pesquise no acervo da Dorinateca'
                />
                <button onClick={handleClick}>Buscar</button>
            </SearchBar>
            <SearchContainer 
                handleButton={handleButton} 
                onButtonClicked={() => setHandleButton(false)} 
                filterText={debouncedSearchTerm} />
            <Footer />
        </>
    )
}