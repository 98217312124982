import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    // VARIAAVEIS
    :root{
        --bg-body: #FAF8F2;

        --yellow: #E9B00D;
        --pink: #DF4263;
        --blue: #001DFF;
        --red: #CF0F0F;
        --purple: #633F88;

        --tred: #FF0000;
        --tyellow: #FFE200;
        --tgreen: #A5FF95;

        --gradient1: linear-gradient(90deg, rgba(139,64,150,1) 0%, rgba(70,32,75,1) 100%);
        --gradient1-y: linear-gradient(180deg, rgba(139,64,150,1) 0%, rgba(70,32,75,1) 100%);

        --gradient2: linear-gradient(90deg, rgba(144,70,145,1) 0%, rgba(205,134,89,1) 100%);
        --gradient2-y: linear-gradient(180deg, rgba(144,70,145,1) 0%, rgba(205,134,89,1) 100%);

        --gradient3: linear-gradient(90deg, rgba(144,70,145,1) 50%, rgba(205,134,89,1) 100%);

        --white: #FFFFFF;
        --black: #000000;
        --gray: #333333;
        --gray-light: #efefef;

        --width-m: 1211px;
        --width-p: 900px;
        --width-pp: 600px;

        --shadow-modal: 1px 1px 5px 0px rgba(0,0,0,0.64);
        --shadow-modal-1: 1px 1px 5px 0px rgba(0,0,0,0.3);
        --shadow-light: 1px 1px 5px 0px rgba(0,0,0,0.15);
        --shadow-light-1: 0px 0px 5px 0px rgba(0,0,0,0.1);
    }
    @media screen and (max-width: 1220px) {
        html, body{
          overflow-x: hidden;
          width: 100vw;
        }
    }
textarea, input[type="date"]{
  font-family: 'Open Sans', sans-serif;
}

    //RESETS
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    a{
        text-decoration: none;
        color: ${(props) => props.theme.textColor};
    }
    body{
        background: ${(props) => props.theme.bodyBackground};
        -webkit-font-smoothing: antialiased;
        font-family: 'Source Sans Pro', sans-serif;
        color: var(--blue4);
        font-size: ${(props) => props.theme.fontSize + 16}px;
        height: 100vh;
    }
    
    button{
        cursor: pointer;
    }
    input{
        outline: none;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .btn-purple{
        display: flex;
        align-items: center;
        justify-content: center;
    font-size: ${(props) => props.theme.fontSize + 17}px;
    font-weight: 600;
            height: 50px;
            border-radius: 7px;
            border: none;
            background: var(--gradient1);
            color: var(--white);
            width: 100%;
            max-width: 350px;
            transition: filter 0.3s;
    &:hover{
        filter: brightness(1.2);
    }
}

button.btn-purple-outline{
    font-size: ${(props) => props.theme.fontSize + 17}px;
    font-weight: 600;
            height: 50px;
            border-radius: 7px;
            border: 1px solid var(--purple);
            background: var(--white);
            color: var(--purple);
            width: 100%;
            max-width: 350px;
            transition: filter 0.3s;
    &:hover{
        filter: brightness(1.2);
    }
}

textarea{
    resize: vertical;
}
.ds-none{
    display: none;
}

* {
    font-family: ${(props) => props.theme.fontFamily};
    letter-spacing: ${(props) => props.theme.letterSpacing}px;
    color: ${(props) => props.theme.textColor};
}

input {
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.backgroundColor} !important;
}

`;

// p
export const Par = styled.p`
  font-size: ${(props) =>
    props.fontSize === 3
      ? '28px'
      : props.fontSize === 2
      ? '22px'
      : '16px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) =>
      props.fontSize === 3
        ? '24px'
        : props.fontSize === 2
        ? '20px'
        : '16px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) =>
     props.fontSize === 3
        ? '26px'
        : props.fontSize === 2
        ? '20px'
        : '16px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;
// h1
export const Title1 = styled.h1`
  font-size: ${(props) => props.fontSize === 3 && '28px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) => props.fontSize === 3 && '24px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) => props.fontSize === 3 && '26px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;
// h2
export const Title2 = styled.h2`
  font-size: ${(props) => props.fontSize === 3 && '28px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) => props.fontSize === 3 && '24px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) => props.fontSize === 3 && '26px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;
// h3
export const Title3 = styled.h3`
  font-size: ${(props) => props.fontSize === 3 && '28px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) => props.fontSize === 3 && '24px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) => props.fontSize === 3 && '26px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;
// h4
export const Title4 = styled.h4`
  font-size: ${(props) => props.fontSize === 3 && '28px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) => props.fontSize === 3 && '24px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) => props.fontSize === 3 && '26px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;
// span
export const Spann = styled.span`
  font-size: ${(props) =>
    props.fontSize === 3
      ? '28px'
      : props.fontSize === 2
      ? '22px'
      : '16px'} !important;
  font-family: ${(props) =>
    props.fontFamily === 3
      ? 'OpenDyslexic'
      : props.fontFamily === 2
      ? 'Georgia'
      : 'Source Sans Pro'} !important;
  letter-spacing: ${(props) =>
    props.letterSpacing === 3
      ? '2px'
      : props.letterSpacing === 2
      ? '1px'
      : '0px'} !important;
  @media screen and (max-width: 720px) {
    font-size: ${(props) =>
      props.fontSize === 3
        ? '26px'
        : props.fontSize === 2
        ? '20px'
        : '16px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    font-size: ${(props) =>
      props.fontSize === 3
        ? '26px'
        : props.fontSize === 2
        ? '20px'
        : '16px'} !important;
    letter-spacing: ${(props) =>
      props.letterSpacing === 3
        ? '1px'
        : props.letterSpacing === 2
        ? '.5px'
        : '0px'} !important;
  }
`;

export const General = styled.div``;
