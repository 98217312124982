import InputMask from 'react-input-mask';
import countriesMask from "../../utils/countriesPhoneMask";
import {
    Container,
    ErrorMessage
} from "./styles";

export function Input(props) {
    const { autofocus, errorMessage } = props
    const className = props.className; 
    const label = props.label;
    const value = props.value;
    const type = props.type;
    const required = props.required;
    const placeholder = props.placeholder;
    const flex = `flex${props.fl}`;
    const ref = props._ref;

    const style = props.style ?? {}

    return (
        <>
            {type === undefined &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input disabled={props.disabled ? props.disabled : false} ref={ref} tabIndex={1} aria-label={props.ariaLabel} className={className} id={props.targetName} value={props.value} onChange={e => props.onChange(e.target.value)} onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} type="text" placeholder={placeholder} />
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === 'number' &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input disabled={props.disabled ? props.disabled : false} ref={ref} tabIndex={1} 
                    aria-label={props.ariaLabel} className={className} id={props.targetName} value={props.value} 
                    onChange={e => props.onChange(e.target.value)} onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} 
                    type="number" placeholder={placeholder}/>
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "text" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input disabled={props.disabled ? props.disabled : false} value={props.value} ref={ref} className={className} tabIndex={1} aria-label={props.ariaLabel} id={props.targetName} onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} onChange={e => props.onChange(e.target.value)} type="text" placeholder={placeholder} />
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "telephone" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <InputMask
                      mask={countriesMask[props.selectedCountry]}
                      value={props.value}
                      disabled={props.disabled ? props.disabled : false}
                      tabIndex={1} aria-label={props.ariaLabel} 
                      id={props.targetName} 
                      onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} 
                      onChange={e => props.onChange(e.target.value)} 
                      type="text" 
                      placeholder={placeholder}
                      className={className}
                    />
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "autocomplete" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input disabled={props.disabled ? props.disabled : false} id={props.id} value={props.value} ref={ref} className={className} tabIndex={1} aria-label={props.ariaLabel} id={props.targetName} onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} onChange={e => props.onChange(e.target.value)} type="text" placeholder={placeholder} />
                    {props.children}
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "password" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input disabled={props.disabled ? props.disabled : false} ref={ref} tabIndex={1} aria-label={props.ariaLabel} id={props.targetName} value={props.value} onChange={e => props.onChange(e.target.value)} onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} type="password" placeholder={placeholder} />
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "date" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={"Data de " + (required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label)}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <input ref={ref} 
                        tabIndex={1} 
                        aria-label={props.ariaLabel} 
                        id={props.targetName} 
                        value={props.value} 
                        onChange={e => props.onChange(e.target.value)} 
                        onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}} 
                        type="date" 
                        placeholder={placeholder} 
                        min="1900-01-01" max="2030-12-31"
                        disabled={props.disabled ? props.disabled : false}
                    />
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "select" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <select ref={ref}
                        tabIndex={1}
                        aria-label={props.ariaLabel}
                        id={props.targetName}
                        onChange={e => props.onChange(e.target.value)}
                        onBlur={props.onBlur !== undefined ? e => props.onBlur(e) : () => {}}
                        value={props.value}
                        disabled={props.disabled ? props.disabled : false}>
                        <option value="">Selecionar</option>
                        {props.children}
                    </select>
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
            {type === "textarea" &&
                <Container style={style} className={flex}>
                    <label htmlFor={props.targetName} tabIndex={1} aria-label={required ? required ? props.ariaLabel ? props.ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : props.ariaLabel ?? label : props.ariaLabel ?? label}>{label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
                    <textarea disabled={props.disabled ? props.disabled : false} tabIndex={1} aria-label={props.ariaLabel} id={props.targetName} value={props.value} onChange={e => props.onChange(e.target.value)} name="" cols="30" rows="10"></textarea>
                    <ErrorMessage>
                        {errorMessage ?? ""}
                    </ErrorMessage>
                </Container>
            }
        </>
    )
}