const countriesMask = {
  AC: '+999-9999',
  AD: '+999-999-999',
  AE: '+999-99-999-9999',
  AF: '+99-99-999-9999',
  AG: '+9(999)999-9999',
  AI: '+9(999)999-9999',
  AL: '+999(999)999-999',
  AM: '+999-99-999-999',
  AN: '+999-999-9999',
  AO: '+999(999)999-999',
  AQ: '+999-999-999',
  AR: '+99(999)999-9999',
  AS: '+9(999)999-9999',
  AT: '+9(999)999-9999',
  AU: '+99-9-9999-9999',
  AW: '+999-999-9999',
  AZ: '+999-99-999-99-99',
  BA: '+999-99-99999',
  BB: '+9(999)999-9999',
  BD: '+999-99-999-999',
  BE: '+99(999)999-999',
  BF: '+999-99-99-9999',
  BG: '+999(999)999-999',
  BH: '+999-9999-9999',
  BI: '+999-99-99-9999',
  BJ: '+999-99-99-9999',
  BM: '+9(999)999-9999',
  BN: '+999-999-9999',
  BO: '+999-9-999-9999',
  BR: '+99(99)99999-9999',
  BS: '+9(999)999-9999',
  BT: '+999-99-999-999',
  BW: '+999-99-999-999',
  BY: '+999(99)999-99-99',
  BZ: '+999-999-9999',
  CA: '+9(999)999-9999',
  CD: '+999(999)999-999',
  CF: '+999-99-99-9999',
  CG: '+999-99-999-9999',
  CH: '+99-99-999-9999',
  CI: '+999-99-999-999',
  CK: '+999-99-999',
  CL: '+99-9-9999-9999',
  CM: '+999-9999-9999',
  CN: '+99(999)9999-9999',
  CO: '+99(999)999-9999',
  CR: '+999-9999-9999',
  CU: '+99-9-999-9999',
  CV: '+999(999)99-99',
  CW: '+999-999-9999',
  CY: '+999-99-999-999',
  CZ: '+999(999)999-999',
  DE: '+99(9999)999-9999',
  DJ: '+999-99-99-99-99',
  DK: '+99-99-99-99-99',
  DM: '+9(999)999-9999',
  DO: '+9(999)999-9999',
  DZ: '+999-99-999-9999',
  EC: '+999-99-999-9999',
  EE: '+999-9999-9999',
  EG: '+99(999)999-9999',
  ER: '+999-9-999-999',
  ES: '+99(999)999-999',
  ET: '+999-99-999-9999',
  FI: '+999(999)999-99-99',
  FJ: '+999-99-99999',
  FK: '+999-99999',
  FM: '+999-999-9999',
  FO: '+999-999-999',
  FR: '+999-99999-9999',
  GA: '+999-9-99-99-99',
  GD: '+9(999)999-9999',
  GE: '+999(999)999-999',
  GF: '+999-99999-9999',
  GH: '+999(999)999-999',
  GI: '+999-999-99999',
  GL: '+999-99-99-99',
  GM: '+999(999)99-99',
  GN: '+999-99-999-999',
  GQ: '+999-99-999-9999',
  GR: '+99(999)999-9999',
  GT: '+999-9-999-9999',
  GU: '+9(999)999-9999',
  GW: '+999-9-999999',
  GY: '+999-999-9999',
  HK: '+999-9999-9999',
  HN: '+999-9999-9999',
  HR: '+999-99-999-999',
  HT: '+999-99-99-9999',
  HU: '+99(999)999-999',
  ID: '+99(999)999-9999',
  IE: '+999(999)999-999',
  IL: '+999-99-999-9999',
  IN: '+99(9999)999-999',
  IO: '+999-999-9999',
  IQ: '+999(999)999-9999',
  IR: '+999(999)999-9999',
  IS: '+999-999-9999',
  IT: '+99(999)9999-999',
  JM: '+9(999)999-9999',
  JO: '+999-9-9999-9999',
  JP: '+9-99-9999-9999',
  KE: '+999-999-999999',
  KG: '+999(999)999-999',
  KH: '+999-99-999-999',
  KI: '+999-99-999',
  KM: '+999-99-99999',
  KN: '+9(999)999-9999',
  KP: '+999-999-999-9999',
  KR: '+99-99-999-9999',
  KW: '+999-9999-9999',
  KY: '+9(999)999-9999',
  KZ: '+9(999)999-99-99',
  LA: '+999(9999)999-999',
  LB: '+999-9-999-999',
  LC: '+9(999)999-9999',
  LI: '+999(999)999-9999',
  LK: '+99-99-999-9999',
  LR: '+999-99-999-999',
  LS: '+999-9-999-9999',
  LT: '+999(999)99-999',
  LU: '+999(999)999-999',
  LV: '+999-99-999-999',
  LY: '+999-99-999-999',
  MA: '+999-99-9999-999',
  MC: '+999(999)999-999',
  MD: '+999-9999-9999',
  ME: '+999-99-999-999',
  MG: '+999-99-99-99999',
  MH: '+999-999-9999',
  MK: '+999-99-999-999',
  ML: '+999-99-99-9999',
  MM: '+99-99-999-999',
  MN: '+999-99-99-9999',
  MO: '+999-9999-9999',
  MP: '+9(999)999-9999',
  MQ: '+999(999)99-99-99',
  MR: '+999-99-99-9999',
  MS: '+9(999)999-9999',
  MT: '+999-9999-9999',
  MU: '+999-999-9999',
  MV: '+999-999-9999',
  MW: '+999-9-999-999',
  MX: '+99-99-99-9999',
  MY: '+99-99-999-9999',
  MZ: '+999-99-999-999',
  NA: '+999-99-999-9999',
  NC: '+999-99-9999',
  NE: '+999-99-99-9999',
  NF: '+999-999-999',
  NG: '+999(999)999-9999',
  NI: '+999-9999-9999',
  NL: '+99-99-999-9999',
  NO: '+99(999)99-999',
  NP: '+999-99-999-999',
  NR: '+999-999-9999',
  NU: '+999-9999',
  NZ: '+99(999)999-999',
  OM: '+999-99-999-999',
  PA: '+999-999-9999',
  PE: '+99(999)999-999',
  PF: '+999-99-99-99',
  PG: '+999(999)99-999',
  PH: '+99(999)999-9999',
  PK: '+99(999)999-9999',
  PL: '+99(999)999-999',
  PS: '+999-99-999-9999',
  PT: '+999-99-999-9999',
  PW: '+999-999-9999',
  PY: '+999(999)999-999',
  QA: '+999-9999-9999',
  RE: '+999-99999-9999',
  RO: '+99-99-999-9999',
  RS: '+999-99-999-9999',
  RU: '+9(999)999-99-99',
  RW: '+999(999)999-999',
  SA: '+999-9-9999-9999',
  SB: '+999-999-9999',
  SC: '+999-9-999-999',
  SD: '+999-99-999-9999',
  SE: '+99-99-999-9999',
  SG: '+99-9999-9999',
  SH: '+999-9999',
  SI: '+999-99-999-999',
  SK: '+999(999)999-999',
  SL: '+999-99-999999',
  SM: '+999-9999-999999',
  SN: '+999-99-999-9999',
  SO: '+999-99-999-999',
  SR: '+999-999-9999',
  SS: '+999-99-999-9999',
  ST: '+999-99-99999',
  SV: '+999-99-99-9999',
  SX: '+9(999)999-9999',
  SY: '+999-99-9999-999',
  SZ: '+999-99-99-9999',
  TC: '+9(999)999-9999',
  TD: '+999-99-99-99-99',
  TG: '+999-99-999-999',
  TH: '+99-99-999-9999',
  TJ: '+999-99-999-9999',
  TK: '+999-9999',
  TL: '+999-999-9999',
  TM: '+999-9-999-9999',
  TN: '+999-99-999-999',
  TO: '+999-99999',
  TR: '+99(999)999-9999',
  TT: '+9(999)999-9999',
  TV: '+999-999999',
  TW: '+999-9999-9999',
  TZ: '+999-99-999-9999',
  UA: '+999(99)999-99-99',
  UG: '+999(999)999-999',
  UK: '+99-99-9999-9999',
  US: '+9(999)999-9999',
  UY: '+999-9-999-99-99',
  UZ: '+999-99-999-9999',
  VA: '+99-9-999-99999',
  VC: '+9(999)999-9999',
  VE: '+99(999)999-9999',
  VG: '+9(999)999-9999',
  VI: '+9(999)999-9999',
  VN: '+99-99-9999-999',
  VU: '+999-99-99999',
  WF: '+999-99-9999',
  WS: '+999-99-9999',
  YE: '+999-99-999-999',
  ZA: '+99-99-999-9999',
  ZM: '+999-99-999-9999',
  ZW: '+999-9-999999',
};

export default countriesMask;
