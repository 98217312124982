import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  textarea {
    height: 150px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    padding: 10px;
    font-weight: 700;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
  }
  label {
    font-size: ${(props) => props.theme.fontSize + 14}px !important;
    font-weight: 400;
  }
  input {
    height: 50px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    padding: 0px 10px;
    background-color: ${(props) => props.theme.backgroundColor};
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
  }
  select {
    background-color: ${(props) => props.theme.backgroundColor};
    height: 50px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    padding: 0px 10px;
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
  }

  ul {
    list-style-type: none;
    background-color: white;
    position: absolute;

    li {
      padding: 10px 15px;
      min-width: 440px;

      &:hover {
        background: #7764D8;
        color: white;
      }
    }
  }

  &.flex1 {
    flex: 1;
  }
  &.flex2 {
    flex: 2;
  }
  &.flex3 {
    flex: 3;
  }
  &.flex4 {
    flex: 4;
  }
  &.flex5 {
    flex: 5;
  }
  &.flex6 {
    flex: 6;
  }
  &.flex7 {
    flex: 7;
  }
  &.flex8 {
    flex: 8;
  }
  &.flex9 {
    flex: 9;
  }
  &.flex10 {
    flex: 10;
  }
  &.flex11 {
    flex: 11;
  }
  &.flex12 {
    flex: 12;
  }
  @media screen and (max-width: 720px) {
    width: 90%;
    flex: 0 !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 40vw;
    &.flex1,
    &.flex2,
    &.flex3,
    &.flex4,
    &.flex5,
    &.flex6,
    &.flex7,
    &.flex8,
    &.flex9,
    &.flex10,
    &.flex11,
    &.flex12 {
      flex: 0 !important;
    }
  }
`;

export const Drowpdown = styled.p.attrs(() => ({
  position: 'absolute'
}))

export const ErrorMessage = styled.p.attrs(() => ({
  ariaHidden: true,
}))`
  margin-top: 6px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize + 14}px !important;
  color: red;
`;
