import { createGlobalStyle } from "styled-components";

export const ModalStyle = createGlobalStyle`

.modal-default{
background-color: ${(props) => props.theme.backgroundColor};
border-radius: 12px;
position: relative;
.green-icon, .green-icon polyline {
    stroke: #45A855 !important;
}
a {
  text-decoration: underline;
  transition: 500ms ease;
}
a:hover{
    background-color: var(--pink);
        color: var(--white);
}
>button.close-modal{
position: absolute;
top: 10px;
right: 10px;
background: transparent;
border: none;
font-size: 30px;
}
>button.close-modal-biggest{
    /* background: transparent; */
    border: none;
    font-size: 20px;
    width: 40%;
    height: 50px;
    background: var(--pink);
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
}
    .modal-accept{
        /* background: transparent; */
        border: none;
        font-size: 20px;
        width: fit-content;
        padding: 0 30px ;
        height: 50px;
        margin: 30px 0 0 0;
        background: #1e4620;
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 700;
        color: var(--white);
        text-transform: uppercase;
        transition: 250ms;
    }
    .modal-accept .btns{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .modal-accept-btn-reject{
            background-color: #cd2626;
        }
    }
}
.overlay-default{
    background: ${(props) => props.theme.textColor}A8;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-login-error{
    background: ${(props) => props.theme.textColor}A8;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

#modal-error{
    z-index: 99999;
    border: red 2px var(--pink);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 395px;
    min-height: 190px;
    padding: 30px 50px;
    p{
        font-size: 16px;
        font-weight: 400;
        .register{
            background: transparent;
            border: none;
            color: #001DFF;
            font-weight: 700;
            text-decoration: underline;
        }
    }
}

#modal-login{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
width: 395px;
min-height: 410px;
padding: 30px 50px;
    input{
        height: 50px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--gray);
        padding: 0px 10px;
        font-size: 16px;
    }
    button.forgot{
        font-size: 16px;
        font-weight: 400;
        color: #001DFF;
        background: transparent;
        border: none;
        text-decoration: underline;
    }
    button.send{
        border-radius: 10px;
        background: var(--pink);
        height: 50px;
        width: 100%;
        border: none;
        color: var(--white);
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        .register{
background: transparent;
border: none;
color: #001DFF;
font-weight: 700;
text-decoration: underline;
        }
    }
}

#modal-medium{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width: 1000px;
height: 400px;
padding: 30px 50px;
gap: 50px;
    div.line{
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        gap: 50px;
    }
    div.item{
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        label{
            font-size: 16px;
            font-weight: 700;
            color: var(--gray);
        }
        >div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            &.item1{
                button{
                    height: 48px;
                    width: 48px;
                    font-weight: 700;
                    font-size: 30px;
                    border-radius: 7px;
                    background: var(--white);
                    border: none;
                    position: relative;
                    >span{
                        height: 5px;
                        background: var(--bg-body);
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        &.active{
                            background: var(--pink);
                        }
                    }
                }
                button:nth-of-type(1){
                    border: 1px solid var(--gray);
                }
                button:nth-of-type(2){
                    background: var(--yellow);
                }
                button:nth-of-type(3){
                    background: var(--black);
                    color: var(--white);
                }
                button:nth-of-type(4){
                    background: var(--black);
                    color: var(--yellow);
                }
            }
            &.item2{
                button{
                    background: transparent;
                    border: none;
                    position: relative;
                    >span{
                        height: 5px;
                        background: var(--bg-body);
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        &.active{
                            background: var(--pink);
                        }
                    }
                }
                button:nth-of-type(1){
                    font-size: 16px;
                    font-weight: 400;
                }
                button:nth-of-type(2){
                    font-size: 22px;
                    font-weight: 400;
                }
                button:nth-of-type(3){
                    font-size: 28px;
                    font-weight: 400;
                }
            }
            &.item3{
                button{
                    background: transparent;
                    border: none;
                    position: relative;
                    >span{
                        height: 5px;
                        background: var(--bg-body);
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        &.active{
                            background: var(--pink);
                        }
                    }
                }
                button:nth-of-type(1){
                    letter-spacing: 0px;
                }
                button:nth-of-type(2){
                    letter-spacing: 1px;
                }
                button:nth-of-type(3){
                    letter-spacing: 2px;
                }
            }
            &.item4{
                button{
                    background: transparent;
                    border: none;
                    position: relative;
                    >span{
                        height: 5px;
                        background: var(--bg-body);
                        width: 100%;
                        display: block;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        &.active{
                            background: var(--pink);
                        }
                    }
                }
                button:nth-of-type(1){
                    font-family: 'Source Sans Pro', sans-serif;
                }
                button:nth-of-type(2){
                    font-family: 'Roboto Slab', serif;
                }
                button:nth-of-type(3){
                    font-family: 'OpenDyslexic', sans-serif;
                }
            }
        }
    }
}
@media screen and (max-width: 720px){
    #modal-login{
        width: 95vw;
        height: fit-content;
    }
    #modal-medium{
        width: 95vw;
        height: fit-content;
        div.line {
            flex-direction: column;
        }
        div.item{
            flex-direction: column;
        }
        div.item1{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item2{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item3{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item4{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
    }
}
@media screen and (max-width: 1300px) and (min-width: 721px) {
    #modal-medium{
        width: 95vw;
        height: fit-content;
        div.line {
            flex-direction: column;
        }
        div.item{
            flex-direction: column;
        }
        div.item1{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item2{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item3{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
        div.item4{
            width: fit-content !important;
            button {
                margin-right: 16px;
            }
        }
    }
  }
`;
