import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  align-self: center;
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  position: relative;

  span {
    height: 2px;
    width: 100%;
    position: absolute;
    top: 14px;
    z-index: -1;
    background: ${(props) => props.theme.backgroundColor};
  }
  div.step {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    p:first-child {
      background: ${(props) => props.theme.backgroundColor};
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.textColor};
      font-weight: 700;
      font-size: ${(props) => props.theme.fontSize + 14}px;
    }
    p:last-child {
      font-size: ${(props) => props.theme.fontSize + 14}px;
      color: ${(props) => props.theme.textColor}aa;
    }
    &.active {
      p:first-child {
        background: var(--yellow);
        color: ${(props) => props.theme.backgroundColor};
      }
      p:last-child {
        font-weight: 700;
        color: ${(props) => props.theme.textColor};
      }
    }
  }
  @media screen and (max-width: 720px) {
    p:last-child {
      font-size: 12px !important;
      text-align: center;
    }
  }
`;
