import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Container, Wrapper, Content } from "./styles";
import { useState, useEffect } from "react";
import { NewsCard } from "../../components/NewsCard";
import api from "../../services/api";
import { Pagination } from "../../components/Pagination";

export function Projects() {

    const [news, setNews] = useState([])

    const [pagination, setPagination] = useState(0)

    const PAGINATION_STEP_SIZE = 8

    const fetchForNewsAndSponsoredProjects = async () => {
        try {
            const response = await api.get(`/articles?_limit=-1&sponsor=true`)

            if (response) {
                if (response.status === 200) {
                    setNews(response.data.filter(({ inactive }) => !inactive))
                    return
                }
                throw new Error('Unexpected response')
            }
            throw new Error('Network error')
        } catch (error) {
            console.log(error, 'error while fetching for news and sponsored news')
        }
    }

    useEffect(() => {
        fetchForNewsAndSponsoredProjects()
    }, [])

    return (
        <Wrapper>
            <Header menu={4} />
            <GoBack title="Projetos" />
            <Container>
                <Content>
                    <div>
                        {
                            news.slice(PAGINATION_STEP_SIZE * pagination, Math.min(news.length, PAGINATION_STEP_SIZE * (pagination + 1))).map((item, key) => <NewsCard
                                news={item}
                                key={key + 'news'} />)
                        }
                    </div>
                </Content>
                <Pagination
                    value={pagination}
                    onChange={setPagination}
                    paginationSize={PAGINATION_STEP_SIZE}
                    itemQuantity={news.length}

                />
            </Container>
            <Footer />
        </Wrapper>
    )
}