import styled from 'styled-components';

export const Container = styled.section`
  width: var(--width-p);
  margin: 0 250px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin: 0 16px;
  }
`;

export const Search = styled.div`
  width: 100%;
  > div {
    width: 568px;
    height: 50px;
    position: relative;
    input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.textColor}88;
      padding: 0px 50px 0px 10px;
      color: ${(props) => props.theme.textColor};
      font-size: ${(props) => props.theme.fontSize + 16}px;
      &::placeholder {
        color: ${(props) => props.theme.textColor}aa;
        font-weight: 700;
        font-size: ${(props) => props.theme.fontSize + 14}px;
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      border: none;
      background: transparent;
      font-size: ${(props) => props.theme.fontSize + 25}px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 720px) {
    > div {
      width: 100vw;
      input {
        width: 80%;
      }
      button {
        right: 80px;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    > div {
      width: 100vw;
      input {
        width: 80%;
      }
      button {
        right: 80px;
      }
    }
  }
`;

export const Collapses = styled.main`
  padding-bottom: 50px;
  @media screen and (max-width: 720px) {
    width: 90vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90vw;
  }
`;
