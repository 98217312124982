export default class MaxLengthValidator {
    constructor(length) {
        this.length = length;
    }

    validate(string) {
        if(string.length > this.length) {
            return `Máximo de ${this.length} caracteres`;
        }
        return "";
    }
}