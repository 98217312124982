import styled from "styled-components"

export const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    svg {
        fill: var(--pink);
    }
    p {
        color: var(--pink);
        margin-left: 16px;
    }
`