import { Wizard } from "../Wizard";

import { ModalDefault } from "../../components/ModalDefault";
import { RenderInput } from "./components/render-input";
import { Container, Controllers, Line } from "./styles";

import { useState } from "react";

export function FormConstructor(props) {
    const { form, step, proceed, proceedText, cancel, cancelText, edit, changeStep } = props
    let isFormValid = false
    let refsArray = [];

    const [errorLabel, setErrorLabel] = useState('')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const filterOptionalInput = form.filter((item) => !item.optional)
    

    const filterValidValues = filterOptionalInput.filter((item) => item.validation ?
        !item.validation(item.value ?? item.items) :
        typeof item.value === 'string' ? item.value.length === 0
            : item.name)

    isFormValid = filterValidValues.length === 0

    const handleInvalidFormAlert = () => {
        const getLastInvalidInput = filterValidValues[0]

        let parsedLabel = getLastInvalidInput.label
        const onFocus = document.querySelector(`[id="${getLastInvalidInput.formLabel || getLastInvalidInput.formLabel.name}"]`);

        if (onFocus) onFocus.focus();

        const parsedErrorMessage = getLastInvalidInput.items ? getLastInvalidInput.items.filter(({value}) => !value)[0].errorMessage : getLastInvalidInput.errorMessage
        // console.log("🐌🧲 ~ file: form-constructor.jsx ~ line 29 ~ handleInvalidFormAlert ~ parsedLabel", parsedLabel)
        // console.log("🐌🧲 ~ file: form-constructor.jsx ~ line 26 ~ handleInvalidFormAlert ~ getLastInvalidInput", getLastInvalidInput)

        setErrorLabel("")
        setTimeout(() => setErrorLabel(parsedLabel), 16)
        if (parsedErrorMessage) {
            setModalError(true)
            setModalErrorMessage(parsedErrorMessage)
            return
        }
        setModalError(true)
        setModalErrorMessage(`O campo ${parsedLabel} é obrigatório.`)
    }

    const closeModalShowLastRequiredField = () => {
        const getLastInvalidInput = filterValidValues[0]
        let parsedLabel = getLastInvalidInput.label

        setModalError(false)
        setErrorLabel("")
        setTimeout(() => setErrorLabel(parsedLabel), 16)
    }

    form.forEach((f) => {
        refsArray[f.label] = null;
    });

    return (
        <>
            <Wizard step={step} isEnterprise={true} navigate={edit} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()} showClose>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container tabIndex="1">
                {
                    Array(Math.floor(form.length / 2)).fill(0).map((_, key) => {
                        const baseKey = key * 2;
                        const [first, second] = [form[baseKey], form[baseKey + 1]];

                        return <Line>
                            <RenderInput
                                {
                                ...(first ?? {})
                                }
                                autofocus={errorLabel === first.label}
                            />
                            <RenderInput
                                {
                                ...(second ?? {})
                                }
                                autofocus={errorLabel === second.label}
                            />
                        </Line>
                    })
                }
            </Container>
            <Controllers>
                <button onClick={cancel}>{cancelText ?? "Voltar"}</button>
                <button style={{
                    opacity: isFormValid ? 1 : .5
                }} onClick={() => isFormValid ? proceed() : handleInvalidFormAlert()}>{proceedText}</button>
            </Controllers>
        </>
    )
}