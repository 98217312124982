import { Bottom, Container, Top, Space, ContrastButton, ActiveSpan, Label } from "./styles"
import searchIcon from '../../assets/icons/search.svg';
import bellIcon from '../../assets/icons/bell.svg';
import accessibilityIcon from '../../assets/icons/accessibility.svg';
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ModalDefault } from "../ModalDefault";
import AuthContext, { STORAGE_KEYS } from "../../contexts/auth";
import { ReactiveLogo } from "../ReactiveLogo";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { Login } from "./components/Login";
import api from "../../services/api";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export function Header(props) {
    const context = useContext(AuthContext);
    const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

    const { Logout, user, stageCollection, setStageCollection } = context
    const [notifications, setNotifications] = useState([])

    const [notificationsCount, setNotificationsCount] = useState(0)
    // console.log("🐌🧲 ~ file: index.jsx ~ line 23 ~ Header ~ notificationsCount", notificationsCount)

    const fetchForNotificationsCount = async () => {
        try {
            const response = await api.get(`/alerts-by-users/count?user.id=${user.id}&sent=false&read=false`)
            if(response.status === 200){
                setNotificationsCount(response.data)
            }
        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(isLogged) {
            const intervalFetchCount = setInterval(fetchForNotificationsCount, 8062)
            return () => clearInterval(intervalFetchCount)
        }
    }, [user])

    const thisPage = props.menu;
    const [helpModal, setHelpModal] = useState(false);
    const [modalLogin, setModalLogin] = useState(false);
    const [modalAce, setModalAce] = useState(false);

    const [loginTitle, setLoginTitle] = useState('Login')

    const theme = useTheme()

    const history = useHistory()

    const handleLogoff = () => {
        Logout()
    }

    const filterUnreadNotifications = notifications.filter(({ read }) => !read)

    return (
        <>
            <Container>
                <Top>
                    <div>
                        <Link to="/buscar" aria-label="Abrir busca de livros" tabIndex={1}>
                            <img src={searchIcon} alt="" />
                        </Link>
                        <button onClick={() => setModalAce(true)} tabIndex={1}
                            aria-label="Abrir menu de acessibilidade">
                            <img src={accessibilityIcon} alt="" />
                        </button>
                        {
                            isLogged ? <button className="notification" onClick={() => history.push("/alertas")} aria-label="Abrir notificações" tabIndex={1}>
                                <img src={bellIcon} alt="" />
                                {
                                    notificationsCount > 0 ?
                                        <span>
                                            {Math.min(99, notificationsCount)}
                                        </span>
                                        : null
                                }
                            </button>
                                : null
                        }
                    </div>
                </Top>
                <Bottom>
                    <div>
                        <Link to="/" className="logo" aria-label="Ir para página inicial" tabIndex={1}>
                            {/* <img src={logoImg} alt="" /> */}

                            <ReactiveLogo color={theme.textColor} />
                        </Link>
                        <nav>
                            <h3 className="ds-none">Menu Superior</h3>
                            <Link className={thisPage === 1 && !stageCollection ? "active" : ""} to="/acervo" tabIndex={1} onClick={() => {setStageCollection(false)}}>Acervo</Link>
                            <Link className={thisPage === 2 ? "active" : ""} to="/noticias" tabIndex={1}>Notícias</Link>
                            {
                                isLogged ?
                                    <Link
                                        className={thisPage === 3 ? "active" : ""}
                                        to="/minha-dorinateca"
                                    >
                                        Minha Dorinateca
                                    </Link>
                                    : null
                            }
                            <Link className={thisPage === 4 ? "active" : ""} to="/projetos-e-patrocinios" tabIndex={1}>Projetos e Patrocínios</Link>
                            <div
                                className={thisPage === 5 || helpModal ? "active" : ""}
                                onFocus={() => setHelpModal(true)}
                                onBlur={() => setHelpModal(false)}
                                onMouseEnter={() => setHelpModal(true)}
                                onClick={() => setHelpModal(true)}
                                aria-label="Ajuda"
                            >
                                Ajuda
                                <div className={isLogged ? "collapse" : "collapse-not-logged"}
                                    style={{
                                        zIndex: helpModal ? 10 : -100,
                                        opacity: helpModal ? 1 : 0,
                                        top: helpModal ? '100%' : -999999
                                    }}
                                    onMouseLeave={() => setHelpModal(false)}
                                    onClick={()=>{setHelpModal(false)}}>
                                    <button tabIndex={1} onClick={() => {
                                        history.push("/perguntas-frequentes")
                                        setHelpModal(false)
                                    }}>Perguntas e respostas</button>
                                    <button tabIndex={1} onClick={() => {
                                        history.push("/recursos-de-acessibilidade")
                                        setHelpModal(false)
                                    }}>Recursos de acessibilidade</button>
                                    <button tabIndex={1} onClick={() => {
                                        history.push("/bibliotecas-parceiras")
                                        setHelpModal(false)
                                    }}>Encontre a biblioteca mais próxima</button>
                                </div>
                            </div>
                            <Link className={thisPage === 6 ? "active" : ""} to="/contato" tabIndex={1}>Contato</Link>
                            {
                                isLogged ?
                                    <Link className={thisPage === 7 ? "active" : ""} to="/" onClick={handleLogoff} tabIndex={1}>Sair</Link>
                                    :
                                    <button className={thisPage === 7 ? "active" : ""} onClick={() => setModalLogin(true)} tabIndex={1}>Entrar</button>
                            }
                        </nav>
                    </div>
                </Bottom>
            </Container>
            <Space/>
            <ModalDefault type="login" title={loginTitle} isOpen={modalLogin} 
            onRequestClose={() => setModalLogin(false)}>
                <Login
                    close={() => setModalLogin(false)}
                    setLoginTitle={setLoginTitle}
                />
            </ModalDefault>
            <ModalDefault type="medium" title="Recursos de acessibilidade" isOpen={modalAce} onRequestClose={() => setModalAce(false)}>
                {/* // 
            themeContrast, 
            setThemeContrast, 
            themeFontSize, 
            setThemeFontSize, 
            themeLetterSpacing, 
            setThemeLetterSpacing, 
            themeFontFamily, 
            setThemeFontFamily 
            */}

                <div className="line">
                    <div className="item">
                        <Label aria-label="Menu de contraste" htmlFor="">Contraste</Label>
                        <div className="item1">
                            <ContrastButton aria-label="Contraste 1" onClick={() => context.setThemeContrast(1)}>
                                A <ActiveSpan active={context.themeContrast === 1} />
                            </ContrastButton>
                            <ContrastButton aria-label="Contraste 2" onClick={() => context.setThemeContrast(2)}>
                                A <ActiveSpan active={context.themeContrast === 2} />
                            </ContrastButton>
                            <ContrastButton aria-label="Contraste 3" onClick={() => context.setThemeContrast(3)}>
                                A <ActiveSpan active={context.themeContrast === 3} />
                            </ContrastButton>
                            <ContrastButton aria-label="Contraste 4" onClick={() => context.setThemeContrast(4)}>
                                A <ActiveSpan active={context.themeContrast === 4} />
                            </ContrastButton>
                        </div>
                    </div>
                    <div className="item">
                        <Label htmlFor="" aria-label="Menu de tamanho de texto">Tamanho do texto</Label>
                        <div className="item2">
                            <button aria-label="Texto pequeno"
                                onClick={() => context.setThemeFontSize(1)}>Pequeno
                                <ActiveSpan active={context.themeFontSize === 1} />
                            </button>
                            <button aria-label="Texto médio" onClick={() => context.setThemeFontSize(2)}>Médio
                                <ActiveSpan active={context.themeFontSize === 2} />
                            </button>
                            <button aria-label="Texto grande" onClick={() => context.setThemeFontSize(3)}>Grande
                                <ActiveSpan active={context.themeFontSize === 3} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="line">
                    <div className="item">
                        <Label htmlFor="" aria-label="Menu de espaçamento de texto">Espaçamento do texto</Label>
                        <div className="item3">
                            <button aria-label="Espaçamento pequeno" onClick={() => context.setThemeLetterSpacing(1)}>Estreito
                                <ActiveSpan active={context.themeLetterSpacing === 1} />
                            </button>
                            <button aria-label="Espaçamento médio" onClick={() => context.setThemeLetterSpacing(2)}>Médio
                                <ActiveSpan active={context.themeLetterSpacing === 2} />
                            </button>
                            <button aria-label="Espaçamento grande" onClick={() => context.setThemeLetterSpacing(3)}>Espaçado
                                <ActiveSpan active={context.themeLetterSpacing === 3} />
                            </button>
                        </div>
                    </div>
                    <div className="item">
                        <Label htmlFor="" aria-label="Menu de fonte de texto">Alterar fonte</Label>
                        <div className="item4">
                            <button aria-label="Fonte Source Sans Pro" onClick={() => context.setThemeFontFamily(1)}>Source Sans Pro
                                <ActiveSpan active={context.themeFontFamily === 1} />
                            </button>
                            <button aria-label="Fonte Roboto Slab" onClick={() => context.setThemeFontFamily(2)}>Roboto Slab
                                <ActiveSpan active={context.themeFontFamily === 2} />
                            </button>
                            <button aria-label="Fonte OpenDyslexic" onClick={() => context.setThemeFontFamily(3)}>OpenDyslexic
                                <ActiveSpan active={context.themeFontFamily === 3} />
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDefault>


        </>
    )
}