import { useState } from "react"
import { BASE_URL } from "../../services/api"
import {
    ArticleSectionContainer,
    ArticleTitle,
    SectionBody,
    SectionImageContainer,
    SectionImageDisplay,
    SectionImageThumbnail,
    SectionImageThumbnailContainer,
    SectionImageDisplayContainer,
    ImageCaption,
    InvisibleLabel
} from './styles'
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw"

export const ArticleSection = ({ title, images, body, imageTitle }) => {
    const [stage, setStage] = useState(0)
    const selectedImageObject = images[stage] ?? {}

    return <ArticleSectionContainer tabIndex={0} aria-label={`Seção: ${title}`}>
        <ArticleTitle tabIndex={1}>
            <InvisibleLabel>
                Título da seção:
            </InvisibleLabel>
            {title}
        </ArticleTitle>
        <SectionBody tabIndex={1}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {body}
            </ReactMarkdown>
        </SectionBody>
        {
            selectedImageObject.file ?
                <SectionImageContainer tabIndex={0} >
                    <SectionImageDisplayContainer>
                        <ArticleTitle tabIndex={1}>
                            {imageTitle}
                        </ArticleTitle>
                        <SectionImageDisplay>
                            <img
                                tabIndex={1}
                                src={selectedImageObject.file?.url}
                                alt={(selectedImageObject ? selectedImageObject.label : 'Imagem sem descrição') ?? 'Imagem sem seleção'}
                            />
                        </SectionImageDisplay>
                        <ImageCaption>
                            {selectedImageObject.caption ?? ""}
                        </ImageCaption>
                    </SectionImageDisplayContainer>
                    <SectionImageThumbnailContainer>
                        {
                            images.map((image, key) => key === stage ? null :
                                image === null ? null :
                                    <SectionImageThumbnail
                                        key={'thumbnail' + key}
                                        onClick={() => setStage(key)}
                                        bgImage={image.file?.url}
                                        tabIndex={1}
                                    >
                                    </SectionImageThumbnail>)
                        }
                    </SectionImageThumbnailContainer>
                </SectionImageContainer>
                : null
        }


    </ArticleSectionContainer>
}