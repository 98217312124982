import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  input {
    display: none;
  }
  @media screen and (max-width: 720px) {
    width: 40%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 40%;
  }
`;

export const Upload = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  height: 50px;
  width: 100%;
  border: 2px dashed #707070;
  border-radius: 7px;
  background: var(--white);
  color: #5d5d5d;
`;
