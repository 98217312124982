import { Container, Content, Content2, Content3 } from "./styles";
import { useContext, useState, useEffect } from "react";
import api from '../../services/api'
import { FiPlus } from "react-icons/fi";
import { BookCard } from "../BookCard";
import AuthContext, { STORAGE_KEYS } from "../../contexts/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SmallBookCard } from "../SmallBookCard";

export function MainCollection() {

    const [recentBooks, setRecentBooks] = useState([])

    const { setSelectedBook } = useContext(AuthContext)
    const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

    const [recentStep, setRecentStep] = useState(0)

    const RECENT_ITEMS_BASE_STEP = 10

    const filteredRecentBooksByStep = recentBooks.slice(0, Math.min((recentStep + 1) * RECENT_ITEMS_BASE_STEP, recentBooks.length))

    const canMakeNewSteps = filteredRecentBooksByStep.length < recentBooks.length

    const [booksByDownloads, setBooksByDownloads] = useState([])

    const [highlightList, setHighlightList] = useState([])

    const fetchForSuggestionsAndBookHighlights = async () => {
        try {
            const hihglights = await api.get(`/suggestions?highlight=true&_limit=1&_sort=created_at%3Adesc`)

            if (hihglights) {
                if (hihglights.status === 200) {
                    let highlightedBooks = []
                    hihglights.data.forEach(({ books }) => highlightedBooks = highlightedBooks.concat(books))

                    if(!isLogged) {
                        highlightedBooks = highlightedBooks.filter(({license}) => typeof license === 'number' ? license === 1 : license.id === 1)
                        // console.log("🐌🧲 ~ file: index.jsx ~ line 39 ~ fetchForSuggestionsAndBookHighlights ~ highlightedBooks", highlightedBooks)
                    }
                    highlightedBooks = highlightedBooks.filter(({inactive}) => !inactive)
                    
                    setHighlightList(highlightedBooks)
                    // console.log("🐌🧲 ~ file: index.jsx ~ line 167 ~ fetchForSuggestionsAndBookHighlights ~ highlightedBooks", highlightedBooks)
                }
            }

        } catch (error) {
            console.log(error, 'error during suggestion fetch')
        }
    }

    const fetchForMostRecentBooks = async () => {
        try {

            const url = `/books?_sort=created_at%3Adesc&_limit=100`

            const response = await api.get(url)
            if (response) {
                if (response.status === 200) {
                    setRecentBooks(response.data.filter(({inactive}) => !inactive))
                    return
                }
                throw new Error('Unauthorized')
            }
            throw new Error('Unexpected response')
        } catch (error) {
            console.log(error, 'error while fetching for most recent books')
        }
    }

    const fetchForMostDownloaded = async () => {
        try {
            
            const url = `/books?_sort=downloads%3Adesc&_limit=10`

            const response = await api.get(url)
            if (response) {
                if (response.status === 200) {
                    setBooksByDownloads(response.data.filter(({inactive}) => !inactive))
                    return
                }
                throw new Error('Unauthorized')
            }
            throw new Error('Unexpected response')
        } catch (error) {
            console.log(error, 'error while fetching for most recent books')
        }
    }

    useEffect(() => {
        fetchForMostRecentBooks()
        fetchForMostDownloaded()
        fetchForSuggestionsAndBookHighlights()
    }, [])

    const history = useHistory()

    const redirectToTargetBook = (book) => {
        setSelectedBook(book)
        history.push(`/detalhes-da-obra/${book.id}`)
    }


    return (
        <Container>
            <Content>
                <h2 tabIndex={0}>Destaques Acervo</h2>
                <div>
                    {
                        highlightList.map((book, key) => <BookCard
                            action={() => redirectToTargetBook(book)}
                            key={key + 'highlight'}
                            book={book} />)
                    }
                </div>
            </Content>
            <Content2>
                <h2 tabIndex={0}>Novidades</h2>
                <div>
                    {
                        filteredRecentBooksByStep.map((book, key) => <SmallBookCard
                            action={() => redirectToTargetBook(book)}
                            key={key + 'content'}
                            book={book} />)
                    }
                </div>
                <div className="div-button">
                    {
                        canMakeNewSteps ?
                        <button 
                        aria-label="Mostrar mais livros recentes"
                        onClick={() => setRecentStep((prevState) => ++prevState)} className="see-more"> <FiPlus /> Ver mais </button>
                        : null
                    }
                </div>

            </Content2>
            <Content3>
                <h2 tabIndex={0}>Mais Retirados / Baixados</h2>
                <div>
                    {
                        booksByDownloads.map((book, key) => <SmallBookCard
                            action={() => redirectToTargetBook(book)}
                            key={key + 'content'}
                            showDownloads={true}
                            book={book} />)
                    }
                </div>
            </Content3>
        </Container>
    )
}