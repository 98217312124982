import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import {
    Container,
    Search,
    Collapses
} from "./styles";
import { GoBack } from '../../components/GoBack';
import { FiSearch } from "react-icons/fi";
import { Hide } from "../../components/Hide";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { replaceAllAcemptuation } from "../../data/formatting/latin-replace-acemptuation/latin-replace-acemptuation";
import { NoMatchError } from "../../components/NoMatchError";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

export function HelpLibraries() {
    const [libraries, setLibraries] = useState([])
    // console.log("🐌🧲 ~ file: index.jsx ~ line 11 ~ HelpLibraries ~ libraries", libraries)
    const [search, setSearch] = useState('')

    const fetchForLibraries = async () => {
        try {

            const response = await api.get(`/partner-libraries`)

            if (response) {
                if (response.status === 200) {
                    setLibraries(response.data.filter(({inactive}) => !inactive))
                    return
                }
            }
        } catch (error) {
            console.log(error, 'error while fetching for libraries')
        }
    }

    useEffect(() => {
        fetchForLibraries()
    }, [])

    let filteredStateByNameOrCity = libraries

    if (search.length > 0) {
        const workedSearchString = replaceAllAcemptuation(search)
        filteredStateByNameOrCity = libraries.filter(({ title, address }) => replaceAllAcemptuation(title).search(workedSearchString) > -1 ||
            replaceAllAcemptuation(`${address.city} - ${address.uf}`).search(workedSearchString) > -1)
    }

    return (
        <>
            <Header menu={5} />
            <GoBack title="Bibliotecas parceiras" />
            <Container>
                <Search>
                    <div>
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Buscar biblioteca" />
                        <button tabIndex={-1} aria-hidden={true}> <FiSearch /> </button>
                    </div>
                </Search>
                <Collapses>
                    {
                        filteredStateByNameOrCity.map(({ title, description, phone, email, address }, key) => <Hide
                            title={`${title} - ${address.city} - ${address.uf}`}
                            key={key + 'library'}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
                            <p>Telefone: {phone}</p>
                            <p>E-mail: {email}</p>
                            <p>
                                Endereço: {`${address.address}, ${address.number}${address.district ? `, ${address.district}` : ''} - ${address.city}, ${address.uf}`}
                            </p>
                        </Hide>)
                    }
                    {
                        filteredStateByNameOrCity.length === 0 ?
                            <NoMatchError />
                            : null
                    }
                </Collapses>
            </Container>
            <Footer />
        </>
    )
}