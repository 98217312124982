import styled from 'styled-components';

export const SearchBar = styled.div`
  width: 568px;
  position: relative;
  margin: 0 auto 50px auto;
  input {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background: ${(props) => props.theme.bodyBackground};
    border: 1px solid ${(props) => props.theme.textColor}aa;
    padding: 0px 120px 0px 10px;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize + 16}px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 113px;
    background: var(--yellow);
    border: 1px solid ${(props) => props.theme.textColor};
    border-left: none;
    border-radius: 0px 12px 12px 0px;
    color: #fff;
    font-size: ${(props) => props.theme.fontSize + 16}px;
  }
  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;
