import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { MainNews } from "../../components/MainNews";
import { GoBack } from "../../components/GoBack";

export function News() {

    
    return (
        <>
            <Header menu={2} />
            <GoBack title="Notícias" />
            <MainNews />
            <Footer />
        </>
    )
}