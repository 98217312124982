import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { Container, Line, Empty, Genero, Controllers } from "./styles";

import ValidationBuilder from '../../../data/validation/builder'
import { maskDocumentInput } from "../../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../../data/regex";
import { ModalDefault } from "../../../components/ModalDefault";
import { useState } from "react";

const cpfValidator = ValidationBuilder("cpf")

const emailValidator = ValidationBuilder("email")

export function Password(props) {
    const { 
        cpf, 
        email, 
        email2, 
        changeStep, 
        save, 
        foreigner, 
        foreignerDocument 
    } = props

    const [targetField, setTargetField] = useState('cpf')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const hasEmail = email !== '' || email2 !== '';

    const isCPFValid = !cpfValidator.validateString(cpf.replace(NUMBER_ONLY, "")).error

    const isEmailValid = email !== '' || foreigner ? !emailValidator.validateString(email).error : true;

    const isEmailMatching = hasEmail || foreigner ? email === email2 : true

    const validateForeignerDocument = foreignerDocument !== '';
    

    const disableButton = foreigner ? !(validateForeignerDocument && isEmailValid && isEmailMatching) 
                : !(isCPFValid && (hasEmail ? isEmailValid && isEmailMatching : true))

    const validationObject = {
        cpf: {
            field: 'cpf',
            message: 'O campo de CPF está inválido'
        },
        email: {
            field: 'email',
            message: 'O campo e-mail está inválido'
        },
        emailMismatch: {
            field: 'email2',
            message: 'Os campos de e-mail não coincidem',
        },
        foreignerDocumentIsEmpty: {
            field: 'foreignerDocument',
            message: 'O campo de documento do estrangeiro não pode estar vazio'
        },
    };

    const focusOnLastRequiredField = () => {
        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' :  '';

        const getErrorEntryForeigner = !validateForeignerDocument ? 'foreignerDocumentIsEmpty' : !isEmailValid ? 'email' : !isEmailMatching ? 'emailMismatch' :  ''

        const getFirstInvalidField = foreigner ? validationObject[getErrorEntryForeigner] :validationObject[getErrorEntry]
        
        setTargetField(' ')
        
        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.field)
    }

    const closeModalShowLastRequiredField = () => {
        const getErrorEntry = !isCPFValid ? 'cpf' : !isEmailValid ? 'email' : !isEmailMatching ?
        'emailMismatch' :  '';

        const getErrorEntryForeigner = !validateForeignerDocument ? 'foreignerDocumentIsEmpty' : !isEmailValid ? 'email' : !isEmailMatching ? 'emailMismatch' :  ''

        const getFirstInvalidField = foreigner ? validationObject[getErrorEntryForeigner] :validationObject[getErrorEntry]

        setModalError(false)
        setTargetField(' ')

        if(getFirstInvalidField.field === targetField) {
            setTargetField(' ')
            const timedOutSetField = setTimeout(() => 
            setTargetField(getFirstInvalidField.field), 256)
            return () => clearTimeout(timedOutSetField)
        }
    }

    return (
        <>
            <Wizard step={2} navigate={true} changeStep={changeStep} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container tabIndex="1">
                <Line>
                    {foreigner ? (
                        <Input 
                                autofocus={targetField === 'cpf' || targetField.length === 0} 
                                value={foreignerDocument} 
                                onChange={(v) => props.setForeignerDocument(v)}
                                onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                                type="text" 
                                label="Documento de identificação para estrangeiro" 
                                placeholder="Documento de identificação para estrangeiro"
                                required
                            />
                        ) : (
                            <Input 
                                targetName={'cpf'} 
                                value={props.cpf} 
                                required={true} 
                                onChange={(v) => props.setCpf(maskDocumentInput(v.replace(NUMBER_ONLY, "").slice(), "cpf"))} 
                                type="text" 
                                label="CPF" 
                                placeholder="CPF para login"
                            />
                        )
                    }
                   <div className="check">
                        <input type="checkbox"
                            id="foreigner"
                            tabIndex={1}
                            aria-label="Sou estrangeiro" 
                            onChange={(e) => props.setForeigner(!foreigner)}
                            checked={foreigner}></input>
                        <label htmlFor="foreigner">Sou estrangeiro</label>
                    </div>
                </Line>
                <Line>
                    <Input targetName={'email'} value={props.email} onChange={(v) => props.setEmail(v)} label="Email" type="text" placeholder="Email para login" required={foreigner} />
                    <Input targetName={'email2'} value={props.email2} onChange={(v) => props.setEmail2(v)} label="Confirmar Email" type="text" placeholder="Confirmar email para login" required={foreigner}/>
                </Line>
            </Container>
            <Controllers>
                <button onClick={props.goBack}>Cancelar</button>
                <button
                    style={{
                        opacity: disableButton ? .5 : 1
                    }}
                    onClick={() => disableButton ? focusOnLastRequiredField() : save()}
                >Salvar</button>
            </Controllers>
        </>
    )
}