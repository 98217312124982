import { useEffect } from "react"
import {Container} from './styles'

export const CheckboxFilter = ({ options, title, onChange, selected, fallback, ariaDescription, style, setPagination }) => {
    useEffect(() => {
        if(selected.length === 0 && fallback) {
            onChange([fallback])
        }

        if(selected.length > 1 && selected.indexOf(fallback) > -1) {
            onChange((prevState) => prevState.filter((item) => item !== fallback))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])
    
    return <Container aria-label={"Selecione " +title} style={style ? style : {}}>
        <p>{title}</p>
        {
            options.map((format, key) => {
                const targetIndex = selected.indexOf(format)
                const isSelected = targetIndex > -1
                const handleOnClick = () => {
                    if (format === 'TODOS' && !isSelected){
                        onChange([fallback])
                    }
                    if (isSelected) {
                        onChange((prevState) => prevState.filter((item) => item !== format))
                        return
                    }
                    setPagination(0)
                    onChange((prevState) => prevState.concat([format]))
                }
                return <div key={key + format}>
                    <input aria-label={ariaDescription[key]} type="checkbox" onChange={handleOnClick} checked={isSelected} name="" id="" />
                    <label htmlFor="">{format}</label>
                </div>
            })
        }
    </Container>
}