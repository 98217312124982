import { Container, Content } from "./styles";
import { useContext, useEffect, useState } from "react";
import AuthContext, { STORAGE_KEYS } from "../../contexts/auth";
import { BookCard } from "../BookCard";
import { NewsCard } from "../NewsCard";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

export function MainHome() {
    const { setSelectedBook } = useContext(AuthContext);
    const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"
    // const [contentList, setContentList] = useState([])

    const [suggestionList, setSuggestionList] = useState({
        title: '',
        books: []
    })

    const [highlightList, setHighlightList] = useState([])

    const [news, setNews] = useState([])

    const [projects, setProjects] = useState([])

    const fetchForSuggestionsAndBookHighlights = async () => {
        try {
            const suggestions = await api.get(`/suggestions?highlight=false&_limit=6&_sort=created_at%3Adesc`)
            if (suggestions) {
                if (suggestions.status === 200) {
                    let getLastSuggestionsBook = suggestions.data.pop()
                    // console.log("🐌🧲 ~ file: index.jsx ~ line 31 ~ fetchForSuggestionsAndBookHighlights ~ isLogged", isLogged)
                    if(!isLogged) {
                        getLastSuggestionsBook = {
                            ...getLastSuggestionsBook,
                            books: getLastSuggestionsBook.books.filter(({license}) => typeof license === 'number' ? license === 1 : license.id === 1)
                        }
                    }
                    getLastSuggestionsBook = {
                        ...getLastSuggestionsBook,
                        books: getLastSuggestionsBook.books.filter(({inactive}) => !inactive)
                    }
                    // console.log("🐌🧲 ~ file: index.jsx ~ line 156 ~ fetchForSuggestionsAndBookHighlights ~ getLastSuggestionsBook", getLastSuggestionsBook)
                    setSuggestionList(getLastSuggestionsBook)
                }
            }
            const hihglights = await api.get(`/suggestions?highlight=true&_limit=6&_sort=created_at%3Adesc`)

            if (hihglights) {
                if (hihglights.status === 200) {
                    let highlightedBooks = []
                    hihglights.data.forEach(({ books }) => highlightedBooks = highlightedBooks.concat(books))
                    if(!isLogged) {
                        highlightedBooks = highlightedBooks.filter(({license}) => typeof license === 'number' ? license === 1 : license.id === 1)
                    }
                    highlightedBooks = highlightedBooks.filter(({inactive}) => !inactive)
                    setHighlightList(highlightedBooks)
                    // console.log("🐌🧲 ~ file: index.jsx ~ line 167 ~ fetchForSuggestionsAndBookHighlights ~ highlightedBooks", highlightedBooks)
                }
            }

        } catch (error) {
            console.log(error, 'error during suggestion fetch')
        }
    }

    const fetchForNewsAndSponsoredProjects = async () => {
        try {
            const response = await api.get(`/articles?_limit=100&_sort=created_at%3Adesc`)

            if (response) {
                if (response.status === 200) {
                    const sponsoredNews = response.data.filter(({ sponsor }) => sponsor)
                    const dorinatecNews = response.data.filter(({ sponsor }) => !sponsor)
                    setNews(dorinatecNews.filter(({inactive}) => !inactive))
                    setProjects(sponsoredNews.filter(({inactive}) => !inactive))
                    return
                }
                throw new Error('Unexpected response')
            }
            throw new Error('Network error')
        } catch (error) {
            console.log(error, 'error while fetching for news and sponsored news')
        }
    }

    useEffect(() => {
        fetchForNewsAndSponsoredProjects()
        fetchForSuggestionsAndBookHighlights()
    }, [])

    const history = useHistory()

    const redirectToTargetBook = (book) => {
        setSelectedBook(book)
        history.push(`/detalhes-da-obra/${book.id}`)
    }

    const redirectToTargetNews = (item) => {

    }
    // console.log(suggestionList.books)
    return (
        <Container>
            <Content>
                <h2 tabIndex={0}>Destaques da Dorinateca</h2>
                <div>
                    {
                        highlightList.map((book, key) => <BookCard
                            action={() => redirectToTargetBook(book)}
                            key={key + 'highlight'}
                            book={book} />)
                    }
                </div>
            </Content>
            <Content>
                <h2 tabIndex={0}>{suggestionList.title}</h2>
                <div>
                    {
                        (suggestionList.books ?? []).map((book, key) => <BookCard
                            action={() => redirectToTargetBook(book)}
                            key={key + 'suggestion'}
                            book={book} />)
                    }
                </div>
            </Content>
            <Content>
                <h2 tabIndex={0}>Notícias</h2>
                <div>
                    {
                        news.slice(0, Math.min(6, news.length)).map((element, key) => <NewsCard
                            action={() => redirectToTargetNews(element)}
                            news={element}
                            key={key + 'news'} />)
                    }
                </div>
            </Content>
            <Content>
                <h2 tabIndex={0}>Projetos</h2>
                <div>
                    {
                        projects.slice(0, Math.min(6, projects.length)).map((element, key) => <NewsCard
                            action={() => redirectToTargetNews(element)}
                            news={element}
                            key={key + 'projects'} />)
                    }
                </div>
            </Content>
        </Container>
    )
}