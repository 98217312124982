import styled from 'styled-components';

export const ImageDivWithFallback = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${(props) => props.theme.textColor}ff;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
  background-size: cover;
  background-position: center;
`;

export const Card2 = styled.article`
  padding: 0;
  cursor: pointer;
  max-width: 100%;
  margin: 0;
  text-align: left;
  display: flex;
  * {
    word-break: keep-all;
  }

  div.categorie {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    > p {
      color: var(--pink);
      font-size: ${(props) => props.theme.fontSize + 12}px;
      font-weight: 700;
      text-transform: uppercase;
    }
    > span {
      font-weight: 400;
      font-size: ${(props) => props.theme.fontSize + 12}px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div.head {
    }
    > div.body {
      display: flex;
      flex-direction: column;
      gap: 5px;

      > h3 {
        font-size: ${(props) => props.theme.fontSize + 20}px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor}aa;
      }
      > p {
        font-size: ${(props) => props.theme.fontSize + 14}px;
        font-weight: 400;
        color: ${(props) => props.theme.textColor}aa;
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      span {
        background-color: var(--pink);
        color: var(--white);
        padding: 5px 10px;
        border-radius: 75px;
        font-size: ${(props) => props.theme.fontSize + 14}px;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 720px) {
    & > div {
      > div.body {
        > h3 {
          font-weight: 700;
          width: 75%;
          word-break: keep-all;
        }
        >h4{
          word-break: keep-all;
        }
      }
    }
  }
`;
