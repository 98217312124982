import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 16px;
  button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.prev {
    font-size: 12px;
    font-weight: 400;
    color: var(--gray);
  }
  button.next {
    font-size: 12px;
    font-weight: 400;
  }
  button.item {
    font-size: 16px;
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  button.active {
    color: var(--white);
    background-color: var(--pink);
  }
  @media screen and (max-width: 720px) {
    width: 90vw;
    gap: 4px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    align-self: center;
    width: 70vw;
    gap: 4px;
    margin-bottom: 16px;
  }
`;
