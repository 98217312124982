import styled from 'styled-components';

export const Container = styled.main`
  padding: 50px 0px;

  width: var(--width-m);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 720px) {
    width: 100vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
  }
`;

export const Content = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  > div {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 720px) {
    margin: 0 16px;
    grid-gap: 20px;
    > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin: 0 16px;
  }
`;

export const Content2 = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  > div {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
  }
  .div-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  button.see-more {
    background-color: var(--pink);
    height: 35px;
    width: 115px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 10px;
    align-self: flex-end;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    > div {
      grid-template-columns: repeat(2, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
      }
    }
    button.see-more {
      margin: 0 64px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin: 0 16px;
    > div {
      grid-template-columns: repeat(3, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
      }
    }
    button.see-more {
      margin: 0 64px;
    }
  }
`;

export const Content3 = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  > div {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin: 0 16px;
    > div {
      grid-template-columns: repeat(2, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin: 0 16px;
    > div {
      grid-template-columns: repeat(3, 1fr);
      width: 90vw;
      article {
        margin-bottom: 16px;
      }
    }
  }
`;

export const Card1 = styled.article`
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 0;
  border-radius: 12px;
  box-shadow: var(--shadow-light);
  a {
    padding: 20px;
    display: flex;
    flex-direction: column;
    > div.head {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      > div.info {
        h3 {
          font-weight: 600;
          font-size: 22px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: ${(props) => props.theme.textColor}dd;
        }
        span {
          color: ${(props) => props.theme.textColor}aa;
          font-size: 12px;
          font-weight: 400;
        }
      }
      > div.photo {
        img {
          width: 94px;
        }
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      span {
        font-size: 12px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor}88;
      }
    }
  }
`;

export const Card2 = styled.article`
  position: relative;
  box-shadow: var(--shadow-light);
  border-radius: 4px;
  > p.position {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: var(--pink);
    width: 46px;
    height: 46px;
    border-radius: 50%;
    color: var(--white);
    font-weight: 700;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
    border-radius: 4px;
  }
  span {
    border-radius: 0px 0px 4px 4px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background-color: ${(props) => props.theme.backgroundColor};
    width: 100%;
    left: 0;
    height: 77px;
    padding: 5px;
    h3 {
      font-size: 15px;
      font-weight: 600;
    }
    p {
      color: ${(props) => props.theme.textColor}aa;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
