import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { GoBack } from '../../components/GoBack';
import { Footer } from '../../components/Footer';
import { Container } from './styles';
import api from '../../services/api';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

export default function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getPrivacyPolicy() {
      await api.get(`/privacy-policy`).then((res) => {
        setPrivacyPolicy(res.data.text);
        setLoading(false);
      });
    }
    return getPrivacyPolicy();
  }, []);
  return (
    <>
      <Header />
      <GoBack
        ariaLabel="Voltar para página anterior"
        title="Política de privacidade"
      />
      <Container aria-label="Política de privacidade" tabIndex={0}>
        {loading ? (
          <div style={{ height: '20vw' }}></div>
        ) : (
          <ReactMarkdown tabIndex={0} rehypePlugins={[rehypeRaw]}>{privacyPolicy}</ReactMarkdown>
        )}
      </Container>
      <Footer />
    </>
  );
}
