import { Button, Container, Image, DisabledButton } from './styles';
import audiobookLogo from '../../assets/favicon-audio-book-2.png';
import brailleLogo from '../../assets/braille-logo.png';
import epubLogo from '../../assets/favicon-epub-2.png';
import daisyLogo from '../../assets/daisy-logo.png';
import { STORAGE_KEYS } from '../../contexts/auth';

function DownloadFormatButtons({ availableFormats, onClick, loading }) {

  const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

  function imageSelector(format) {
    switch (format) {
      case "Audiobook":
        return audiobookLogo
      case "Daisy":
        return daisyLogo
      case "Braille":
        return brailleLogo
      case "ePub":
        return epubLogo
      default:
        console.warn(`Unsupported format: ${format}`);
        return null; // Or a placeholder image
    }
  }

  function altGenerator(format) {
    switch (format) {
      case "Audiobook":
        return "Logo audiobook"
      case "Daisy":
        return "Logo daisy"
      case "Braille":
        return "Logo braille"
      case "ePub":
        return "Logo epub"
      default:
        break;
    }
  }

  function textGenerator(format) {
    switch (format) {
      case "Audiobook":
        return "Áudio Livro"
      case "Daisy":
        return "Livro DAISY"
      case "Braille":
        return "Livro Braille"
      case "ePub":
        return "Livro EPUB3"
      default:
        break;
    }
  }
  return (
    <>
      <Container>
        {availableFormats.map((format, key) => (
          (isLogged ?
            <Button
              onClick={() => onClick(format)}
              key={`${key}-${format}`}
              aria-label={`Baixar ${textGenerator(format)}`}
              disabled={loading}
              style={loading ? { opacity: 0.7 } : null}
            >
              <Image src={imageSelector(format)} alt={altGenerator(format)} />
              {textGenerator(format)}{" "}
            </Button> :
            <DisabledButton
              onClick={onClick}
              key={`${key}-${format}`}
              aria-label={`Baixar ${textGenerator(format)}`}
              style={loading ? { opacity: 0.7 } : null}
            >
              <Image src={imageSelector(format)} alt={altGenerator(format)} />
              {textGenerator(format)}{" "}
            </DisabledButton>)
        ))}

      </Container>
    </>
  );
}

export default DownloadFormatButtons;