import {
    Wrapper,
    Body,
    Container,
    Row,
    RowSummary,
    RowActionButtons,
    RowButtons,
    RowTitle,
    RowSummaryText,
    RowDescription,
    StatusButton,
    InvisibleLabel
} from './styles'
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
import { useContext, useEffect, useState } from 'react'
import { GoBack } from "../../components/GoBack";
import api from '../../services/api';
import AuthContext from '../../contexts/auth';
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw";

export const Notifications = (props) => {
    const [selected, setSelected] = useState(-1)

    const [notifications, setNotifications] = useState([])
    // console.log("🐌🧲 ~ file: index.jsx ~ line 25 ~ Notifications ~ notifications", notifications)

    const { user } = useContext(AuthContext)

    const fetchForUserAlerts = async () => {
        try {
            const response = await api.get(`/alerts-by-users?user.id=${user.id}&_limit=-1`)
            // console.log("🐌🧲 ~ file: index.jsx ~ line 28 ~ fetchForUserAlerts ~ response", response)
            if (response) {
                if (response.status === 200) {
                    setNotifications(response.data)
                    return
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchForUserAlerts()
    }, [])

    const updateUnreadNotification = async (id, deleteNotification) => {
        try {

            let params = {
                read: true
            }
            if (deleteNotification) {
                params.sent = true
            }

            await api.put(`/alerts-by-users/${id}`, params)

            fetchForUserAlerts()

        } catch (error) {
            console.log(error, 'error while updating notification status')
        }
    }

    const handleSelect = (key, id, read) => {
        setSelected(key === selected ? -1 : key)
        if (!read) {
            updateUnreadNotification(id)
        }
    }

    const filteredNotifications = notifications.filter(({ sent }) => !sent)

    return <Wrapper>
        <Header />
        <Body>
            <GoBack title="Notificações" />
            <Container>
                {
                    filteredNotifications.map(({ title, message, read, id }, key) => {
                        const isSelected = key === selected
                        return <Row key={key + 'notif'}  >
                            <StatusButton tabIndex={0}
                            aria-label={`${isSelected ? 'Fechar' : 'Abrir'} notificação: ${title} `}
                            onKeyDown={({ keyCode }) => keyCode === 13 ? handleSelect(key, id, read) : null} onClick={() => handleSelect(key, id, read)}>
                                {!selected ? '-' : '+'}
                            </StatusButton>
                            <RowSummary>
                                <RowSummaryText>
                                    <RowTitle>
                                        <InvisibleLabel>
                                            Título:
                                        </InvisibleLabel>
                                        {title}
                                    </RowTitle>
                                    {
                                        isSelected ?
                                            <RowDescription>
                                                <InvisibleLabel>
                                                    Mensagem:
                                                </InvisibleLabel>
                                                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                                    {message}
                                                </ReactMarkdown>
                                            </RowDescription>
                                            : null
                                    }
                                </RowSummaryText>
                                <RowButtons>
                                    <RowActionButtons tabIndex={1}>
                                        {
                                            read ?
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000">
                                                    <path d="M21.99 8c0-.72-.37-1.35-.94-1.7l-8.04-4.71c-.62-.37-1.4-.37-2.02 0L2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zm-11.05 4.34l-7.2-4.5 7.25-4.25c.62-.37 1.4-.37 2.02 0l7.25 4.25-7.2 4.5c-.65.4-1.47.4-2.12 0z" />
                                                </svg>
                                                :
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    width="24px"
                                                    fill="#000000">
                                                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" />
                                                </svg>
                                        }
                                    </RowActionButtons>
                                    <RowActionButtons
                                        tabIndex={0}
                                        aria-label={`Deletar notificação ${title}`}
                                        onClick={() => updateUnreadNotification(id, true)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#000000">
                                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" />
                                        </svg>
                                    </RowActionButtons>
                                </RowButtons>
                            </RowSummary>
                        </Row>
                    })
                }
            </Container>
        </Body>
        <Footer />
    </Wrapper>
}