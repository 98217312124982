export default class MinLengthValidator {
    constructor(length) {
        this.length = length;
    }

    validate(string) {
        if (string.length < this.length) {
            return `Mínimo de ${this.length} caracteres`;
        }
        return "";
    }
}
