import styled from 'styled-components';

export const CardRow = styled.main`
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
  padding-top: 32px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100vw;
    margin: 0 32px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    flex-direction: column;
    width: 100vw;
    margin: 0 32px;
  }
`;

export const ImagePreview = styled.div``;

export const ImageDivFallback = styled.div`
  width: 200px;
  height: 350px;
  background-color: ${(props) => props.theme.textColor}aa;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin: 0 auto;
  }
`;

export const ImageDivContainer = styled.div``;

export const ImageSelector = styled.div`
  margin-right: 32px;
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    align-items: center;
    & button {
      width: 25%;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin-bottom: 16px;
  }
`;

export const ImageSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`;

export const ImageThumbnailSelector = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.textColor}dd;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  margin: 4px;
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 1px 1px 4px ${(props) => props.theme.textColor}26;
  width: 50vw;
  @media (max-width: 720px) {
    width: 85%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90%;
  }
`;

export const CardHeader = styled.article`
  & h2 {
    margin-bottom: 6px;
    font-size: ${(props) => props.theme.fontSize + 22}px;
  }
`;

export const CardList = styled.div`
  margin-top: 18px;
`;

export const CardSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom: 1px solid ${(props) => props.theme.textColor}44;
`;

export const Label = styled.p`
  font-weight: bold;
  margin-right: 20px;
  min-width: 40%;
  max-width: 40%;
  opacity: 0.7;
`;

export const Description = styled.p``;

export const CardFooter = styled.div`
  & > p {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }
`;

export const CardSummary = styled.div`
  margin-top: 8px;
  & p {
    margin-top: 4px;
  }
`;


export const FooterDownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    text-align: left;
    align-self: flex-start;
    margin-bottom: 8px;
  }
  margin: 16px 0 ;
  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DownloadOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  .loading {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  & button {
    border-radius: 10px;
    background: var(--pink);
    height: 50px;
    width: 100%;
    border: none;
    color: var(--white);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: 42px;
    padding-right: 42px;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    justify-content: space-between;
    & button {
      width: 25%;
    }
  }
`;

export const Button = styled.button`
 border-radius: 10px;
 background: var(--pink);
 height: 50px;
 width: 100%;
 border: none;
 color: var(--white);
 font-size: 16px;
 text-transform: uppercase;
 font-weight: 700;
 padding-left: 42px;
 padding-right: 42px;
 margin: 16px 0;
 transition: 500ms linear;
 box-shadow: 1px 1px 2px ${(props) => props.theme.textColor}26;

 &:hover{ 
  transform: scale(1.01);
 }
`