import styled from 'styled-components';

export const Container = styled.article`
  border-bottom: 1px solid ${(props) => props.theme.textColor}88;
  padding: 10px 0px;
`;

export const Ask = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background: transparent;
  border: none;
  h3 {
    text-align: left;
    font-size: ${(props) => props.theme.fontSize + 16}px;
    font-weight: 700;
  }
`;

export const Answer = styled.div`
  display: none;
  flex-direction: column;
  padding: 10px 30px;

  &.active {
    display: flex;
    color: ${(props) => props.theme.textColor}88;
  }
  a {
    text-decoration: underline !important;
  }
`;
