import { useState } from "react";
import { Input } from "../../../components/Input";
import { Wizard } from "../../../components/Wizard";
import { ModalDefault } from "../../../components/ModalDefault";

import { Container, Line, Empty, Genero, Controllers, Radius } from "./styles";

export function MoreDatas(props) {
    const {
        schooling,
        profession,
        occupation,
        fluencyInBraille,
        screenReader,
        screenMagnifier,
        brailleLine,
        eletronicGlass,
        readingFluency,
        searchingFor,
        goToLibraries,
        which,
        literaryEvents,
        equipament,
        fdnc,
        meet
    } = props
    const howAboutDorinateca = [
        'Amigos',
        'Através de estudantes',
        'Através de um professor',
        'Através de uma biblioteca',
        'Através de uma instituição',
        'Por whatsapp',
        'Notícias',
        'Redes sociais',
        'Site de busca'
    ]

    const howAboutDorinatecaBackend = [
        'friends',
        'students',
        'teachers',
        'libraries',
        'institutions_serving_people_disabilities',
        'whatsapp',
        'media_newspaper_or_tv_or_articles',
        'social_networks',
        'search_site'
    ]

    const [targetField, setTargetField] = useState('schooling')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const filterNonEmptyFields = [
        {
            value: schooling,
            label: 'schooling',
            message: 'O campo escolaridade é obrigatório'
        },
        {
            value: fluencyInBraille,
            label: 'fluencyInBraille',
            message: 'O campo fluência em braille é obrigatório'
        },
        {
            value: meet,
            label: 'meet',
            message: 'O campo como conheceu a Dorinateca é obrigatório'
        }
    ].filter(({ value }) => !value)


    const isFormValid = filterNonEmptyFields.length === 0

    const focusOnLastRequiredField = () => {
        const getFirstInvalidField = filterNonEmptyFields[0]

        setModalError(true)
        setModalErrorMessage(getFirstInvalidField.message)
        setTargetField(getFirstInvalidField.label)

    }
    const closeModalShowLastRequiredField = () => {
        const getFirstInvalidField = filterNonEmptyFields[0]
        if (filterNonEmptyFields.length > 0) {
            setModalError(false)
            if (getFirstInvalidField.label === targetField) {
                setTargetField(' ')
                const timedOutSetField = setTimeout(() =>
                    setTargetField(getFirstInvalidField.label), 256)
                return () => clearTimeout(timedOutSetField)
            }
            setTargetField(getFirstInvalidField.label)
            return
        }

        setModalError(false)
    }

    return (
        <>
            <Wizard step={4} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError}
                onRequestClose={() => closeModalShowLastRequiredField()}>
                <p>{modalErrorMessage}</p>
            </ModalDefault>
            <Container>
                <Line>
                    <Input autofocus={targetField === 'schooling'}
                        targetName={'schooling'}
                        onChange={(v) => props.setSchooling(v)}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        type="select"
                        label="Escolaridade"
                        value={props.schooling}
                        required>
                        <option value="fundamental">Ensino Fundamental</option>
                        <option value="fundamentalIncomplete">Ensino Fundamental Incompleto</option>
                        <option value="highSchool">Ensino Médio</option>
                        <option value="highSchoolIncomplete">Ensino Médio incompleto</option>
                        <option value="Graduated">Ensino Superior</option>
                        <option value="SuperiorIncomplete">Ensino Superior Incompleto</option>
                        <option value="postgraduate">Pós Graduado</option>
                    </Input>
                    <Empty></Empty>
                </Line>
                <Line>
                    <Input autofocus={targetField === 'profession'} targetName={'profession'} value={props.profession} onChange={(v) => props.setProfession(v)} label="Profissão" type="text" />
                    <Input autofocus={targetField === 'occupation'} targetName={'occupation'} value={props.occupation} onChange={(v) => props.setOccupation(v)} label="Ocupação" type="text" />
                </Line>
                <Line>
                    <Input autofocus={targetField === 'fluencyInBraille'}
                        targetName={'fluencyInBraille'}
                        label="Fluencia de leitura em Braille"
                        onChange={(v) => props.setFluencyInBraille(v)} fl={1}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        placeholder="Fluencia em Braille"
                        type="select"
                        value={props.fluencyInBraille}
                        required>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                    </Input>
                    <Radius>
                        <label tabIndex={1}
                            aria-label="Recursos de acessibilidade que utiliza"
                            htmlFor="">Recursos de acessibilidade que utiliza</label>
                        <div>
                            <div >
                                <input tabIndex={1} type="checkbox" aria-label={"Leitor de tela"} name="" id="screenReader" checked={props.screenReader} onClick={(e) => props.setScreenReader(e.currentTarget.checked)} />
                                <label htmlFor="screenReader">Leitor de tela</label>
                            </div>
                            <div>
                                <input tabIndex={1} type="checkbox" aria-label={"Ampliador de tela"} name="" id="screenMagnifier" checked={props.screenMagnifier} onClick={(e) => props.setScreenMagnifier(e.currentTarget.checked)} />
                                <label htmlFor="screenMagnifier">Ampliador de tela</label>
                            </div>
                            <div>
                                <input tabIndex={1} type="checkbox" aria-label={"Linha Braille"} name="" id="brailleLine" checked={props.brailleLine} onClick={(e) => props.setBrailleLine(e.currentTarget.checked)} />
                                <label htmlFor="brailleLine">Linha Braille</label>
                            </div>
                            <div>
                                <input tabIndex={1} type="checkbox" aria-label={"Lupa eletrônica"} name="" id="eletronicGlass" checked={props.eletronicGlass} onClick={(e) => props.setEletronicGlass(e.currentTarget.checked)} />
                                <label htmlFor="eletronicGlass">Lupa eletrônica</label>
                            </div>
                        </div>
                    </Radius>
                </Line>
                <Line>

                </Line>
                <Line>

                    <div style={{
                        width: 400
                    }}>
                        <div style={{
                            marginBottom: 8
                        }}>
                            <label tabIndex={1} htmlFor="">Frequenta outras bibliotecas?</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                marginRight: 22
                            }}>
                                <input tabIndex={1}
                                    aria-label="sim"
                                    checked={goToLibraries}
                                    type="radio"
                                    name="yesev"
                                    style={{
                                        marginRight: 4
                                    }}
                                    value={true}
                                    id="yesev"
                                    onChange={e => props.setGoToLibraries(true)} />
                                <label onClick={e => props.setGoToLibraries(true)} htmlFor="yesev">Sim</label>
                            </div>
                            <div>
                                <input tabIndex={1}
                                    checked={!goToLibraries}
                                    style={{
                                        marginRight: 4
                                    }}
                                    aria-label="não" type="radio" name="noev" id="no" value={false}
                                    onChange={e => props.setGoToLibraries(false)} />
                                <label onClick={e => props.setGoToLibraries(false)} htmlFor="noev">Não</label>
                            </div>
                        </div>
                    </div>
                    {
                        props.goToLibraries ?
                            <Input value={props.which}
                                onChange={(v) => props.setWhich(v)} ariaLabel="Quais bibliotecas utiliza?" label="Quais?"></Input>
                            : <Empty />
                    }
                    {/* <Input value={props.which} onChange={(v) => props.setWhich(v)} label="Quais?"></Input> */}
                </Line>
                <Line>
                    {/* <div className="check3">
                        <input type="checkbox" aria-label="Gostaria de participar de eventos literários?" checked={props.literaryEvents} onClick={(e) => props.setLiteraryEvents(e.currentTarget.checked)} />
                        <label htmlFor="">Gostaria de participar de eventos literários?</label>
                       
                    </div> */}
                    <div>
                        <div style={{
                            marginBottom: 8
                        }}>
                            <label tabIndex={1} htmlFor="">Gostaria de participar de eventos literários</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                marginRight: 22
                            }}>
                                <input tabIndex={1}
                                    aria-label="sim"
                                    checked={literaryEvents}
                                    type="radio"
                                    name="yes"
                                    style={{
                                        marginRight: 4
                                    }}
                                    value={true}
                                    id="yes"
                                    onChange={e => props.setLiteraryEvents(true)} />
                                <label onClick={e => props.setLiteraryEvents(true)} htmlFor="yes">Sim</label>
                            </div>
                            <div>
                                <input tabIndex={1}
                                    checked={!literaryEvents}
                                    style={{
                                        marginRight: 4
                                    }}
                                    aria-label="não" type="radio" name="no" id="no" value={false}
                                    onChange={e => props.setLiteraryEvents(false)} />
                                <label onClick={e => props.setLiteraryEvents(false)} htmlFor="no">Não</label>
                            </div>
                        </div>
                    </div>
                </Line>
                <Line>
                    {/* <Input value={props.equipament} onChange={(v) => props.setEquipament(v)} type="select" label="Que tipo de equipamento utiliza">
                    </Input>
                    <Input value={props.fdnc} onChange={(v) => props.setFdnc(v)} type="select" label="Por quais serviços da FDNC se interessa?">
                    </Input> */}
                </Line>
                <Line>
                    <Input autofocus={targetField === 'meet'} value={props.meet} onChange={(v) => props.setMeet(v)}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }} type="select" label="Como conheceu a Dorinateca" required>
                        {
                            howAboutDorinateca.map((opt, key) => <option
                                value={howAboutDorinatecaBackend[key]}
                                key={key + 'opt-about'}>
                                {opt}
                            </option>)
                        }
                    </Input>
                </Line>
            </Container>
            <Controllers>
                <button tabIndex={1} onClick={() => { props.changeStep(3) }}>Anterior</button>
                <button
                    style={{
                        opacity: isFormValid ? 1 : .5
                    }}
                    tabIndex={1}
                    onClick={() => isFormValid ? props.changeStep(5) : focusOnLastRequiredField()}>Avançar</button>
            </Controllers>
        </>
    )
}