import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme2 from '../../assets/2-themes.jpg';
import theme4 from '../../assets/4-themes.jpg';
import abcLogo from '../../assets/abc-logo.jpg';
import theme1 from '../../assets/books-themes.jpg';
import { Footer } from '../../components/Footer';
import { GoBack } from '../../components/GoBack';
import { Header } from '../../components/Header';
import { MainCollection } from '../../components/MainCollection';
import { SearchContainer } from '../../components/SearchContainer';
import AuthContext from '../../contexts/auth';
import { SearchInputWrapper, Themes } from './styles';

export function Collection(props) {
    const history = useHistory()
    const {location} = props
    const params = location.search

    const [contentFilter, setContentFilter] = useState('')

    const { setInjectSearch, stageCollection, setStageCollection, themeLetterSpacing, themeFontFamily  } = useContext(AuthContext)

    const options = [
        {
            label: 'Livros',
            filter: ['Livros'],
            image: theme1,
            ariaLabel: 'Imagem de um livro aberto'
        },
        {
            label: 'Livro falado',
            filter: ['Audios'],
            image: theme4,
            ariaLabel: 'Imagem de um fone de ouvido'
        },
        {
            label: 'Jornais e revistas',
            filter: ['Revistas', 'Jornais'],
            image: theme2,
            ariaLabel: 'Imagem de varias Revistas e Jornais em uma prateleira'
        },
        {
            label: 'Acervo Internacional',
            filter: '',
            image: abcLogo,
            ariaLabel: 'Logo do consorcio ABC'
        },
    ]

    const handleShowSearch = (label) => {
        setContentFilter(label)
        setStageCollection(true)
    }
    const handleRedirectToInternational = () => {
        history.push('/noticias/ler/5')
    }
    useEffect(() => {
        if(params) {
            if(params.length > 0 && params.split("=").length > 0) {
                const pos = parseInt(params.split("=")[1], 10)
                if(!Number.isNaN(pos)) {
                    handleShowSearch(options[pos - 1].label)
                }
            }
        }
    }, [params])

    const [search, setSearch] = useState('')
    const handleRedirect = () => {
        setInjectSearch(search)
        history.push("/buscar")
    }
    return (
        <>
            <Header menu={1} />
            <GoBack title="Acervo" onClick={stageCollection ? () => setStageCollection(false) : undefined} 
            />
            {
                !stageCollection ?
                    <>
                        <SearchInputWrapper>
                            <div className="search">
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text" placeholder="Pesquise no acervo da Dorinateca" />
                                <button onClick={handleRedirect}>Buscar</button>
                            </div>
                        </SearchInputWrapper>
                        <Themes>
                            {
                                options.map(({ image, label, filter, ariaLabel }, key) => <div
                                    onClick={() => {
                                        if (label === 'Acervo Internacional'){
                                            handleRedirectToInternational()
                                        }else {
                                            handleShowSearch(filter)
                                        }
                                    }}
                                    key={key + label}
                                    aria-label={ariaLabel}
                                    >
                                    <img 
                                        tabIndex={0} 
                                        aria-label={`Abrir busca de obras com a categoria ${label}`} 
                                        src={image} 
                                        alt="" 
                                        width={284} height={161} 
                                    />
                                    <p style={ themeLetterSpacing === 3 && themeFontFamily !== 1 ? {
                                        height: 88,
                                        display: 'flex',
                                        alignItems: 'center'
                                        }: null }>{label}</p>
                                </div>)
                            }

                        </Themes>
                        <MainCollection />
                    </>
                    :
                    null
            }

            {
                stageCollection ?
                    <SearchContainer contentFilter={contentFilter} />
                    : null
            }
            <Footer />
        </>
    )
}