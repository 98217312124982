import styled from "styled-components";

export const ContainerFileModal = styled.div`
  height: 90vw !important;
  max-width: 90vw;
  overflow-y: scroll;
  word-break: keep-all;
  @media screen and (max-width: 720px) {
    height: 100vw !important;
  }
  @media screen and (max-width: 1200px) and (min-width: 721px) {
    height: 70vw !important;
  }
`;