export default class Validator {
    constructor(validationArray) {
        this.validation = validationArray;
    }

    validateString(string) {
        //here I iterate between all validators and retrieve any error message
        //then I apply a filter where I search for validation errors
        const errorMessage = this.validation.map((validator) => validator.validate(string)).filter(item => item.length > 0);
        //if array length is bigger than 0, validation error
        return {
            error: errorMessage.length > 0,
            message: errorMessage
        }
    }
}