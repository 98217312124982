import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  h2 {
    margin: 10px 0;
  }
  p {
    margin: 4px 0;
  }
  a {
    text-decoration: underline !important;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    padding: 0 32px;
    margin: 0;
  }
  @media screen and (max-width: 1200px) and (min-width: 721px) {
    width: 100%;
    padding: 0 32px;
    margin: 0;
  }
`;
