import moment from "moment"

const insertOptionalZero = (str) => `0${str}`.slice(-2)

const insertRightOptionalZero = (str) => `${str}0`.slice(0, 2)

export const formatDateString = (dateString) => {
    const momentDate = moment(dateString)
    return `${insertOptionalZero(momentDate.date())}/${insertOptionalZero(momentDate.month() + 1)}/${momentDate.year()}`
}

export const formatCurrencyString = (string) => {
    const splittedValues = string.split('.')

    const pureValue = splittedValues[0]
    const formattedValue = pureValue.length > 3 ? `${pureValue.slice(0, pureValue.length - 3)}.${pureValue.slice(pureValue.length - 3, pureValue.length)}` : pureValue

    return `R$ ${formattedValue},${splittedValues[1] ? insertRightOptionalZero(splittedValues[1]) : '00'}`
}

export const formatCurrencyInput = (string) => {
    return `R$ ${string}`
}

export const formatNameTitleString = (str) => {
    //regex to detect first characters or the first characters after a space
    const regex = new RegExp(/( [a-zA-Z]|^[a-zA-Z])/, 'g')
    return `${str}`.toLowerCase().replace(regex, (match) => `${match}`.toUpperCase())
}

export const maskDocumentInput = (string, type) => {
    let scopedText = string;
    if (type === "cnpj") {
        if (string.length > 12) {
            scopedText = scopedText.slice(0, 12) + "-" + scopedText.slice(12, Math.min(string.length, 14));
        }

        if (string.length > 8) {
            scopedText = scopedText.slice(0, 8) + "/" + scopedText.slice(8, scopedText.length);
        }

        if (string.length > 5) {
            scopedText = scopedText.slice(0, 5) + "." + scopedText.slice(5, scopedText.length);
        }

        if (string.length > 2) {
            scopedText = scopedText.slice(0, 2) + "." + scopedText.slice(2, scopedText.length);
        }
    }

    if (type === "cpf") {

        if (string.length > 9) {
            scopedText = scopedText.slice(0, 9) + "-" + scopedText.slice(9, Math.min(string.length, 11));
        }

        if (scopedText.length > 6) {
            scopedText = scopedText.slice(0, 6) + "." + scopedText.slice(6, scopedText.length);
        }

        if (scopedText.length > 3) {
            scopedText = scopedText.slice(0, 3) + "." + scopedText.slice(3, scopedText.length);
        }

    }

    return scopedText;
}


export const maskPhoneInput = (string) => {
    const replacedText = string.replace(/^55/, "")

    let scopedText = (string.length > 11 ? replacedText : replacedText).slice(0, Math.min(11, string.length))

    if (scopedText.length > 6 && scopedText.length <= 10) {
        scopedText = `${scopedText.slice(0, 6)}-${scopedText.slice(6, scopedText.length + 1)}`
    } else {
        if (scopedText.length === 11) {
            scopedText = `${scopedText.slice(0, 7)}-${scopedText.slice(7, scopedText.length + 1)}`
        }
    }

    if (scopedText.length > 2) {
        scopedText = `(${scopedText.slice(0, 2)}) ${scopedText.slice(2, scopedText.length + 1)}`
    }

    return scopedText
}


export const maskCepInput = (string) => {
    const limitedCEP = string.slice(0, Math.min(8, string.length))
    if (limitedCEP.length > 5) {
        return `${limitedCEP.slice(0, 5)}-${limitedCEP.slice(5, Math.min(9, limitedCEP.length))}`
    } else {
        return limitedCEP
    }
}

export const maskTimeInput = (string) => {
    let targetString = string.slice(0, Math.min(4, string.length))

    if (targetString.length > 2) {

        const getFirstSlice = targetString.slice(0, 2)

        let parsedSlice = getFirstSlice

        if (parseInt(getFirstSlice) > 23) {
            parsedSlice = 23
        }

        const getSecondSlice = targetString.slice(2, targetString.length)
        let parsedSecondSlice = getSecondSlice

        if (parseInt(getSecondSlice) > 60) {
            parsedSecondSlice = 59
        }
        return `${parsedSlice}:${parsedSecondSlice}`
    }
    return targetString
}