import styled from 'styled-components';

export const Container = styled.main`
  padding: 50px 0px;

  width: var(--width-m);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    margin: 0 18px;
  }
`;

export const Content = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize + 32}px;
  }
  > div {
    display: grid;
    grid-gap: 20px;
    align-items: start;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 320px) {
    > div {
      margin: 0 auto;
      grid-gap: 10px;
      padding: 0 10px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      article {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 600px) {
    > div {
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      article {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 1025px) {
    > div {
      margin: 0 auto;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      article {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
`;

export const Card1 = styled.article`
  background-color: var(--white);
  padding: 0;
  border-radius: 12px;
  box-shadow: var(--shadow-light);
  a {
    padding: 20px;
    display: flex;
    flex-direction: column;
    > div.head {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      > div.info {
        h3 {
          font-weight: 600;
          font-size: ${(props) => props.theme.fontSize + 22}px;
        }
        p {
          font-size: ${(props) => props.theme.fontSize + 16}px;
          font-weight: 400;
          color: ${(props) => props.theme.textColor}dd;
        }
        span {
          color: ${(props) => props.theme.textColor}aa;
          font-size: ${(props) => props.theme.fontSize + 16}px;
          font-weight: 400;
        }
      }
      > div.photo {
        img {
          width: 94px;
        }
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      span {
        font-size: ${(props) => props.theme.fontSize + 12}px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor}88;
      }
    }
  }
`;

export const Card2 = styled.article`
  margin-bottom: 20px;
  padding: 0;
  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div.head {
      img {
        width: 100%;
      }
    }
    > div.body {
      display: flex;
      flex-direction: column;
      gap: 5px;
      > div.categorie {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          color: var(--pink);
          font-size: ${(props) => props.theme.fontSize + 12}px;
          font-weight: 700;
          text-transform: uppercase;
        }
        > span {
          font-weight: 400;
          font-size: ${(props) => props.theme.fontSize + 12}px;
        }
      }
      > h3 {
        font-size: ${(props) => props.theme.fontSize + 20}px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor}dd;
      }
      > p {
        font-size: ${(props) => props.theme.fontSize + 14}px;
        font-weight: 400;
        color: ${(props) => props.theme.textColor}aa;
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      span {
        background-color: var(--pink);
        color: var(--white);
        padding: 5px 10px;
        border-radius: 75px;
        font-size: ${(props) => props.theme.fontSize + 14}px;
        font-weight: 700;
      }
    }
  }
`;
