import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 50px;
  width: var(--width-p);
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 720px) {
    width: 100vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  > div.check {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    input {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 720px) {
    overflow: scroll;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    overflow: scroll;
  }
`;

export const Empty = styled.div`
  width: 100%;
`;

export const Genero = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
  }
  > div:last-child {
    display: flex;
    gap: 20px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      input {
      }
      label {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
`;

export const Controllers = styled.div`
  width: var(--width-p);
  /* background: red; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;

  > button:first-child {
    width: 178px;
    height: 50px;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #707070;
  }
  > button:last-child {
    width: 178px;
    height: 50px;
    background: var(--pink);
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    padding: 0 32px;
    > button:first-child {
      width: 140px;
    }
    > button:last-child {
      width: 140px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    padding: 0 auto;
    > button:first-child {
      width: 150px;
    }
    > button:last-child {
      width: 150px;
    }
  }
`;
