import styled from "styled-components";

export const ImagePlaceholder = styled.div`
    width: 100%;
    height: 120px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 4px;
    background-color: ${props => props.theme.textColor};
    ${props => props.bgImage ? `background-image: url(${props.bgImage});` : ''}
`

export const Card2 = styled.article`
    position: relative;
    box-shadow: 1px 2px 4px ${props => props.theme.textColor}26;
    border-radius: 4px;
    cursor: pointer;
    max-width: ${props => (props.theme.fontSize * 4) + 180}px;
    background-color: ${props => props.theme.backgroundColor};
    >p.position{
        position: absolute;
        top: -15px;
        right: -15px;
        background-color: var(--pink);
        width: 46px;
        height: 46px;
        border-radius: 50%;
        color: var(--white);
        font-weight: 700;
        font-size: ${props => props.theme.textSize + 22}px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img{
        width: 100%;
        border-radius: 4px;
    }
    > div:last-child {
        border-radius: 0px 0px 4px 4px;
        bottom: 0;
        z-index: 1;
        background-color: ${props => props.theme.backgroundColor};
        flex-grow: 1;
        padding: 5px;
        h3{
            font-size: ${props => props.theme.textSize + 15}px;
            font-weight: 600;
        }
        p{
            color: ${props => props.theme.textColor}aa;
            font-size: ${props => props.theme.textSize + 14}px;
            font-weight: 400;
        }
    }
`;


export const InvisibleLabel = styled.label`
    opacity: 0;
    font-size: 0px;
    width: 0px;
    position: absolute;
`