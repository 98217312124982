import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { ModalDefault } from "../../components/ModalDefault";
import { FormConstructor } from "../../components/form-cosntructor/form-constructor";
import AuthContext from "../../contexts/auth";
import { maskCepInput, maskDocumentInput, maskPhoneInput } from "../../data/formatting/document-formats/document-formats";
import { NUMBER_ONLY } from "../../data/regex";
import api from "../../services/api";
import { Container } from "./styles";

import axios from "axios";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ValidationBuilder from '../../data/validation/builder';

const emailValidator = ValidationBuilder("email")

const cnpjValidation = ValidationBuilder("cnpj")

export function RegisterEnterprise() {
    const [step, setStep] = useState(1);
    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('');
    const [modalUseTerms, setModalUseTerms] = useState(false); 

    const [modalPrivacyPolice, setModalPrivacyPolice] = useState(false);
    const [modalUseTermsMessage, setModalUseTermsMessage] = useState('')
    const [modalPrivacyPoliceMessage, setModalPrivacyPoliceMessage] = useState('') 
    useEffect(()=>{
        async function getPrivacyPolicy(){
            await api.get(`/privacy-policy`).then(res => {
                setModalPrivacyPoliceMessage(res.data.text)
            })
        }
        return getPrivacyPolicy()
    }, [])

    useEffect(()=>{
        async function getUseTerm(){
            api.get(`/terms-use`).then(res => {
                setModalUseTermsMessage(res.data.text)
            })
        }
        return getUseTerm()
    }, [])

    const [name, setName] = useState('');
    const [cnpj, setCnpj] = useState('');

    const [surname, setSurname] = useState('');

    const [responsibleName, setResponsibleName] = useState('');

    const [institutionType, setInstitutionType] = useState('');

    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const [district, setDistrict] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');

    const [occupation, setOccupation] = useState('');

    const [profileImage, setProfileImage] = useState({});

    const { handleRegisterEnterprise } = useContext(AuthContext);
    const history = useHistory();

    const [cities, setCities] = useState([]);
    const [drowdownCities, setDropdownCities] = useState([]);
    
    const [attendendPublic, setAttendedPublic] = useState([]);

    const fetchForSelectedStateCities = async () => {
        try {
            //here we retrieve the list of cities from IBGE API
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
            if (response.status === 200) {
                const cities = Array.from(response.data || []).map(({ nome }) => nome)
                setCities(cities);
            }

        } catch (error) {
            console.log(error, 'error');
        }
    }
    useEffect(() => {
        if (state.length === 2) {
            setDropdownCities([])
            setCity('')
            fetchForSelectedStateCities()
        }
    }, [state]);

    function handleCityInput (input) {
        setCity(input)
        if (cities.length > 0) {
            const variableToSearch = input.toLocaleLowerCase();
            const citiesFiltered = cities.filter(element => {
                return element
                    .toLocaleLowerCase()
                    .includes(variableToSearch)
            });

            setDropdownCities(citiesFiltered)
        }
    }

    function handleCities (selected) {
        if (cities.length > 0) {
            setDropdownCities([]);

            const citiesFiltered = cities.filter(element => element === selected);
            setCity(citiesFiltered);
        }
    }

    const firstStageForm = [
        {
            value: name,
            label: 'Nome da instituição',
            formLabel: 'name',
            onChange: setName,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Nome da instituição',
            required: 'required'
        },
        {
            value: cnpj,
            label: 'CNPJ',
            formLabel: 'cnpj',
            onChange: setCnpj,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskDocumentInput(value, 'cnpj'),
            type: 'text',
            placeholder: 'CNPJ',
            validation: (value) => !cnpjValidation.validateString(value.replace(NUMBER_ONLY, "")).error,
            required: 'required',
            errorMessage: cnpj.length > 0 ?
                cnpjValidation.validateString(cnpj.replace(NUMBER_ONLY, "")).error ? 'CNPJ inválido'
                    : '' : ''
        },
        {
            value: surname,
            label: 'Razão social do CNPJ informado',
            formLabel: 'socialName',
            onChange: setSurname,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Insira a razão social',
            fl: 4,
            required: 'required'
        },
        {
            value: cep,
            label: 'CEP',
            formLabel: {
                name: 'address',
                entry: 'zipcode'
            },
            onChange: setCep,
            onBlur: (e) => {
                let cep = e.target.value;

                if (!cep) {
                    e.target.classList.add('error')
                } else {
                    e.target.classList.remove('error')

                    fetch(`https://viacep.com.br/ws/${cep}/json/`)
                        .then(response => {
                            response.json()
                            .then(res => {
                                if (res.erro) {
                                    setModalError(true);
                                    setModalErrorMessage('CEP invalido!');

                                    setCep('');
                                    setAddress('');
                                    setDistrict('');
                                    setState('');
                                    setCity('');

                                    return null;
                                }

                                setAddress(res.logradouro);
                                setDistrict(res.bairro);
                                setState(res.uf);
                                setCity(res.localidade);
                            });
                        })
                        .catch(() => {
                            setModalError(true);
                            setModalErrorMessage('CEP invalido!');

                            setCep('');
                            setAddress('');
                            setDistrict('');
                            setState('');
                            setCity('');

                            return null;
                        })
                }
            },
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskCepInput(value),
            type: 'text',
            placeholder: 'Insira o CEP',
            fl: 1,
            required: 'required'
        },
        {
            value: address,
            label: 'Endereço',
            formLabel: {
                name: 'address',
                entry: 'address'
            },
            onChange: setAddress,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Insira o endereço',
            fl: 4,
            required: 'required'
        },
        {
            value: number,
            label: 'Número',
            formLabel: {
                name: 'address',
                entry: 'number'
            },
            onChange: setNumber,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Insira o número',
            fl: 1,
            required: 'required'
        },
        {
            value: complement,
            label: 'Complemento',
            formLabel: {
                name: 'address',
                entry: 'complement'
            },
            onChange: setComplement,
            type: 'text',
            placeholder: 'Insira o complemento',
            optional: true
        },
        {
            value: district,
            label: 'Bairro',
            formLabel: {
                name: 'address',
                entry: 'district'
            },
            onChange: setDistrict,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Insira o bairro',
            required: 'required'
        },
        {
            value: state,
            label: 'Estado',
            formLabel: {
                name: 'address',
                entry: 'uf'
            },
            onChange: setState,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'select',
            placeholder: 'Insira o estado',
            required: 'required'
        },
        {
            value: city,
            label: 'Cidade',
            formLabel: {
                name: 'address',
                entry: 'city'
            },
            onChange: handleCityInput,
            onSelectChange: (e) => setCity(e),
            onBlur: (e) => setDropdownCities([]),
            type: 'autocomplete',
            drowpdownValues: drowdownCities,
            onSelect: (e) => handleCities(e),
            placeholder: 'Insira a cidade',
            options: cities,
            required: 'required'
        },
        {
            value: primaryPhone,
            label: 'Telefone principal',
            formLabel: 'mainPhone',
            onChange: setPrimaryPhone,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: 'Informe o número com código de área',
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskPhoneInput(value),
            required: 'required'
        },
        {
            value: secondaryPhone,
            label: 'Telefone alternativo',
            formLabel: 'phone',
            onChange: setSecondaryPhone,
            type: 'text',
            optional: true,
            placeholder: 'Informe o número com código de área',
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            mask: (value) => maskPhoneInput(value),
        },
        {
            value: email,
            label: 'E-mail para contato',
            formLabel: 'email',
            onChange: setEmail,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'text',
            placeholder: ' ',
            validation: (value) => !emailValidator.validateString(value).error,
            required: 'required'
        },
        {
            value: email2,
            label: 'E-mail alternativo para contato',
            formLabel: 'alternativeEmail',
            onChange: setEmail2,
            type: 'text',
            placeholder: ' ',
            validation: (value) => !emailValidator.validateString(value).error
        },
        {
            value: responsibleName,
            label: 'Nome do responsável pelo cadastro',
            formLabel: 'responsibleRegistration',
            onChange: setResponsibleName,
            type: 'text',
            placeholder: 'Nome do responsável'
        },
        {
            value: occupation,
            label: 'Cargo na instituição',
            formLabel: 'occupation',
            onChange: setOccupation,
            type: 'text',
            placeholder: ' '
        },
        {
            value: institutionType,
            label: 'Tipo de instituição',
            formLabel: 'typeInstitution',
            onChange: setInstitutionType,
            type: 'select',
            placeholder: ' ',
            options: [
                {
                    label: 'Escola regular',
                    value: 'regular_school'
                },
                {
                    label: 'Escola técnica',
                    value: 'technical_school'
                },
                {
                    label: 'Universidade',
                    value: 'university'
                },
                {
                    label: 'Biblioteca pública',
                    value: 'public_library'
                },
                {
                    label: 'Organização social',
                    value: 'social_organization'
                },
                {
                    label: 'Centro especializado em pessoas com deficiência',
                    value: 'specialized_service_center_people_disabilities'
                },
                {
                    label: 'Instituto de Esporte, Lazer e Cultura',
                    value: 'institution_culture_leisure_sport'
                },
                {
                    label: 'Instituição de saúde',
                    value: 'health_institution'
                },
                {
                    label: 'Outro',
                    value: 'others'
                }
            ]
        },
        {
            value: profileImage,
            label: 'Foto de perfil',
            formLabel: 'avatar',
            image: true,
            onChange: setProfileImage,
            type: 'upload',
            placeholder: ' ',
        }
    ]


    const passwordForm = [
        {
            value: password,
            label: 'Senha de acesso',
            formLabel: 'password',
            onChange: setPassword,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'password',
            placeholder: ' ',
            validation: (value) => value.length > 7,
            errorMessage: password.length > 0 ?
            password.length < 7 ? "O campo senha precisa de 8 caracteres ou mais" 
            : ""
            : "",
            required: 'required'
        },
        {
            value: password2,
            label: 'Confirme a senha de acesso',
            formLabel: 'password',
            onChange: setPassword2,
            onBlur: (e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error') },
            type: 'password',
            placeholder: ' ',
            validation: (value) => value === password,
            errorMessage: password2.length > 0 ? password !== password2 ? "As senhas não coincidem"
            : "" : "",
            required: 'required'
        }
    ]

    const [receiveMailBox, setReceiveMailBox] = useState(false)

    const [receiveDownload, setReceiveDownload] = useState(false)

    const [workGroup, setWorkGroup] = useState(false)

    const [targetPublic, setTargetPublic] = useState('')

    const [ageGroup, setAgeGroup] = useState('')

    const [kidsQuantity, setKidsQuantity] = useState('')

    const [teenQuantity, setTeenQuantity] = useState('')

    const [youngAdoutQuantity, setYoungAdultQuantity] = useState('')

    const [adultQuantity, setAdultQuantity] = useState('')


    const [useBraille, setUseBraille] = useState(false)
    const [useAudio, setUseAudio] = useState(false)
    const [useEnlarged, setUseEnlarged] = useState(false)
    const [useBrailleInks, setUseBrailleInks] = useState(false)
    const [useDaisy, setUseDaisy] = useState(false)
    const [useEpub, setUseEpub] = useState(false)

    const aboutCollectionForm = [
        {
            type: 'multiple-checkbox',
            label: 'Sobre o recebimento de livros',
            items: [
                {
                    label: 'Receber pelos correios',
                    value: receiveMailBox,
                    onChange: setReceiveMailBox,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'receiveBooksOnMailBox'
                    }
                },
                {
                    label: 'Fazer download de livros',
                    value: receiveDownload,
                    onChange: setReceiveDownload,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'receiveBooksViaDownload'
                    }
                }
            ]
        },
        {
            type: 'radio',
            label: 'Faz parte de algum grupo de trabalho da Rede de Leitura Inclusiva',
            value: workGroup,
            onChange: setWorkGroup
        },
        {
            type: 'none'
        },
        {
            label: 'Público atendido com deficiência',
            type: 'select',
            value: targetPublic,
            onChange: setTargetPublic,
            formLabel: 'publicServedDisabilities',
            options: [
                {
                    label: 'Baixa visão',
                    value: 'visual_low_vision'
                },
                {
                    label: 'Cegueira',
                    value: 'visual_blind'
                },
                {
                    label: 'Surdo-cegueira',
                    value: 'deafblindness'
                },
                {
                    label: 'Deficiência física',
                    value: 'physics'
                },
                {
                    label: 'Deficiência intelectual',
                    value: 'intellectual'
                },
                {
                    label: 'Deficiência auditiva',
                    value: 'hearing_or_deafness'
                },
                {
                    label: 'Múltiplas deficiências',
                    value: 'multiple'
                },
                {
                    label: 'Não atendo público com deficiência',
                    value: 'i_don_t_serve_disabilities'
                }
            ]
        },
        {
            label: 'Faixa etária para recebimento dos livros',
            // type: 'select',
            // value: ageGroup,
            // onChange: setAgeGroup,
            type: 'list-checkbox',
            value: attendendPublic.map((item) => ({name: item})),
            formLabel: 'publicByAge',
            items: [
                {
                    value: attendendPublic.indexOf('Até 11 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('Até 11 anos') > -1 ?
                    prevState.filter((item) => item !== 'Até 11 anos') : 
                    prevState.concat(['Até 11 anos'])),
                    label: 'Até 11 anos'
                },
                {
                    value: attendendPublic.indexOf('De 12 a 17 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('De 12 a 17 anos') > -1 ?
                    prevState.filter((item) => item !== 'De 12 a 17 anos') : 
                    prevState.concat(['De 12 a 17 anos'])),
                    label: 'De 12 a 17 anos'
                },
                {
                    value: attendendPublic.indexOf('De 18 a 28 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('De 18 a 28 anos') > -1 ?
                    prevState.filter((item) => item !== 'De 18 a 28 anos') : 
                    prevState.concat(['De 18 a 28 anos'])),
                    label: 'De 18 a 28 anos'
                },
                {
                    value: attendendPublic.indexOf('Acima de 29 anos') > -1,
                    onChange: () => setAttendedPublic(
                        (prevState) => prevState.indexOf('Acima de 29 anos') > -1 ?
                    prevState.filter((item) => item !== 'Acima de 29 anos') : 
                    prevState.concat(['Acima de 29 anos'])),
                    label: 'Acima de 29 anos'
                },
                // {
                //     label: 'Até 11 anos',
                //     value: 'child_0_to_11_years'
                // },
                // {
                //     label: 'De 12 a 17 anos',
                //     value: 'adolescent_12_to_17_years'
                // },
                // {
                //     label: 'De 18 a 28 anos',
                //     value: 'young_18_to_28_years'
                // },
                // {
                //     label: 'Acima de 29 anos',
                //     value: 'adult_over_29_years'
                // }
            ]
        },
        {
            type: 'none',
        },
        {
            type: 'text-list',
            label: 'Quantidade de pessoas com deficiência atendidas por faixa etária',
            filter: (value) => value.replace(NUMBER_ONLY, ""),
            optional: true,
            items: [
                {
                    label: 'Criança (0 a 11 anos e 11 meses)',
                    value: kidsQuantity,
                    onChange: setKidsQuantity,
                    formLabel: 'disabledPeopleQtyKid'
                },
                {
                    label: 'Adolescente (12 anos e 27 anos e 11 meses)',
                    value: teenQuantity,
                    onChange: setTeenQuantity,
                    formLabel: 'disabledPeopleQtyAdolescent'
                },
                {
                    label: 'Jovem (18 anos e 28 anos e 11 meses)',
                    value: youngAdoutQuantity,
                    onChange: setYoungAdultQuantity,
                    formLabel: 'disabledPeopleQtyYoung'
                },
                {
                    label: 'Adulto (acima de 29 anos)',
                    value: adultQuantity,
                    onChange: setAdultQuantity,
                    formLabel: 'disabledPeopleQtyAdult'
                }
            ]
        },
        {
            type: 'list-checkbox',
            label: 'Formatos de livros acessíveis que deseja receber',
            optional: true,
            items: [
                {
                    value: useBraille,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useBraille'
                    },
                    onChange: setUseBraille,
                    label: 'Braille'
                },
                {
                    value: useAudio,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useAudiobooks'
                    },
                    onChange: setUseAudio,
                    label: 'Audiolivro'
                },
                {
                    value: useEnlarged,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useEnlargedFonts'
                    },
                    onChange: setUseEnlarged,
                    label: 'Fonte larga'
                },
                {
                    value: useBrailleInks,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useBrailleInks'
                    },
                    onChange: setUseBrailleInks,
                    label: 'Tinta braille'
                },
                {
                    value: useDaisy,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useDaisy'
                    },
                    onChange: setUseDaisy,
                    label: 'Daisy'
                },
                {
                    value: useEpub,
                    formLabel: {
                        name: 'additionlInformation',
                        entry: 'useEpub3'
                    },
                    onChange: setUseEpub,
                    label: 'ePub3'
                }
            ]
        }
    ]

    const [aboutFoundation, setAboutFoundation] = useState('')

    const [suggestions, setSuggestions] = useState('')

    const [shareCollection, setShareCollection] = useState(false)

    const [acceptPolicy, setAcceptPolicy] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    function BtnTerms () {
        return (
            <label htmlFor="">Li e estou de acordo com os <button onClick={() => {setModalUseTerms(true)}} style={{
                background: 'transparent',
                border: 'none',
                color: '#001DFF',
                textDecoration: 'underline'
            }}>Termos de Uso</button> do serviço</label>
        )
    }

    function BtnPrivacyPolicy () {
        return (
            <label htmlFor="">Li e estou de acordo com a <button onClick={() => {setModalPrivacyPolice(true)}}  style={{
                background: 'transparent',
                border: 'none',
                color: '#001DFF',
                textDecoration: 'underline'
            }}>Política de Privacidade</button> do serviço</label>
        )
    }
    const finalStepForm = [
        {
            type: 'select',
            label: 'Como soube sobre Fundação Dorina?',
            value: aboutFoundation,
            onChange: setAboutFoundation,
            formLabel: 'howAboutDorinateca',
            options: [
                {
                    label: 'Através de um amigo',
                    value: 'friends'
                },
                {
                    label: 'Através de um estudante',
                    value: 'students'
                },
                {
                    label: 'Através de um professor',
                    value: 'teachers'
                },
                {
                    label: 'Através de uma biblioteca',
                    value: 'libraries'
                },
                {
                    label: 'Através de uma instituição para pessoas com deficiência',
                    value: 'institutions_serving_people_disabilities'
                },
                {
                    label: 'Através do whatsapp',
                    value: 'whatsapp'
                },
                {
                    label: 'Através da mídia (notícia ou TV)',
                    value: 'media_newspaper_or_tv_or_articles'
                },
                {
                    label: 'Através das redes sociais',
                    value: 'social_networks'
                },
                {
                    label: 'Através de site de pesquisa',
                    value: 'search_site'
                },
            ]
        },
        {
            type: 'textarea',
            label: 'Deixe suas sugestões, comentários e solicitações',
            value: suggestions,
            onChange: setSuggestions,
            formLabel: {
                name: 'additionlInformation',
                entry: 'suggestion'
            }
        },
        {
            type: 'radio',
            label: 'Para que as pessoas saibam do seu acervo acessível é muito importante a divulgação. Com isso gostaríamos de apoiá-lo divulgando em nossos canais que a sua instituição dispõe de livros acessíveis, informando o telefone, e-mail e endereço.',
            value: shareCollection,
            onChange: setShareCollection,
            formLabel: 'accessMyCollection'
        },
        {
            type: 'none'
        },
        {
            type: 'list-checkbox',
            label: ' ',
            noForm: true,
            items: [
                {
                    labelElement: <BtnPrivacyPolicy/>,
                    value: acceptPolicy,
                    onChange: setAcceptPolicy,
                    errorMessage: "É necessário aceitar a política de privacidade"
                },
                {
                    labelElement: <BtnTerms/>,
                    value: acceptTerms,
                    onChange: setAcceptTerms,
                    errorMessage: "É necessário aceitar os termos de uso"
                }
            ],
            validation: (el) => el.map(({ value }) => value).filter((item) => !item).length === 0
        },
        {
            type: 'none'
        },
    ]

    

    const handlePublishUserRegistration = () => {

        const userAccess = {
            username: cnpj.replace(NUMBER_ONLY, ""),
            email: email,
            password: password
        }

        const unifiedUserForm = firstStageForm.concat(aboutCollectionForm)
            .concat(finalStepForm)

        const userForm = {}

        unifiedUserForm.forEach(({ value, formLabel, items, image, noForm, filter }) => {
            if (value && formLabel && !image) {
                if (typeof formLabel === 'string') {
                    userForm[formLabel] = filter ? filter(value) : value
                } else {
                    if (!userForm[formLabel.name]) {
                        userForm[formLabel.name] = {}
                    }
                    userForm[formLabel.name][formLabel.entry] = filter ? filter(value) : value
                }
            }
            if (!value && !formLabel && !noForm && items) {
                items.forEach((item) => {
                    if (item.value && item.formLabel) {
                        if (typeof item.formLabel === 'string') {
                            userForm[item.formLabel] = filter ? filter(item.value) : item.value
                        } else {
                            if (!userForm[item.formLabel.name]) {
                                userForm[item.formLabel.name] = {}
                            }
                            userForm[item.formLabel.name][item.formLabel.entry] = filter ? filter(item.value) : item.value
                        }
                    }
                })
            }
        })

        const handleSuccess = () => {
            alert("Cadastro feito com sucesso!")
            history.push("/")
        }
        
        handleRegisterEnterprise(userAccess, profileImage, userForm, handleSuccess);
    }

    const closeModalUseTerms = () => {
        setModalUseTerms(false)
    }
    const closeModalPrivacyPolice = () => {
        setModalPrivacyPolice(false)
    }
    return (
        <>
            <Header />
            <GoBack title="Cadastro pessoa jurídica" />
            <Container>
                <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError}
                onRequestClose={() => setModalError(false)}>
                        <p>{modalErrorMessage}</p>
                </ModalDefault>
                {step === 1 &&
                    <FormConstructor
                        form={firstStageForm}
                        step={step}
                        proceed={() => setStep(step + 1)}
                        proceedText="Avançar"
                        cancel={() => history.push('/')}
                        cancelText="Cancelar"
                    />
                }
                {step === 2 &&
                    <FormConstructor
                        form={passwordForm}
                        step={step}
                        proceed={() => setStep(step + 1)}
                        proceedText="Avançar"
                        cancel={() => setStep(step - 1)}
                    />
                }
                {
                    step === 3 &&
                    <FormConstructor
                        form={aboutCollectionForm}
                        step={step}
                        proceed={() => setStep(step + 1)}
                        proceedText="Avançar"
                        cancel={() => setStep(step - 1)}
                    />
                }
                {
                    step === 4 &&
                    <FormConstructor
                        form={finalStepForm}
                        step={step}
                        proceed={handlePublishUserRegistration}
                        proceedText="Salvar"
                        cancel={() => setStep(step - 1)}
                    />
                }
            </Container>
            <Footer />
            <ModalDefault type="file" title="Política de privacidade" isOpen={modalPrivacyPolice} 
                onRequestClose={() => closeModalPrivacyPolice()}>
                    <ReactMarkdown aria-label='Política de privacidade' tabIndex={0}>{modalPrivacyPoliceMessage}</ReactMarkdown>
            </ModalDefault>
            <ModalDefault type="file" title="Termo de uso" isOpen={modalUseTerms} 
                onRequestClose={() => closeModalUseTerms()}>
                    <ReactMarkdown aria-label='Termos de uso'>{modalUseTermsMessage}</ReactMarkdown>
            </ModalDefault>
        </>
    )
}