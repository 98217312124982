export default class CNPJValidator {
    constructor(){
        this.consecutiveNumbersRegex = new RegExp(/0{11}|1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11}/);
    }

    validate(string) {
        const errorMessage = "CNPJ inválido";

        const firstDigitsWeight = [2,3,4,5,6,7,8,9,2,3,4,5];

        if(string.length !== 14 || this.consecutiveNumbersRegex.test(string)) {
            return errorMessage;
        }

        const lastDigits = string.slice(-2);

        const remainingDigits = string.slice(0, -2);

        const documentDigits = [...remainingDigits.split("").map(item => Number(item))].reverse();

        let reducedDigits = 0;

        documentDigits.forEach((item, index) => {
            reducedDigits += (Number.parseInt(item) * (firstDigitsWeight[index]))
        });
        const extractedDigit = 11 - (reducedDigits % 11);
        const firstDigitVerifier = extractedDigit > 9 ? 0 : extractedDigit; 

        //if first digit is invalid, return error
        if(firstDigitVerifier !== Number.parseInt(lastDigits.slice(0, 1))) {
            return errorMessage;
        }

        let reducedSecondDigit = 0;
        documentDigits.splice(0,0, firstDigitVerifier);
        firstDigitsWeight.push(6);
        //sum all values with their respective weight
        documentDigits.forEach((item, index) => {
            reducedSecondDigit += Number.parseInt(item) * (firstDigitsWeight[index]);
        });
        
        let secondDigitVerifier = 11 - (reducedSecondDigit % 11);
        if(secondDigitVerifier > 9) {
            secondDigitVerifier = 0;
        }

        //if second digit is invalid, return error
        if(secondDigitVerifier !== Number.parseInt(lastDigits.slice(-1))) {
            return errorMessage;
        }
        
        return "";
    }
}