import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 900px){
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-template-rows: repeat(2, 6fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
  }
  @media screen and (max-width: 500px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const Button = styled.button`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: fit-content !important;
  padding: 8px !important;
  margin-left: 32px !important;
  transition: transform 0.3s linear;
  background: none !important;
  color: #000 !important;
  img{
    margin-right: 8px;
  }
  &:hover{
    box-shadow: 0px 1px 3px 0px #000;
    background: #DF4263 !important;
    color: #fff !important;
  }
  @media screen and (max-width: 900px){
    margin: 0 16px 16px;
    width: 150px !important;
  }
  @media screen and (max-width: 500px){
    margin: 16px;
  }
`

export const DisabledButton = styled.button`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: fit-content !important;
  padding: 8px !important;
  margin-left: 32px !important;
  transition: transform 0.3s linear;
  background: none !important;
  color: #000 !important;
  img{
    margin-right: 8px;
  }
  @media screen and (max-width: 900px){
    margin: 0 16px 16px;
    width: 150px !important;
  }
  @media screen and (max-width: 500px){
    margin: 16px;
  }
`

export const Image = styled.img`
  width: 36px;
  height: 36px;
`