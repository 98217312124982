import styled from 'styled-components';

export const Genero = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
  }
  > div:last-child {
    display: flex;
    gap: 20px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      input {
      }
      label {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 80vw;
    flex: 0 !important;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 80vw;
  }
`;
