import { useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Input } from "../../../components/Input";
import { ModalDefault } from "../../../components/ModalDefault";
import { UploadInput } from "../../../components/UploadInput";
import { Wizard } from "../../../components/Wizard";
import { Container, Controllers, Empty, Line } from "./styles";
import rehypeRaw from "rehype-raw";

export function Visual(props) {

    const {
        privacy,
        terms,
        visualCondition,
        cidCode,
        report,
        modalPrivacyPoliceMessage,
        modalUseTermsMessage,
        loading,
        foreigner
    } = props
    const validationPipe = [
        {
            value: visualCondition,
            message: 'O campo condição visual é obrigatório',
            label: 'visualCondition'
        },
        {
            value: cidCode,
            message: 'O campo CID é obrigatório',
            label: 'cidCode'
        },
        {
            value: report.name,
            message: 'O campo laudo é obrigatório',
            label: 'report'
        }
    ].filter(({ value }) => !value)

    const validationPipeForeigner = [
        {
            value: visualCondition,
            message: 'O campo condição visual é obrigatório',
            label: 'visualCondition'
        },
        {
            value: report.name,
            message: 'O campo laudo é obrigatório',
            label: 'report'
        }
    ].filter(({ value }) => !value)

    const visualOptions = [
        {
            label: 'Baixa visão',
            value: 'low_vision'
        },
        {
            label: 'Cegueira total',
            value: 'blind'
        }
    ]

    const policyRef = useRef()
    const termsRef = useRef()

    const imageOrPDFRegex = new RegExp(/pdf|jpg|jpeg|png/i)

    const isFileValid = imageOrPDFRegex.test(report.name ?? '')

    const isFormValid = privacy && terms && isFileValid && validationPipe.length === 0
    const isFormValidForeigner = privacy && terms && isFileValid && validationPipeForeigner.length === 0

    const [targetField, setTargetField] = useState('')

    const [modalError, setModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const [modalUseTerms, setModalUseTerms] = useState(false); 

    const [modalPrivacyPolice, setModalPrivacyPolice] = useState(false);

    const handleInvalidForm = () => {
        const getFirstInvalidField = foreigner ? validationPipeForeigner[0] : validationPipe[0] 
        if(getFirstInvalidField){
            setTargetField(getFirstInvalidField.label)
            setModalError(true)
            setModalErrorMessage(getFirstInvalidField.message)
        }else{
            if(!isFileValid) {
                setTargetField("policyRef")
                setModalError(true)
                setModalErrorMessage("Arquivo de laudo inválido. Por favor use os formatos: JPEG, PNG ou PDF")
                return
            }
            if (!privacy) {
                setTargetField("policyRef")
                setModalError(true)
                setModalErrorMessage("Você precisa concordar com a política de privacidade")
                return
            }
            if (!terms) {
                setTargetField("termsRef")
                setModalError(true)
                setModalErrorMessage("Você precisa concordar com os termos de uso")
                return
            }
        }
    }

    const closeModalShowLastRequiredField = () => {
        setModalError(false);

        if (validationPipe.length > 0 && !foreigner) {
            const getFirstInvalidField = validationPipe[0]
            if (getFirstInvalidField.label === targetField) {
                setTargetField(' ')
                const timedOutTargetField = setTimeout(() => setTargetField(getFirstInvalidField.label), 256)
                return () => clearTimeout(timedOutTargetField)
            }
            setTargetField(getFirstInvalidField.label)
            return
        } else if (validationPipeForeigner.length > 0 && foreigner) {
            const getFirstInvalidField = validationPipeForeigner[0]
            if (getFirstInvalidField.label === targetField) {
                setTargetField(' ')
                const timedOutTargetField = setTimeout(() => setTargetField(getFirstInvalidField.label), 256)
                return () => clearTimeout(timedOutTargetField)
            }
            setTargetField(getFirstInvalidField.label)
            return
        }

        if(!isFileValid) {
            setModalError(false)
            const timedOutTargetField = setTimeout(() => setTargetField('report'), 256)
            return () => clearTimeout(timedOutTargetField)
        }
        if (!privacy) {
            setModalError(false)
            const timedOutTargetField = setTimeout(() => policyRef.current?.focus(), 256)
            return () => clearTimeout(timedOutTargetField)
        }

        if (!terms) {
            setModalError(false)
            const timedOutTargetField = setTimeout(() => termsRef.current?.focus(), 256)
            return () => clearTimeout(timedOutTargetField)
        }
    }

    const closeModalUseTerms = () => {
        setModalUseTerms(false)
    }
    const closeModalPrivacyPolice = () => {
        setModalPrivacyPolice(false)
    }

    return (
        <>
            <Wizard step={5} />
            <ModalDefault type="error" showCloseButton title="Campo inválido" isOpen={modalError} 
                onRequestClose={() => closeModalShowLastRequiredField()}>
                    <p>{modalErrorMessage}</p>
            </ModalDefault>
            <ModalDefault type="file" title="Política de privacidade" isOpen={modalPrivacyPolice} 
                onRequestClose={() => closeModalPrivacyPolice()}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} aria-label='Política de privacidade' tabIndex={1}>{modalPrivacyPoliceMessage}</ReactMarkdown>
            </ModalDefault>
            <ModalDefault type="file" title="Termo de uso" isOpen={modalUseTerms} 
                onRequestClose={() => closeModalUseTerms()}>
                    <ReactMarkdown aria-label='Termos de uso'>{modalUseTermsMessage}</ReactMarkdown>
            </ModalDefault>
            <Container>
                <Line>
                    <Input
                        autofocus={targetField === 'visualCondition'}
                        value={props.visualCondition}
                        onChange={(v) => props.setVisualCondition(v)} type="select" label="Qual a sua condição visual"
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        required>
                        {
                            visualOptions.map((opt, key) => <option 
                            value={opt.value}
                            key={key + 'opt'}>
                                {opt.label}
                            </option>)
                        }
                    </Input>
                    {!foreigner ? (
                        <Input
                        id="codigo-cdi"
                        autofocus={targetField === 'cidCode'}
                        value={props.cidCode}
                        onChange={(v) => props.setCidCode(v)}
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        label="Indique o código CID"
                        required>
                    </Input>
                    ) : null}
                </Line>
                <Line>
                    <UploadInput
                        tabIndex={1}
                        value={report}
                        autofocus={targetField === 'report'}
                        onChange={props.setReport}
                        aria-label="Upload de laudo digitalizado - imagem ou PDF"
                        onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        label="Laudo digitalizado (imagem ou PDF)"
                        required />
                    <Empty></Empty>
                </Line>
                <Line>
                    <div className="check">
                        <input type="checkbox"
                            ref={policyRef}
                            tabIndex={1}
                            aria-label="Li e estou de acordo com a Política de Privacidade"
                            checked={props.privacy} onClick={(e) => props.setPrivacy(e.currentTarget.checked)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }} />
                        <label htmlFor="">Li e estou de acordo com a <button tabIndex={1} onClick={() => {setModalPrivacyPolice(true)}}>Política de Privacidade</button> do serviço <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span></label>
                    </div>
                </Line>
                <Line>
                    <div className="check">
                        <input type="checkbox"
                            ref={termsRef}
                            tabIndex={1}
                            aria-label="Li e estou de acordo com os Termos de Uso"
                            checked={props.terms}
                            onClick={(e) => props.setTerms(e.currentTarget.checked)}
                            onBlur={(e) => { (!e.target.value) ? e.target.classList.add('error') : e.target.classList.remove('error'); }}
                        />
                        <label htmlFor="">Li e estou de acordo com os <button tabIndex={1} onClick={() => {setModalUseTerms(true)}}>Termos de Uso</button> do serviço <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span></label>
                    </div>
                </Line>
            </Container>
            <Controllers>
                <button tabIndex={1} onClick={() => { props.changeStep(4) }}>Anterior</button>
                <button
                    style={{
                        opacity: foreigner ? isFormValidForeigner || loading ? 1 : .5 : isFormValid || loading ? 1 : .5
                    }}
                    tabIndex={1}
                    disabled={loading}
                    onClick={() => foreigner ? isFormValidForeigner ? props.submit() : handleInvalidForm() : isFormValid ? props.submit() : handleInvalidForm()}>{loading ? 'Enviando...' : 'Concluir'}</button>
            </Controllers>
        </>
    )
}