import { BASE_URL } from '../../services/api'
import {
    Card1,
    ImageDivFallback,
    InvisibleLabel
} from './styles'

export const BookCard = ({ book, action }) => {

    let getFirstAvailableImage = book.images[0]

    let url = getFirstAvailableImage ? getFirstAvailableImage.file?.url : false

    let ariaLabel = `Imagem do livro ${book.bookTitle ?? "livro sem nome"}`

    if (url) {
        ariaLabel = getFirstAvailableImage.label
    }
    const formats = (book.categories ?? (book.category ? [book.categor] : [])).map(({ name }) => name).join(", ")
    const availableFormats = ['Braille', 'Audiobook', 'Daisy', 'ePub'].filter((item) => !!book[item.toLowerCase()])
    // console.log("🐌🧲 ~ file: index.jsx ~ line 22 ~ BookCard ~ availableFormats", availableFormats)
    return <Card1
    onClick={action} >
        <div>
            <div className="head">
                <div className="info">
                    <h3 tabIndex={0}
                    onClick={action} 
                    onKeyDown={({keyCode}) => keyCode === 13 ? action() : null } 
                    aria-label={`Ir para livro: ${book.bookTitle ?? "livro sem nome"} do autor: ${book.author}`} 
                    aria-label={`Livro: ${book.bookTitle ?? "livro sem nome"}`}><InvisibleLabel>Livro:</InvisibleLabel>{book.bookTitle ?? "livro sem nome"}</h3>
                    <p tabIndex={1} aria-label={`Autor: ${book.author ?? "livro sem autor cadastrado"}`}><InvisibleLabel>Autor:</InvisibleLabel>{book.author ?? "livro sem autor cadastrado"}</p>
                    {/* <span tabIndex={-1}>{formats}</span> */}
                    <InvisibleLabel tabIndex={1}>{availableFormats.length > 0 ? 
                    `Formatos disponíveis: ${availableFormats.join(", ")}` : `Nenhum formato disponível`}</InvisibleLabel>
                </div>
                <div className="photo">
                    <ImageDivFallback
                        aria-label={ariaLabel}
                        tabIndex={2}
                        bgImage={url ? url : undefined}
                    />
                </div>
            </div>
            <div className="foot">
                {
                    availableFormats.map((item, key) => <span aria-hidden={true} tabIndex={-1} key={key + 'format' + book.id}>
                        {item}
                    </span>)
                }
            </div>
        </div>
    </Card1>
}