import CNPJValidator from "../cnpj";
import CPFValidator from "../cpf";
import EmailValidator from "../email";
import ExactLengthValidator from "../exact-length";
import MaxLengthValidator from "../max-length";
import MinLenghtValidator from "../min-length";
import Validator from "../validator";

const ValidationBuilder = (type) => {
    switch(type) {
        case "cpf":
            return new Validator([new ExactLengthValidator(11), new CPFValidator()]);
        case "cnpj":
            return new Validator([new ExactLengthValidator(14), new CNPJValidator()]);
        case "email":
            return new Validator([new EmailValidator()]);
        case "staff-password":
            return new Validator([new MinLenghtValidator(6), new MaxLengthValidator(20)]);
        default:
            return new Validator([new MinLenghtValidator(6), new MaxLengthValidator(20)]);
    }
}

export default ValidationBuilder;