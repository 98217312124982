import styled from 'styled-components';

export const ImageDivFallback = styled.div`
  width: 100px;
  height: 150px;
  background-color: ${(props) => props.theme.textColor}aa;
  background-size: cover;
  background-position: center;
  ${(props) =>
    props.bgImage ? `background-image: url(${props.bgImage});` : ''}
`;

export const ImageDivContainer = styled.div``;

export const ImageSelector = styled.div``;

export const InvisibleLabel = styled.label`
  opacity: 0;
  font-size: 1px;
  position: absolute;
  top: 0;
`;

export const Card1 = styled.article`
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 0;
  border-radius: 12px;
  box-shadow: 0px 0px 4px ${(props) => props.theme.textColor}36;
  & > div {
    cursor: pointer;
    padding: 20px;
    display: flex;
    flex-direction: column;
    > div.head {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      > div.info {
        h3 {
          font-weight: 600;
          font-size: ${(props) => props.theme.fontSize + 22}px;
        }
        p {
          font-size: ${(props) => props.theme.fontSize + 16}px;
          font-weight: 400;
          color: ${(props) => props.theme.textColor}dd;
        }
        span {
          color: ${(props) => props.theme.textColor}aa;
          font-size: ${(props) => props.theme.fontSize + 12}px;
          font-weight: 400;
        }
      }
      > div.photo {
        img {
          width: 94px;
        }
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      span {
        font-size: ${(props) => props.theme.fontSize + 12}px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor}88;
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 40vw !important;
    height: 100% !important;
    word-break: keep-all;
    & > div {
      width: fit-content;
      > div.head {
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 70% !important;
    height: 100% !important;
    word-break: keep-all;
    & > div {
      width: fit-content;
      > div.head {
        justify-content: space-between;
        flex-direction: column;
        > div.photo {
          align-self: center;
        }
      }
    }
  }
`;
