export default class ExactLengthValidator {
    constructor(length, type = "password") {
        this.length = length;
        this.type = type;
    }

    validate(string) {
        const errorMessage = `O campo precisa conter ${this.length} caracteres`;
        if(string.length !== (this.length - (this.type === "password" ? 0 : 3))) {
            return errorMessage;
        }
        return "";
    }
}