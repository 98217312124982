import { useState } from "react";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";
import { Answer, Ask, Container } from "./styles";


export function Hide(props){
    const [collapse, setCollapse] = useState(false);
    const toggleCollapse = () => setCollapse((prevState) => !prevState)
    return(
        <Container>
            <Ask aria-label={`${collapse ? 'Fechar' : 'Abrir'} opção ${props.title}`} onClick={toggleCollapse}>
                {collapse ? <FiMinusSquare size={20} /> : <FiPlusSquare size={20} />}
                <h3>{props.title}</h3>
            </Ask>
            <Answer className={collapse && "active"}>
                {props.children}
            </Answer>
        </Container>
    )
}