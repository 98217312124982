import ReactModal from "react-modal"
import { FiX } from 'react-icons/fi';
import { ContainerFileModal } from "./styles";
export function ModalDefault(props) {
    const modalType = props.type;
    const overlay = props.overlay ?? "overlay-default";
    return (
        <>
            {modalType === "login" &&
                <ReactModal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-login" overlayClassName="overlay-default">
                    <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal"><FiX /></button>
                    <h3>{props.title}</h3>
                    {props.children}
                </ReactModal>
            }
            {modalType === "warning" &&
                <ReactModal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-login" overlayClassName="overlay-default">
                    <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal"><FiX /></button>
                    <h3>{props.title}</h3>
                    {props.children}
                </ReactModal>
            }
            {modalType === "medium" &&
                <ReactModal  isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-medium" overlayClassName="overlay-default">
                    <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal"><FiX /></button>
                    <h3>{props.title}</h3>
                    {props.children}
                </ReactModal>
            }
            {modalType === "file" &&
                <ReactModal  isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-medium" overlayClassName="overlay-default">
                    <h3 tabIndex={0}>{props.title}</h3>
                    <ContainerFileModal tabIndex={0}>
                        {props.children}
                    </ContainerFileModal>
                    <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal" tabIndex={0}><FiX /></button>
                </ReactModal>
            }
            {modalType === "error" &&
                <ReactModal ariaHideApp={false}  isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-error" overlayClassName={overlay} shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}>
                    <h3>{props.title}</h3>
                    {props.children}
                    {props.showCloseButton && (
                        <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal-biggest">Fechar</button>
                    )}
                </ReactModal>
            }
            {modalType === "accept" &&
                <ReactModal ariaHideApp={false}  isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="modal-default" id="modal-error" overlayClassName={overlay}>
                    <button aria-label="Botão de fechar o modal" onClick={() => props.onRequestClose()} className="close-modal"><FiX /></button>
                    <h3>{props.title}</h3>
                    <br></br>
                    {props.children}
                    <div className="btns">
                        <button aria-label="Botão de recusar" onClick={() => props.onRequestClose()} className="modal-accept" 
                        style={{backgroundColor: "#cd2626", marginRight: "16px"}}>Não</button>
                        <button aria-label="Botão de aceitar" onClick={() => props.onRequestAccept()} className="modal-accept">Sim</button>
                    </div>    
                 </ReactModal>
            }
        </>
    )
}