import styled from 'styled-components';

export const Container = styled.section`
  margin-left: 32px;
  margin-right: 32px;
  max-width: 800px;
  align-self: center;
  margin-bottom: 128px;
  @media screen and (max-width: 720px) {
    width: 100vw;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    margin-bottom: 16px;
  }
`;

export const HeaderSummary = styled.div`
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 8px 32px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: fit-content;
    margin: 8px 32px;
  }
`;

export const HeaderRow = styled.div``;

export const HeaderNewsType = styled.p`
  color: var(--pink);
  font-weight: bold;
  font-size: ${(props) => props.theme.textSize + 12};
`;

export const HeaderShareLink = styled.div`
 @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: fit-content;
  }`;

export const Title = styled.h3`
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: fit-content;
  }
`;

export const DateText = styled.p``;

export const MainArticle = styled.main`
  display: flex;
`;

export const InivisibleLabel = styled.span`
  position: absolute;
  opacity: 0;
`;

export const ImageArticle = styled.div`
  margin-right: 22px;
  margin-bottom: 8px;
  float: left;
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin-right: 0px;
    margin-bottom: 0px;
    float: none;
  }
`;

export const ImageArticleImage = styled.div`
  width: 500px;
  background-color: ${(props) => props.textColor}aa;
  img{
    width:100%;
  }
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
    background: none;
    img {
      width: 90vw;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    height: fit-content;
    margin: 8px 32px;
    img {
      width: 100%;
    }
  }
`;

export const TextArticle = styled.p`
  word-break: keep-all;
  width: 800px;
  min-height: 330px;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90vw;
    margin: 8px 32px;
  }
`;

export const ImageCaption = styled.p`
  color: ${(props) => props.theme.textColor}aa;
  @media screen and (max-width: 720px) {
    margin: 0 16px;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    margin: 8px 32px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
