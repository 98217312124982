import { useEffect, useState } from "react";
import {
    Container,
    ErrorMessage
} from "./styles";

export function Autocomplete(props) {
    const { errorMessage, required, ariaLabel, label, placeholder, className, data } = props;

    const [items, setItems] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const flex = `flex${props.fl}`;

    const style = props.style ?? {}

    useEffect(() => {
        if (selectedIndex >= 0) {
            const ul = document.getElementsByClassName("listOf")[0];
            if (typeof ul != "undefined") {
                const li = ul.querySelectorAll('li');

                if (li.length > 0) {
                    li.forEach(element => {
                        element.classList.remove('selected');
                        element.removeAttribute('aria-label', false);
                    });

                    props.onSelectChange(li[selectedIndex].getAttribute('value'))
                    li[selectedIndex].classList.add('selected');
                }
            }
        }
    }, [selectedIndex])

    function handleKeyDown(e) {
        if (e.keyCode === 38) {
            // tecla para cima
            e.preventDefault();

            setSelectedIndex((prevIndex) => 
                prevIndex <= 0 ? items.length - 1 : (items.length -1 < prevIndex ? 0 : prevIndex - 1)
            );

        } else if (e.keyCode === 40) {
            // tecla para baixo
            e.preventDefault();

            setSelectedIndex((prevIndex) =>
                prevIndex ===  items.length - 1 ? 0 : (items.length -1 < prevIndex  ? 0 : prevIndex + 1)
            );
            
        } else if (e.keyCode === 13) {
            // tecla enter
            const ul = document.getElementsByClassName("listOf")[0];
            const li = ul.querySelectorAll('li');
            if (li.length > 0) {
                props.onSelect(li[selectedIndex].getAttribute('value'))
            }
        }
    }

    useEffect(() => {
        setItems([]);
        if(data.length > 0) {
            let tmpData = []
            for (let i = 0; i < (data.length >= 10 ? 10 : data.length); i++) {
                const element = data[i];
                tmpData.push(
                    <li
                        key={i} 
                        className={i === selectedIndex ? 'selected' : ''}
                        value={element}
                    >
                        {element} 
                    </li>
                )
            }
            setItems([...tmpData]);
        }
    }, [ data ])

    function handleValid (event){
        const regex = /^[^\d]*$/;
        const key = event.target.value;

        if (!regex.test(key) && key.length > 0) {
            event.preventDefault();
        } else {
            props.onChange(key)
        }
    }

    return (
        <>
            <Container style={style} className={flex}>
                <label 
                    htmlFor={props.targetName} 
                    aria-label={required ? required ? ariaLabel ? ariaLabel+". Esse é um campo de preenchimento obrigatório" : label ? label+". Esse é um campo de preenchimento obrigatório"  : "" : ariaLabel ?? label : ariaLabel ?? label}
                    >
                        {label} {(required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}
                </label>
                <div>
                    <input
                        tabIndex={1}
                        aria-label={ariaLabel}
                        className={className}
                        id={props.targetName}
                        value={props.value}
                        onChange={handleValid} 
                        onBlur={props.onBlur !== undefined ? e => setTimeout(() => {props.onBlur()},100) : () => {}}
                        type="text"
                        placeholder={placeholder}
                        autoComplete="off"
                        onKeyDown={handleKeyDown}
                        onFocus={props.onFocus !== undefined ? props.onFocus : () => {}}
                    />
                        <ul
                            className="listOf"
                            onClick={e => props.onSelect(e.target.getAttribute('value'))}
                        >
                            {items}
                        </ul>
                </div>
                <ErrorMessage>
                    {errorMessage ?? ""}
                </ErrorMessage>
            </Container>
        </>
    )
}