import styled from 'styled-components';

export const Container = styled.section`
  width: var(--width-m);
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  margin-bottom: 50px;
  @media screen and (max-width: 720px) {
    flex-direction: column-reverse;
    margin: 0 16px;
    width: 100vw;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    flex-direction: column-reverse;
    margin: 0 16px;
    width: 100vw;
  }
`;

export const Aside = styled.aside`
  flex: 3;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .keywords {
      overflow-y: scroll;
      max-height: 150px;
      word-break: keep-all;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      select {
        width: 250px;
      }
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSize + 14}px !important;
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
  }
  label {
    font-size: ${(props) => props.theme.fontSize + 14}px !important;
    font-weight: 400;
  }
  input {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 720px) {
    width: 90vw;
    display: flex;
    overflow-x: scroll;
    > div {
      grid-area: auto;
      width: fit-content;
      overflow-y: scroll;
      max-height: 150px;
      word-break: keep-all;
      > div {
        width: 100%;
        select {
          width: 100%;
          margin: 0 32px;
        }
      }
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100vw;
    display: grid;
    overflow-y: scroll;
    grid-template-columns: repeat(3, 1fr);
    > div {
      grid-area: auto;
      width: 25vw;
      overflow-y: scroll;
      max-height: 150px;
      > div {
        width: 100%;
        select {
          width: 100%;
          margin: 0 32px;
        }
      }
    }
  }
`;

export const Main = styled.main`
  flex: 9;
  display: flex;
  flex-direction: column;
  gap: 10px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: ${(props) => props.theme.fontSize + 14}px !important;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: fit-content;
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 90vw;
    margin-bottom: 32px;
  }
`;

export const Item = styled.article``;

export const InvisibleLabel = styled.label`
  opacity: 0;
  font-size: 0px;
  width: 0px;
  position: absolute;
  z-index: -100;
`;

export const NoResultsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  p {
    color: ${(props) => props.theme.textColor};
    font-size: ${(props) => props.theme.fontSize + 16}px !important;
    font-weight: bold;
    align-self: center;
  }
`;

export const Card1 = styled.article`
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 0;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.textColor}26;
  cursor: pointer;
  padding: 20px;
  > div {
    display: flex;
    flex-direction: column;
    > div.head {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      > div.info {
        h3 {
          font-weight: 600;
          font-size: ${(props) => props.theme.fontSize + 22}px !important;
        }
        p {
          font-size: ${(props) => props.theme.fontSize + 16}px !important;
          font-weight: 400;
          color: ${(props) => props.theme.textColor}aa;
          margin-top: 8px;
        }
        span {
          color: ${(props) => props.theme.textColor}aa;
          font-size: ${(props) => props.theme.fontSize + 12}px !important;
          font-weight: 400;
        }
      }
      > div.photo {
        img {
          width: 94px;
        }
      }
    }
    > div.foot {
      display: flex;
      gap: 10px;
      padding-top: 20px;
      span {
        font-size: ${(props) => props.theme.fontSize + 12}px !important;
        font-weight: 700;
        margin-right: 20px;
        color: ${(props) => props.theme.textColor}aa;
      }
    }
  }
  @media screen and (max-width: 720px) {
    width: 90vw;
  }
`;

export const BookImage = styled.img`
  width: 94px;
`;

export const ImageFallback = styled.div`
  width: 94px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.textColor};
  ${(props) =>
    props.targetImage ? `background-image: url(${props.targetImage});` : ''}
`;
