import { useEffect, useRef } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Container, Upload } from './styles';

export function UploadInput(props) {
    const label = props.label;
    const { ariaLabel, autofocus } = props
    let blobURL = ''

    const ref = useRef()

    if (props.value) {
        if (props.value.name) blobURL = URL.createObjectURL(props.value)
    }

    const buttonRef = useRef()

    const handleButton = () => {
        if (ref) {
            if (ref.current) {
                ref.current.click()
            }
        }
    }

    useEffect(() => {
        if(autofocus) {
            buttonRef.current.focus()
        }
    }, [autofocus])

    return (
        <Container>
            <label tabIndex={1} htmlFor="">{label}{(props.required) ? <span className='red' aria-label="Campo Obrigatório" role="landmark">*</span> : null}</label>
            <Upload htmlFor="up"
                aria-label={ariaLabel ?? label}
                ref={buttonRef}
                onClick={handleButton}
                tabIndex={1}>
                {
                    blobURL.length === 0 ? <>
                        <FiPlus />
                        Adicionar arquivo ou imagem
                    </>
                        : props?.value?.name

                }
            </Upload>
            {
                blobURL.length > 0 ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 5
                    }}>
                        <img
                            style={{
                                height: 50,
                                alignSelf: 'center'
                            }}
                            alt={`Pré-visualização do arquivo ${props?.value?.name}`}
                            src={blobURL}
                        />
                    </div>
                    : null
            }
            <input
                type="file"
                ref={ref}
                onChange={(e) => props.onChange(e.target.files[0])}
                name="" />
        </Container>
    )
}